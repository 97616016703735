<template>
	<form class="form" autocomplete="off" @submit.prevent="submitForm" @change="hasChanged">
		<!-- Definizione delle tabs -->
		<div class="tabs--stage" v-if="tabs">
			<div class="tabs--drag">
				<div class="tabs">
					<div :class="`tab ${(t.key == tabSelected) ? 'selected' : ''}`" v-for="t in tabs" v-bind:key="t.key"
						 @click="tabSelected = t.key; actionTab();">
						{{ t.caption }}
					</div>
				</div>
			</div>
		</div>

		<!-- Form -->
		<div class="panel panel_post">
			<!-- Errors -->
			<div v-if="error" class="error error--general-big">
				{{ error }}
			</div>

			<!-- Tab -->
			<div class="tab_item" v-if="tabSelected == 'generale'">
				<Inpt label="Titolo" name="title" v-model="$v.form.title.$model" :o="$v.form.title" :maxlength="96"
					  :battute="true" :placeholder="`Titolo`" :submitted="submitted"></Inpt>

				<Inpt label="Descrizione" name="title" v-model="$v.form.others.description.$model"
					  :o="$v.form.others.description"
					  :battute="false" :placeholder="`Descrizione`" :submitted="submitted"></Inpt>

				<!--
				<Inpt label="Telenuovo ID" name="title" v-model="$v.form.others.telenuovo_id.$model" :o="$v.form.others.telenuovo_id"
					  :battute="false" :disabled="true" :placeholder="`Telenuovo ID`" :submitted="submitted"></Inpt>

				<Inpt label="Titolatrice ID" name="title" v-model="$v.form.others.titolatrice_id.$model" :o="$v.form.others.titolatrice_id"
					  :battute="false" :disabled="true" :placeholder="`Titolatrice ID`" :submitted="submitted"></Inpt>

				<Inpt label="Post ID collegato" name="title" v-model="$v.form.others.media_id.$model" :o="$v.form.others.media_id"
					  :battute="false" :disabled="true" :placeholder="`Post ID collegato`" :submitted="submitted"></Inpt>
			    -->

				<!-- data -->
				<div class="fieldset" v-if="form.others.data">
					<label>Data</label>
					<div class="input">
						<Datetime :type="`datetime`" v-model="$v.form.others.data.$model"></Datetime>
					</div>
				</div>

				<!-- scadenza
				<div class="fieldset" v-if="form.others.data_scadenza">
					<label>Scadenza</label>
					<div class="input">
						<Datetime :type="`date`" v-model="$v.form.others.data_scadenza.$model"></Datetime>
					</div>
				</div>-->

				<div class="fieldset" v-if="form.others.categoria">
					<label>Categoria</label>
					<div class="input">
						<select v-model="$v.form.others.categoria.$model">
							<option :value="c.id" v-for="c in categories" :key="c.id">
								{{ c.name }}
							</option>
						</select>
					</div>
				</div>

				<div class="fieldset" style="flex-direction: row">
					<div class="col" style="padding: 20px; width: 30%">
						<label>
							Thumbnail<br><br>
						</label>
						<span class="video-container-nella-galleria-media o-ratio u-16:10">
							<img v-if="form.others.thumb_url" :src="form.others.thumb_url">
						</span>

						<Btn :loading="loading" :text="'Cambia Thumbnail'" text-size="big" button-type="default"
							 :is-full="false" v-on:btn-click="showChangeThumbnailVideo = true"
							 :is-submit-button="false"></Btn>

						<ChangeVideoTitolatriceThumbnailModal
							@close="closeChangeThumbVideo($event)"
							v-if="showChangeThumbnailVideo"
							:video-id="`${form.others.video_url}`.split(`/`).reverse()[1]"></ChangeVideoTitolatriceThumbnailModal>
					</div>
					<div class="col" style="padding: 20px; width: 70%">
						<label>
							Video<br><br>
						</label>
						<span class="video-container-nella-galleria-media o-ratio u-16:10">
							<videoPlayer ref="videoPlayer" :options="playerOptions"></videoPlayer>
						</span>
					</div>
				</div>

				<div class="fieldset">
					<label>XML Titolatrice</label>
					<textarea :disabled="true" class="xml" v-model="$v.form.others.xml.$model"></textarea>
				</div>

			</div>

			<div class="form-buttons">
				<div></div>
				<!--<span class="next_button" v-if="!loading && tabSelected != 'attribute' && form.id == null"
					  @click="tabSelected = nextTab()">
					Avanti
				</span>-->
				<Btn v-if="tabSelected == 'generale' || form.id != null" :loading="loading" :text="'Salva'"
					 text-size="big" button-type="primary" :is-full="false" :is-submit-button="true"></Btn>
			</div>
		</div>
	</form>
</template>
<script>
import {required} from 'vuelidate/lib/validators';
import Btn from "../common/forms/Btn";
import Inpt from "../common/forms/Inpt";
import {Datetime} from 'vue-datetime';
import {videoPlayer} from 'vue-videojs7';
import ChangeVideoTitolatriceThumbnailModal from "@components/common/modals/ChangeVideoTitolatricceThumbnailModal";

export default {
	name: 'PlayForm',
	components: {
		ChangeVideoTitolatriceThumbnailModal,
		Inpt,
		Btn,
		Datetime,
		videoPlayer
	},
	props: {
		// l'oggetto di base
		base: {
			default: null,
			required: false
		},
		categories: {
			default: [],
			required: false
		}
	},
	data () {
		return {
			// indica se il form è stato spedito
			submitted: false,
			// errori della form
			error: '',
			loading: false,
			// gestione dei tab
			tabSelected: 'generale',
			tabs: [
				{key: 'generale', caption: ""},
			],
			// oggetto form
			form: {
				id: null,
				title: '',
				date: null,
				others: {
					description: '',
					telenuovo_id: '',
					titolatrice_id: '',
					video_url: '',
					thumb_url: '',
					xml: '',
					media_id: '',
					data: new Date(),
					data_scadenza: new Date('2100-12-31'),
					categoria: ''
				}
			},
			playerOptions: {
				autoplay: false,
				controls: true,
				controlBar: {
					timeDivider: false,
					durationDisplay: false
				}
			},
			showChangeThumbnailVideo: false
		};
	},
	/**
	 * Validatori Form
	 */
	validations: {
		form: {
			id: {},
			title: {
				required
			},
			date: {},
			others: {
				description: {},
				telenuovo_id: {},
				titolatrice_id: {},
				video_url: {},
				thumb_url: {},
				xml: {},
				media_id: {},
				data: {},
				data_scadenza: {},
				categoria: {}
			}
		}
	},
	computed: {
		/**
		 * il player video
		 */
		player () {
			return this.$refs.videoPlayer.player;
		}
	},
	methods: {
		/**
		 * Inizializzo la pagina
		 */
		init () {
			if (this.base !== null) {
				Object.keys(this.form).map(k => {
					if (this.base[k]) {
						this.form[k] = this.base[k];
					}
				})
				setTimeout(() => {
					this.playVideo(this.form.others.video_url);
				}, 300)
			}
		},
		/**
		 * Gestione del player video
		 */
		playVideo: function (src) {
			// in caso di localhost allora prendo un video HLS di prova
			// if (window.location.href.indexOf("http://localhost") >= 0) {
			// 	src = 'https://mediastream1.telenuovo.it/video//2020/12/28/8b0e9ea5-583d-4fb3-ac57-1c2e31872088/playlist.m3u8'
			// }
			const video = {
				withCredentials: false,
				type: 'application/x-mpegurl',
				src: src
			}
			this.player.reset();
			this.player.src(video)
		},
		/**
		 * Quando la form cambia, viene chiamato questo metodo che emette al componente
		 * padre la modifica
		 */
		hasChanged () {
			this.$emit('change', !this.submitted);
		},
		/**
		 * Quando viene selezionata una tab, controllo la tab ed eseguo
		 * delle operazioni
		 */
		actionTab () {
		},
		/**
		 * Tab successiva,
		 * @returns {string} il codice della tab successiva
		 */
		nextTab () {
			let next = "";
			this.tabs.map((t, index) => {
				if (this.tabSelected == t.key) {
					next = this.tabs[index + 1].key;
				}
				this.actionTab();
			})
			return next;
		},
		/**
		 * Chisura della form
		 */
		close () {
			this.$emit('close');
			this.submitted = false;
		},
		/**
		 * Salvataggio della form
		 */
		submitForm () {
			this.$v.form.$touch();
			this.submitted = true; // setto come inviato
			this.hasChanged(); // blocco l'emissione del form cambiato (non serve)
			this.error = '';
			this.loading = true;
			if (!this.$v.form.$invalid) {
				let f = {...this.form};
				this.service.play.save(f)
					.then(() => {
						// Salvo una seconda volta
						// è una cagata, lo so
						setTimeout(() => {
							this.service.play.save(f)
								.then(() => {
									this.$emit('save');
									this.submitted = false;
								})
								.catch((err) => {
									if (err && err.messages && err.messages[0]) {
										this.errorMessage = this.errors[err.messages[0]];
										this.error = this.errorMessage;
										this.submitted = false;
									}
								})
								.finally(() => {
									this.loading = false;
								})
						}, 300)
					})
					.catch((err) => {
						if (err && err.messages && err.messages[0]) {
							this.errorMessage = this.errors[err.messages[0]];
							this.error = this.errorMessage;
							this.submitted = false;
						}
					})
					.finally(() => {
						this.loading = false;
					})
			} else {
				this.loading = false;
				this.flashMessage.show({
					status: 'error',
					title: 'Dati non corretti',
					message: 'Controllare i dati prima per continuare'
				});
			}
		},
		closeChangeThumbVideo (v) {
			this.showChangeThumbnailVideo = false;
		}
	},
	mounted () {
		this.init();
	}
}
</script>
<style lang="scss">
.form-buttons {
	width: 100%;
	display: flex;
	justify-content: space-between !important;
	margin-top: 30px;
}

.fieldset {
	.error {
		margin-bottom: 15px;
	}
}

.panel_post {
	width: 100%;
}
</style>
