import { gsap } from 'gsap';
const Draggable = require("gsap/dist/Draggable").Draggable;
gsap.registerPlugin(Draggable);

export default {
    install: function (Vue) {
        Vue.prototype.$gsap = gsap;
        Vue.prototype.$draggable = Draggable;
    },
    gsap: gsap
}
