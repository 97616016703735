<template>
	<transition name="confirm.modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container modal-container-big">
					<div class="modal-header">
						<slot name="header">
							<h3>Seleziona un media</h3>
						</slot>
					</div>
					<div class="modal-body">
						<slot name="body">
							<MediaList :onlyimage="onlyimage" :onlyfiles="onlyfiles" :ispopup="true" @close="closeModal($event)"></MediaList>
						</slot>
					</div>
					<div class="modal-footer">
						<!--<slot name="footer">
							<Btn @btn-click="closeModal(false)" :text="`Chiudi finestra`" text-size="big"></Btn>
						</slot>-->
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
import MediaList from "../../../pages/media/MediaList";

export default {
	name: 'SelectMediaModal',
	components: {MediaList},
	props: {
		categories: {
			required: false
		},
		onlyimage: {
			required: false,
			type: Boolean,
			default: false
		},
		onlyfiles: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	methods: {
		closeModal (item) {
			this.$emit('close', item)
		}
	}
}
</script>

<style lang="scss">
.modal-footer {
	display: flex;
	justify-content: space-between !important;
}
</style>
