<template>
	<div class="fieldset news_correlate_cointainer">

		<div class="row">
			<div class="col three-quarters">
				<h2>{{title}}</h2>
			</div>
			<div class="col one-quarters">
				<Btn v-if="this.data.length < 5" @btn-click="showPostModal = true" :text="`Aggiungi`"  text-size="small" :isFull="true"></Btn>
			</div>
		</div>

		<LoadingSpinner v-if="loading" :small="true"></LoadingSpinner>

		<div class="news_correlate_cointainer__list" v-if="!loading">
			<div class="news_correlate_cointainer__list__item" v-for="(i, index) in items" :key="i.id">
				<div @click="remove(index)" class="news_correlate_cointainer__list__item__trash">
					<Icon icon="trash"></Icon>
				</div>
				<div class="new-box">
					<div class="news_correlate_cointainer__list__item__title" v-html="i.title"></div>
				</div>
			</div>
		</div>

		<SelectPostModal v-if="showPostModal" @close="select($event)"></SelectPostModal>
	</div>
</template>

<script>
import SelectPostModal from "@components/common/modals/SelectPostModal";
import Btn from "@components/common/forms/Btn";
import LoadingSpinner from "@components/common/Loading";
import Icon from "@components/common/forms/Icon";

export default {
	name: 'NewsCorrelate',
	components: {Icon, LoadingSpinner, Btn, SelectPostModal},
	data () {
		return {
			showPostModal: false,
			selected: null,
			items: [],
			loading: false,
		}
	},
	methods: {
		init (data) {
			this.items = [];
			if (data  && data.length) {
				this.loading = true;
				this.service.post.list_read_only(1, null, "publish", null, null, null, null, data)
					.then((res) => {
						if (res && res.length) {
							res.map(r => {
								this.items.push(r);
							})
						}
					})
					.finally(() => {
						this.loading = false;
					})
			}
		},
		select (id) {
			let data = [];
			this.data.map(d => data.push(d));
			data.push(id);
			data = [...new Set(data)];
			this.init(data);
			this.$emit("update", data);
			this.showPostModal = false;
		},
		remove(index) {
			this.data.splice(index, 1);
			this.init(this.data);
		}
	},
	mounted () {
		this.init(this.data);
	},
	props: {
		data: {
			required: true
		},
		title: {
			type: String,
			default: "News Correlate"
		},
		name: {
			type: String,
			default: '',
			required: true
		}
	}
}
</script>

<style lang="scss">

.news_correlate_cointainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 10px;
	border: 1px solid rgba($primary, 0.2);
	padding: 10px;
	border-radius: 5px;

	h2 {
		align-content: center;
	}

	&__list {
		display: flex;
		flex-direction: column;
		align-content: center;
		flex-wrap: wrap;
		width: 100%;

		&__item {
			display: flex;
			@extend %flexStartStart;
			width: 100%;
			margin-bottom: var(--gutter);
			.new-box {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				strong, input, div {
					display: block;
					width: 100%;
				}
				input {
					margin: 6px 0;
				}
			}
			&__trash {
				display: flex;
				@extend %flexCenterCenter;
				@include size(var(--icon),var(--icon));
				svg {
					cursor: pointer;
					fill: $primary;
				}

				&:hover {
					svg {
						fill: $red;
					}
				}
			}

		}
	}
	@media (min-width: $to-medium) {
		&__list {
			&__item {
				align-items: center;
			}
		}
	}
}

</style>
