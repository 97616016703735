<template>
	<div id="media_list" :class="(ispopup) ? '' : 'main-content'" class="page">
		<div class="page--head">
			<div class="head--title">
				<PageTitle v-if="!ispopup" :title_translation="false" title="Media" subtitle=""></PageTitle>

				<span class="floating">
					<Btn id="aggiungi" @btn-click="openUpload()" text-size="small" button-type="primary"
						 v-if="!showUpload && !loading" text="Aggiungi"></Btn>
				</span>
			</div>
			<div v-if="ispopup" class="page-main-title">
			</div>
			<span class="buttons">
				<Btn @btn-click="openUpload()" text-size="small" button-type="primary" v-if="showUpload && !loading"
					 text="Chiudi"></Btn>
				<div v-if="!showUpload" class="media_list_search_container">
					<input class="media_list_search" id="media_list_search" v-model="search"
						   :placeholder="'Cerca'">
					<div class="ricerca--controls">
						<div class="send_search_container control">
							<div class="send_search" @click="isSearch = true; page=1; refresh();">
								<Icon icon="search"></Icon>
							</div>
						</div>

						<div v-if="isSearch" class="trash_all_container control">
							<div class="trash_all" @click="isSearch = false; initSearch(); refresh();">
								<Icon icon="cross"></Icon>
							</div>
						</div>
					</div>
				</div>
			</span>
		</div>
		<div class="page--content">
			<!-- visualizzo le categorie dei media -->
			<div class="tabs main-tabs" v-if="!showUpload">
				<div :class="`tab tabopc ${(null == typeSelected) ? 'selected' : ''}`"
					 @click="typeSelected = null; categorySelected = null; refresh(); mediaSelected = null">
					Tutte
				</div>
				<div :class="`tab tabopc ${('image' == typeSelected) ? 'selected' : ''}`"
					 @click="typeSelected = 'image'; categorySelected = null; refresh(); mediaSelected = null">
					Immagini
				</div>
				<div :class="`tab tabopc ${('video' == typeSelected) ? 'selected' : ''}`"
					 @click="typeSelected = 'video'; categorySelected = null; refresh(); mediaSelected = null">
					Video
				</div>
				<div :class="`tab tabopc ${('titolatrice' == typeSelected) ? 'selected' : ''}`"
					 @click="typeSelected = 'titolatrice'; categorySelected = null; refresh(); mediaSelected = null">
					TelenuovoPlay
				</div>
				<div :class="`tab tabopc ${('application' == typeSelected) ? 'selected' : ''}`"
					 @click="typeSelected = 'application'; categorySelected = null; refresh(); mediaSelected = null">
					Files
				</div>
			</div>
			<div class="tabs--stage subtabs" v-if="!showUpload && typeSelected !== 'titolatrice'">
				<div class="subtabs--drag">
					<div class="subtabs-tabs">
						<div :class="`tab ${(c.id == categorySelected) ? 'selected' : ''}`" v-for="c in categories"
							 v-bind:key="c.id" @click="categorySelected = c.id; refresh(); mediaSelected = null">
							{{ c.caption }}
						</div>
					</div>
				</div>
			</div>
			<div class="tabs--stage subtabs" v-if="!showUpload && typeSelected == 'titolatrice'">
				<div class="subtabs--drag">
					<div class="subtabs-tabs">
						<select style="margin: 9px 0;" v-model="categorySelected" @change="refresh();">
							<option :value="null">Tutte le categorie</option>
							<option :value="c.id" v-for="c in titolatriceCategories" v-bind:key="c.id">
								{{ c.title }}
							</option>
						</select>
					</div>
				</div>
			</div>

			<div class="panel" v-if="!showUpload">
				<LoadingSpinner v-if="loading" :small="false"></LoadingSpinner>
				<div class="media" v-if="!loading">
					<div
						:class="`media__item o-ratio u-16:10 ${(mediaSelected && i.id == mediaSelected.id) ? 'selected' : ''}`"
						v-for="i in items" v-bind:key="i.id">

						<template v-if="!ispopup">
							<div class="button" style="position: absolute; top: 5px; left: 5px; cursor: pointer; display: block; width: 25px; height: 25px; z-index: 2" @click="copyLink(i.url)">
								<svg width="20" viewBox="0 0 40 40">
									<use fill="white" xlink:href="#icons--files" width="100%" height="100%"></use>
								</svg>
							</div>
						</template>

						<div v-if="i.media_type  != 'file'" :style="`background: url(${getThumb(i)}); background-size: cover;`"
							 class="media__item-img" @click="selectMedia(i)">

							<span class="video" v-if="i.media_type  == 'video'">
								Video
							</span>
							<span class="title">
								{{ service.helpers.decode(i.title) }}
							</span>
							<Btn v-if="ispopup && mediaSelected && mediaSelected.id == i.id" @btn-click="closeModal(mediaSelected)" text-size=""
								 button-type="primary" :text="`Seleziona`"></Btn>&nbsp;
						</div>

						<div v-if="i.media_type  == 'file'" :style="``" class="media__item-img" @click="selectMedia(i)">
							<span class="file">
								{{ i.mime_type.split("/").reverse()[0] }}
							</span>
							<span class="title">
								{{ service.helpers.decode(i.title) }}
							</span>
							<Btn v-if="ispopup && mediaSelected && mediaSelected.id == i.id" @btn-click="closeModal(mediaSelected)" text-size=""
								 button-type="primary" :text="`Seleziona`"></Btn>&nbsp;
						</div>
					</div>

				</div>
				<!-- <div v-if="selected">{{selected}}</div>-->
			</div>
			<div class="panel" style="padding: 20px !important;" v-if="showUpload">
				<div v-if="uploadError" class="upload_error">
					Errore durante l'upload.Riprovare
				</div>
				<LoadingSpinner v-if="loading" :small="true"></LoadingSpinner>

				<div v-if="loading && fakeBar" class="">
					Attendere caricamento media in corso...
				</div>
				<div class="fakeBar" v-if="fakeBar" :style="`display: block; width: ${fakeBar}%;`">
					{{ fakeBar.toFixed(2) }}%
				</div>

				<span class="file_upload" v-if="!loading && !cropEditor">
					<span v-if="!categorySelected">
						<a href='javascript:void(0)' @click="openCategorySelection = true">Aggiungi gli attributi del media</a>
					</span>
					<span v-if="categorySelected" style="line-height: 20px">
						Categoria Selezionata: <strong>{{ categorySelected.caption }}</strong>
						<br>Titolo: <strong>{{ titleSelected }}</strong>
						<br>Descrizione: <strong>{{ descriptionSelected }}</strong>
						<br><br><br>
					</span>
					<span @drop="onDrop($event, 1)" v-if="categorySelected" class="fake-btn">
						<span>
							<br>Trascina il file da caricare oppure<br>
						</span>
						<a href='javascript:void(0)'>Seleziona il file</a>
						<input name="mediaFile" @change="uploadFile($event.target.files)" type='file'/>
					</span>
				</span>
				<span v-if="cropEditor">
					<strong>Selezionare la porzione di immagine da utilizzare</strong>
					<br><br>
				</span>

				<div class="file_upload_cropper" v-if="selectedData && cropEditor" style="height: 300px">
					<cropper :src="selectedData" imageRestriction="fit-area" :stencil-props="{ aspectRatio: 16/10 }"
							 image-restriction="fit-area" default-boundaries="fill" @change="newMediaFile"></cropper>
				</div>

				<div v-if="selectedData && cropEditor" class="crop_buttons">
					<Btn @btn-click="uploadCropImage()" text-size="" button-type="primary" :text="`Carica`"></Btn>
					<Btn @btn-click="openUpload()" text-size="" button-type="default" :text="`Chiudi`"></Btn>
				</div>
			</div>

			<PaginationSimple v-if="!loading && !showUpload" :page="page" :page_end="pageEnd"
							  v-on:page="changePage($event)"></PaginationSimple>

			<CategorySelectionModal :categories="categories" v-if="openCategorySelection"
									@close="categorySelected = $event.category; titleSelected = $event.title; descriptionSelected = $event.description; openCategorySelection = false;"></CategorySelectionModal>

			<MediaDetailModal :categories="categories" v-if="mediaDetail" :media="mediaDetail"
							  @close="mediaDetail = null; refresh()"></MediaDetailModal>

			<div class="crop_buttons" v-if="ispopup && !showUpload && !loading">
				<Btn v-if="ispopup && mediaSelected" @btn-click="closeModal(mediaSelected)" text-size=""
					 button-type="primary" :text="`Seleziona`"></Btn>&nbsp;
				<span v-if="ispopup && !mediaSelected"></span>&nbsp;
				<Btn v-if="ispopup" @btn-click="closeModal(null)" text-size="" button-type="default"
					 :text="`Annulla`"></Btn>
			</div>
		</div>
	</div>
</template>

<script>
import PageTitle from "../../components/common/PageTitle";
import LoadingSpinner from "../../components/common/Loading";
import Btn from "../../components/common/forms/Btn";
import PaginationSimple from "../../components/common/forms/PaginationSimple";
import {Cropper} from 'vue-advanced-cropper';
import CategorySelectionModal from "../../components/common/modals/CategorySelectionModal";
import MediaDetailModal from "@components/common/modals/MediaDetailModal";
import Icon from "../../components/common/forms/Icon";
import heic2any from "heic2any";

export default {
	name: 'MediaList',
	components: {
		MediaDetailModal,
		CategorySelectionModal,
		PaginationSimple,
		Btn,
		LoadingSpinner,
		PageTitle,
		Cropper,
		Icon
	},
	props: {
		ispopup: {
			required: false,
			default: false
		},
		onlyimage: {
			required: false,
			default: false
		},
		onlyfiles: {
			required: false,
			default: false
		}
	},
	data () {
		return {
			isSearch: false,
			testVideo: 'https://mediastream1.telenuovo.it/video//2020/12/28/8b0e9ea5-583d-4fb3-ac57-1c2e31872088/playlist.m3u8',
			loading: false,
			cropEditor: false,
			uploadError: false,
			items: [],
			page: 1,
			pageEnd: false,
			search: '',
			sort: null,
			selected: null,
			showUpload: false,
			selectedData: null,
			selectedDataMime: null,
			selectedCropImage: null,
			categories: [],
			titolatriceCategories: [],
			openCategorySelection: false,
			categorySelected: null,
			titleSelected: '',
			descriptionSelected: '',
			mediaSelected: null,
			typeSelected: null,
			fakeBar: null,
			mediaDetail: null
		}
	},
	methods: {
		/** Inizializzo la ricerca dei media */
		initSearch () {
			this.search = '';
			this.page = 1;
		},
		/** Prendo la thumb del media */
		getThumb (media) {
			if (media.media_type == 'image') {
				return media.url
			} else {
				return this.getVideoThumbs(media.url);
			}
		},
		/** Se si tratta di un video elaboro la sua thumb */
		getVideoThumbs (src) {
			let n = src.replace(/playlist.m3u8/g, 'thumbnail.jpg');
			return n;
		},
		copyLink (url) {
			let newUrl = url;
			let tobecopied = document.createElement('input');
			tobecopied.classList.add('tobecopied')
			tobecopied.value = newUrl;
			document.body.appendChild(tobecopied);
			tobecopied.focus();
			tobecopied.select();
			document.execCommand('copy');
			let tooltip = document.createElement('div');
			tooltip.classList.add('tooltip');
			tooltip.innerHTML = 'Link copiato!'
			document.body.appendChild(tooltip);
			this.$gsap.to(tooltip, {
				duration: 0.3,
				opacity: 1,
				onComplete: () => {
					this.flashMessage.show({
						status: 'success',
						title: 'Link',
						message: "Link copiato correttamente"
					});
				}
			})
		},
		/** Refresh della lista media */
		refresh () {
			this.loading = true;
			this.uploadError = false;
			this.cropEditor = false;
			this.fakeBar = null;
			this.showUpload = false;
			this.items = [];

			if (this.onlyimage) {
				this.typeSelected = "image";
			}
			if (this.onlyfiles) {
				this.typeSelected = "application";
			}

			this.service.categories.list()
				.then(categories => {
					this.categories = [
						{id: null, caption: "Tutti"}
					];
					if (categories && categories.length) {
						categories.map(c => {
							if (c.slug.indexOf('tg') == 0) {
								this.categories.push({id: c.id, caption: c.name});
							}
						});
					}
					this.categories.push({
						id: 1,
						caption: "MediaWeb"
					});
					this.categories.push({
						id: 999,
						caption: "L Banner"
					});

					this.service.media.titolatrice.getCategories(true)
						.then((catsTit) => {

							this.titolatriceCategories = [];

							if(catsTit && catsTit.data && catsTit.data.length) {
								this.titolatriceCategories = catsTit.data;
							}

							this.service.media.list(this.page, this.sort, this.search, this.categorySelected, this.typeSelected)
								.then(items => {
									this.items = [];
									items.forEach(i => {
										i.url = (+i.category !== 999) ? `${i.url}`.replace("1280x720", "1200x620") : i.url;
										i.url = (+i.category === 999) ? `${i.url}`.replace(".jpg", ".png") : i.url;
										console.log(i.url)
										// TODO: Da sistemare che è una cagata intergalattica
										if(!(i.media_type == "file" && i.mime_type == "video/mp4")) {
											this.items.push(i);
										}
									})
								})
								.finally(() => {
									this.loading = false;
								})
						})


				})
		},
		/** Cambio pagina */
		changePage (page) {
			this.page = page;
			this.refresh();
		},
		/** Seleziono un media */
		selectMedia (media) {
			if (this.ispopup) {
				this.mediaSelected = media;
			} else {
				this.mediaDetail = media;
			}
		},
		/** Chiudo la modale */
		closeModal (item) {
			this.$emit('close', item)
		},
		/** Una volta fatto il crop dell'immagine prendo il suo dato e ritorno un blob */
		newMediaFile ({canvas}) {
			canvas.toBlob((blob) => {
				const reader = new FileReader();
				let that = this;
				reader.addEventListener('loadend', () => {
					const arrayBuffer = reader.result;
					that.selectedCropImage = arrayBuffer;
				});
				reader.readAsArrayBuffer(blob);
			}, this.uploadDataMime)
		},
		/** Eseguo l'upload dell'immagine cropppata */
		uploadCropImage: function () {
			this.loading = true;
			this.cropEditor = false;

			let now = this.$moment();
			let kb = 200;

			this.service.media.check(kb)
				.then(() => {
					let nowAfterCheck = this.$moment();
					let secondiPassati = nowAfterCheck.valueOf() - now.valueOf();
					if (secondiPassati <= 0) {
						secondiPassati = 1;
					}

					let dimensione = 1024;
					if (this.selectedCropImage) {
						dimensione = this.selectedCropImage.byteLength * 4;
					}

					secondiPassati = (secondiPassati * 0.7) / 1000; // aggiungo un po'
					let tempoCaricamento = ((dimensione / 1024) * secondiPassati) / kb;
					tempoCaricamento = Math.round(tempoCaricamento);
					tempoCaricamento = tempoCaricamento + 30;
					console.log(`Tempo Estimato per upload: ${tempoCaricamento} secondi, su un test di ${secondiPassati} secondi`);

					let start = 1;
					let timer = setInterval(() => {
						console.log(`Ci sono ${start}`);
						this.fakeBar = (100 / tempoCaricamento) * start;
						start = start + 1;
						if (start > tempoCaricamento) {
							this.fakeBar = null;
							clearInterval(timer);
						}
					}, 1000);

					this.service.media.upload(this.selectedCropImage, this.selectedDataMime, this.titleSelected, this.descriptionSelected, this.categorySelected.id)
						.then((media) => {
							/*this.loading = false;
							this.fakeBar = null;
							clearInterval(timer);
							this.openUpload();
							this.flashMessage.show({
								status: 'success',
								title: `Caricamento immagine`,
								message: `L'immagine è stata caricata con successo`
							});*/

							this.checkFile(media.id, 0)
								.then(() => {
									this.loading = false;
									this.fakeBar = null;
									clearInterval(timer);
									this.openUpload();
									this.flashMessage.show({
										status: 'success',
										title: `Caricamento`,
										message: `Il media è stato caricato con successo.`
									});
								})
								.catch(() => {
									console.log(`Errore`);
								})
						})
						.catch(() => {
							this.fakeBar = null;
							clearInterval(timer);
							this.selectedData = null;
							this.selectedDataMime = null;
							this.sDataelectedCropImage = null;
							this.uploadError = true;
							this.loading = false;
						})

				})
				.catch(() => {
					this.fakeBar = null;
					this.error = 'Errore durante il controllo della banda.';
					this.loading = false;
				})


		},
		/** Apro l'interfaccia di upload */
		openUpload () {
			this.selectedData = null;
			this.selectedDataMime = null;
			this.selectedCropImage = null;
			this.categorySelected = null;
			this.uploadError = false;
			this.showUpload = !this.showUpload;

			if (this.showUpload == false) {
				this.page = 1;
				this.refresh();
			}
		},
		/**
		 * Eseguo il check del file in modo ricorsivo. Al momento non serve più se
		 * si gestisce solo il singolo upload
		 */
		checkFile (media_id, index) {
			return new Promise((resolve, reject) => {
				console.log(`[f, ${media_id}] ${index}`)
				// dopo 30 volte che viene chiamato allora
				// ritorna un errore
				if (index > 400) {
					this.flashMessage.show({
						status: 'error',
						title: `Upload`,
						message: `Non è stato possibile caricare il video.`
					});
					reject();
				} else {
					this.service.media.single(media_id)
						.then(media => {
							if (media) {
								console.log(`[f, ${media_id}] Trovato`, media);
								if (media.status == "OK") {
									resolve();
								} else {
									setTimeout(() => {
										return resolve(this.checkFile(media_id, ++index))
									}, 1000)
								}
							} else {
								setTimeout(() => {
									return resolve(this.checkFile(media_id, ++index))
								}, 1000)
							}
						})
						.catch(() => {
							setTimeout(() => {
								return resolve(this.checkFile(media_id, ++index))
							}, 1000)
						})
				}
			});
		},
		uploadFile (fileList) {
			this.loading = true;
			this.cropEditor = false;
			this.uploadError = false;
			Array
				.from(Array(fileList.length).keys())
				.map(x => {
					let reader = new FileReader();
					let that = this;
					let type = (fileList[0].type || "").split("/")[0];
					console.log(`Type: ${type}, Category: ${this.categorySelected.id}`);
					that.fakeBar = null;
					// se è un LBanner carico direttamente
					//
					if(type === "image" && this.categorySelected && this.categorySelected.id === 999) {
						that.fakeBar = 0;
						reader.onload = function (e) {
							try {
								// creo un api per il controllo della banda
								let mimeType = fileList[0].type;
								let data = e.target.result;
								let now = that.$moment();
								let kb = 200; // 200 kb per il test
								that.service.media.check(kb)
									.then(() => {
										let nowAfterCheck = that.$moment();
										let secondiPassati = nowAfterCheck.valueOf() - now.valueOf();
										if (secondiPassati <= 0) {
											secondiPassati = 1;
										}
										secondiPassati = (secondiPassati * 0.7) / 1000; // aggiungo un po'
										let tempoCaricamento = ((data.byteLength / 1024) * secondiPassati) / kb;
										tempoCaricamento = Math.round(tempoCaricamento);
										tempoCaricamento = tempoCaricamento + 30;
										console.log(`Tempo stimato per upload: ${tempoCaricamento} secondi, su un test di ${secondiPassati} secondi`);

										let start = 1;
										let timer = setInterval(() => {
											console.log(`Ci sono ${start}`);
											that.fakeBar = (100 / tempoCaricamento) * start;
											start = start + 1;
											if (start > tempoCaricamento) {
												that.fakeBar = null;
												clearInterval(timer);
											}
										}, 1000);

										that.service.media.upload(data, mimeType, that.titleSelected, that.descriptionSelected, that.categorySelected.id)
											.then((media) => {
												that.loading = false;
												that.fakeBar = null;
												clearInterval(timer);
												that.openUpload();
												that.flashMessage.show({
													status: 'success',
													title: `LBanner caricato`,
													message: `Sarà disponibile nei media a transcodifica terminata.`
												});

												if(that.ispopup) {
													that.closeModal(null);
												}
											})
											.catch((err) => {
												console.log(err);
												clearInterval(timer);
												that.fakeBar = null;
												that.selectedData = null;
												that.selectedDataMime = null;
												that.selectedCropImage = null;
												that.uploadError = true;
												that.loading = false;
											})

									})
									.catch(() => {
										that.errorData = 'Errore durante il controllo della banda.';
										that.loading = false;
									})

							} catch (e) {
								that.errorData = 'File non valido';
								that.loading = false;
							}

						}
						console.log(fileList[x])
						reader.readAsArrayBuffer(fileList[x]);
						type = null; // inizializzo il tutto per evitare un doppio caricamento
					}
					// se è un video
					// carico direttamente il file
 					if (type == "video") {
						that.fakeBar = 0;
						reader.onload = function (e) {
							try {
								// creo un api per il controllo della banda
								let mimeType = fileList[0].type;
								let data = e.target.result;
								let now = that.$moment();
								let kb = 200; // 200 kb per il test
								that.service.media.check(kb)
									.then(() => {
										let nowAfterCheck = that.$moment();
										let secondiPassati = nowAfterCheck.valueOf() - now.valueOf();
										if (secondiPassati <= 0) {
											secondiPassati = 1;
										}
										secondiPassati = (secondiPassati * 0.7) / 1000; // aggiungo un po'
										let tempoCaricamento = ((data.byteLength / 1024) * secondiPassati) / kb;
										tempoCaricamento = Math.round(tempoCaricamento);
										tempoCaricamento = tempoCaricamento + 30;
										console.log(`Tempo stimato per upload: ${tempoCaricamento} secondi, su un test di ${secondiPassati} secondi`);

										let start = 1;
										let timer = setInterval(() => {
											console.log(`Ci sono ${start}`);
											that.fakeBar = (100 / tempoCaricamento) * start;
											start = start + 1;
											if (start > tempoCaricamento) {
												that.fakeBar = null;
												clearInterval(timer);
											}
										}, 1000);

										that.service.media.upload(data, mimeType, that.titleSelected, that.descriptionSelected, that.categorySelected.id)
											.then((media) => {
												that.loading = false;
												that.fakeBar = null;
												clearInterval(timer);
												that.openUpload();
												that.flashMessage.show({
													status: 'success',
													title: `Video caricato`,
													message: `Il video sarà disponibile nei media a transcodifica terminata.`
												});

												if(that.ispopup) {
													that.closeModal(null);
												}
											})
											.catch((err) => {
												console.log(err);
												clearInterval(timer);
												that.fakeBar = null;
												that.selectedData = null;
												that.selectedDataMime = null;
												that.selectedCropImage = null;
												that.uploadError = true;
												that.loading = false;
											})

									})
									.catch(() => {
										that.errorData = 'Errore durante il controllo della banda.';
										that.loading = false;
									})

							} catch (e) {
								that.errorData = 'File non valido';
								that.loading = false;
							}

						}
						reader.readAsArrayBuffer(fileList[x]);

					}
					// se si tratta di un'immagine carico
					// la parte del crop
					if (type == "image") {
						this.fakeBar = null;
						reader.onload = function (e) {
							try {
								let data = e.target.result;
								let mimeType = fileList[0].type;
								if(mimeType == 'image/heic') {
									const byteCharacters = atob(data.replace('data:image/heic;base64,', ''));
									const byteArrays = [];
									const sliceSize = 512;
									const contentType = 'image/heic';
									for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
										const slice = byteCharacters.slice(offset, offset + sliceSize);
										const byteNumbers = new Array(slice.length);
										for (let i = 0; i < slice.length; i++) {
											byteNumbers[i] = slice.charCodeAt(i);
										}
										const byteArray = new Uint8Array(byteNumbers);
										byteArrays.push(byteArray);
									}
									const blob = new Blob(byteArrays, {type: contentType});
									// conversione
									heic2any({
										blob,
										toType: "image/jpeg",
										quality: 1,
									}).then((conversionResult) => {
										var r = new FileReader();
										r.onload = function() {
											that.selectedCropImage = null;
											that.selectedData = r.result;
											that.selectedDataMime = "image/jpeg";
											that.loading = false;
											that.cropEditor = true;
										};
										r.readAsDataURL(conversionResult);
									})
								} else {
									that.selectedCropImage = null;
									that.selectedData = data;
									that.selectedDataMime = mimeType;
									that.loading = false;
									that.cropEditor = true;
								}
							} catch (e) {
								that.errorData = 'File non valido';
								that.loading = false;
							}
						}
						reader.readAsDataURL(fileList[x]);
					}
					//
					// File
					if(type !== "video" && type !== "image") {
						that.fakeBar = 0;
						reader.onload = function (e) {
							try {
								let mimeType = fileList[0].type;
								let data = e.target.result;
								let now = that.$moment();
								let kb = 200; // 200 kb per il test
								that.service.media.check(kb)
									.then(() => {
										let nowAfterCheck = that.$moment();
										let secondiPassati = nowAfterCheck.valueOf() - now.valueOf();
										if (secondiPassati <= 0) {
											secondiPassati = 1;
										}
										secondiPassati = (secondiPassati * 0.7) / 1000; // aggiungo un po'
										let tempoCaricamento = ((data.byteLength / 1024) * secondiPassati) / kb;
										tempoCaricamento = Math.round(tempoCaricamento);
										tempoCaricamento = tempoCaricamento + 30;
										console.log(`Tempo stimato per upload: ${tempoCaricamento} secondi, su un test di ${secondiPassati} secondi`);

										let start = 1;
										let timer = setInterval(() => {
											console.log(`Ci sono ${start}`);
											that.fakeBar = (100 / tempoCaricamento) * start;
											start = start + 1;
											if (start > tempoCaricamento) {
												that.fakeBar = null;
												clearInterval(timer);
											}
										}, 1000);

										that.service.media.upload(data, mimeType, that.titleSelected, that.descriptionSelected, that.categorySelected.id)
											.then((media) => {
												that.loading = false;
												that.fakeBar = null;
												clearInterval(timer);
												that.openUpload();
												that.flashMessage.show({
													status: 'success',
													title: `File caricato`,
													message: `Il file sarà disponibile fra qualche secondo.`
												});
												if(that.ispopup) {
													that.closeModal(null);
												}
											})
											.catch((err) => {
												console.log(err);
												clearInterval(timer);
												that.fakeBar = null;
												that.selectedData = null;
												that.selectedDataMime = null;
												that.selectedCropImage = null;
												that.uploadError = true;
												that.loading = false;
											})

									})
									.catch(() => {
										that.errorData = 'Errore durante il controllo della banda.';
										that.loading = false;
									})

							} catch (e) {
								that.errorData = 'File non valido';
								that.loading = false;
							}

						}
						reader.readAsArrayBuffer(fileList[x]);

					}
				});
		},
		/** Drop - Check, da testare ed eliminare */
		onDrop (evt, list) {
			console.log('ONDROP', evt, list)
		}
	},
	mounted () {
		this.refresh()
	}
}
</script>
<style lang="scss">
.page--head {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
}

.panel {
	display: block;
	width: 100%;
	background: $white;
}

.upload_error {
	background: red;
	color: white;
	text-align: center;
	padding: 20px;
	margin-bottom: 20px;
}

.buttons {
	display: flex;
	@extend %flexCenterEnd;
	width: 100%;
	margin-top: var(--gutter);

	#aggiungi {
		display: none;
	}

	button {
		margin: 0 var(--minigutter) 0 0;
	}

	@media (min-width: $to-medium) {
		width: auto;
		margin-top: 0;
		#aggiungi {
			display: block;
		}
		.media_list_search_container {
			.ricerca--controls {
				width: auto;
				margin: 0;

				.control {
					margin: 0 10px;

					&.trash_all_container {
						width: var(--icon);

						.trash_all {
							.input-icon {
								display: flex !important;
							}
						}
					}
				}
			}
		}
	}
}

.media {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;

	/*
	* KEKKO RICORDA CHE TI VOGLIO BENE!
	*/
	&__item {
		display: inline-block;
		width: calc((100% / 6) - 12px);
		margin: 6px;
		overflow: hidden;
		cursor: pointer;
		box-shadow: 0 0 5px rgba(#000, 0.7);

		div {
			width: 100%;
			height: 100%;
			position: relative;

			.video {
				width: 100%;
				position: absolute;
				top: 0;
				padding: 5px;
				background: $black;
				color: $red;
				text-align: right;
				font-size: 12px;
			}

			.file {
				width: 100%;
				position: absolute;
				top: 0;
				padding: 49px 5px 5px;
				//background: $black;
				color: $red;
				text-align: right;
				font-size: 40px;
				text-align: center;
			}

			.title {
				width: 100%;
				position: absolute;
				bottom: 0;
				background: $black;
				color: $white;
				padding: 10px;
				opacity: .5;
			}
		}

		&.selected {
			border: 5px solid $red;
		}
	}

}

.media_list_search_container {
	margin-left: 10px;
	margin-top: 11px;

	input.media_list_search {
		height: 46px;
	}

	span {
		display: none;
	}
}

.file_upload_cropper {
}

.file_upload {
	position: relative;

	span {
		cursor: pointer !important;
	}

	a {
		position: relative;
		overflow: hidden;
		font-weight: 600;
		color: $primary;
	}

	span:hover a {
		color: $white;
		background: $primary;
		padding: 2px;
		border-radius: 2px;
	}

	a + input {
		position: absolute;
		top: 95px;
		left: 0;
		opacity: 0;
		cursor: pointer;
	}
}

.fakeBar {
	height: 10px;
	background: $primary;
	color: white;
	transition: 300ms linear;
	display: block;
	line-height: 34px;
	height: 34px;
	text-align: right;
	padding-right: 15px;
	font-weight: 700;
	margin-top: 20px;
}

.crop_buttons {
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;
}


/* --- APPREZZO LO SFORZO <3 --- */
img {
	max-width: 100%;
}

#media_list {
	.page--head {
		display: flex;
		flex-wrap: nowrap;
		@extend %flexCenterBetween;
		width: 100%;
		// margin-bottom: var(--gutter);

		.head--title {
			padding: calc(var(--gutter) / 2);
			margin-bottom: 5px !important;
		}

		.buttons {
			// padding: calc(var(--gutter) / 2);
			display: flex;
			flex-wrap: wrap;
			@extend %flexCenterBetween;
			width: 100%;
			// margin: var(--gutter) 0;
			margin-top: 0;

			@media (min-width: $to-medium) {
				width: 100%;
				min-width: 200px;
			}

			button {
				margin: 0;

				&:nth-of-type(1) {
					// display: none;
				}
			}

			.media_list_search_container {
				display: flex;
				flex-wrap: nowrap;
				width: 100%;
				margin: 0;

				.media_list_search {
					margin: 10px;
				}

				input {
					min-width: 100px;
					width: 100%;
				}

				.ricerca--controls {
					display: flex;
					@extend %flexCenterAround;
					// width: 100%;
					padding: 6px 0;

					.control {
						display: flex;
						@extend %flexCenterCenter;
						@include size(calc(var(--icon) * 2), var(--icon));
						background: $bg;

						.send_search {
							cursor: pointer;
							display: block;
							@include size(100%, 100%);

							.input-icon {
								display: flex;
								@extend %flexCenterCenter;
								@include size(100%, 100%);

								svg {
									display: block;
									@include size(60%, 60%);
									fill: $white;
								}
							}
						}

						&.trash_all_container {
							cursor: pointer;
							background: transparent;
							border: 1px solid $bg;

							svg {
								fill: $bg;
							}
						}
					}
				}
			}
		}
	}

	.page--content {
		margin: 0;
	}

	.tabs {
		display: flex;
		flex-wrap: nowrap;
		@include size(100%, var(--icon));

		&.main-tabs {
			overflow: scroll;
		}

		.tab {
			display: flex;
			@extend %flexCenterCenter;
			flex-grow: 1;
			@include size(auto, 100%);
			padding: 0 var(--gutter);
			background: rgba($bg, 0.4);
			color: $bg;

			&.selected {
				background: $white;
				color: $bg;
			}

			&.disabled {
				pointer-events: none;
			}
		}
	}

	.subtabs {
		display: block;
		@include size(100%, var(--input));

		.subtabs--drag {
			display: block;
			width: 100%;
			height: 100%;
			overflow-x: auto;
			position: relative;

			.subtabs-tabs {
				position: absolute;
				display: flex;
				flex-wrap: nowrap;
				width: auto;
				height: 100%;
				min-width: 100%;

				.tab {
					display: flex;
					@extend %flexCenterCenter;
					@include size(auto, 100%);
					padding: 0 var(--gutter);
					background: rgba($bg, 0.4);
					color: $bg;

					&.selected {
						background: $white;
						color: $bg;
					}

					&.disabled {
						pointer-events: none;
					}
				}
			}
		}
	}

	.panel {
		padding: var(--gutter) 0 !important;

		.media {
			&__item {
				width: 48%;
				margin: 1%;
				position: relative;

				.media__item-img {
					position: absolute;
					top: 0;
					left: 0;
					display: block;
					@include size(100%, 100%);

					.btn {
						position: absolute;
						top: 50%;
						left: 50%;
						background: $white;
						color: $blue;
						transform: translate(-50%, -50%);
						margin: 0;

					}
				}
			}
		}
	}

	@media (min-width: $to-medium) {
		.head {
			.buttons {
				margin: var(--gutter) 0;

				button {
					&:nth-of-type(1) {
						display: block;
					}
				}

				.media_list_search_container {
					width: auto;
				}
			}
		}
		.panel {
			.media {
				&__item {
					width: 18%;
				}
			}
		}
	}
}

.fake-btn {
	display: block;
	@include size(auto, calc(var(--icon) * 2));
	position: relative;
	text-align: center;
	border: 1px dashed cadetblue;

	input[type="file"] {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		@include size(100%, 100%);
	}

	&:hover {
		background: #183351;
		color: white;
	}

}

</style>
