import axios from 'axios';
const crypto = require("crypto");

const SOCIAL_API_URL = (process.env.VUE_APP_SOCIAL_API_URL !== undefined && process.env.VUE_APP_SOCIAL_API_URL !== "") ? process.env.VUE_APP_SOCIAL_API_URL : '{{ VUE_APP_SOCIAL_API_URL }}';
const SOCIAL_API_HEAD = (process.env.VUE_APP_SOCIAL_API_HEAD !== undefined && process.env.VUE_APP_SOCIAL_API_HEAD !== "") ? process.env.VUE_APP_SOCIAL_API_HEAD : '{{ VUE_APP_SOCIAL_API_HEAD }}';
const SOCIAL_API_SIGN = (process.env.VUE_APP_SOCIAL_API_SIGN !== undefined && process.env.VUE_APP_SOCIAL_API_SIGN !== "") ? process.env.VUE_APP_SOCIAL_API_SIGN : '{{ VUE_APP_SOCIAL_API_SIGN }}';
export default {
	install: function (Vue) {
		// global $http object
		Object.defineProperty(Vue.prototype, '$http', {value: axios});
		// axios settings
		axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';
		//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
		//axios.defaults.withCredentials = false;
		/**
		 * Creazione della risposta
		 * @param response
		 * @param resolve
		 * @param reject
		 * @param token
		 * @param redirectUnauthorized
		 */
		const buildResponse = (response, resolve, reject) => {
			let data = response.data || "";
			let status = response.status || null;

			if (response && response.status && status == 200) {
				resolve(data);
			}
			if (response && data.messages && status != 200) {
				reject(data);
			}
			reject({
				messages: ['api-general-error']
			});
		};
		/**
		 *
		 */
		const getBaseHeaders = () => {
            const headers = {}
			const token = crypto.createHmac("sha256", SOCIAL_API_SIGN).digest('hex');
            headers[`${SOCIAL_API_HEAD}`] = token;

			return {
				validateStatus: () => {
					return true;
				},
				headers: {
					...headers
				}
			}
		}
		//
		// definizione delle api
		//
		const socialApi = {
			get (resource, data) {
				let config = getBaseHeaders();
				if (data) {
					config.data = data;
				}
				return new Promise((resolve, reject) => {
					axios.get(`${SOCIAL_API_URL}${resource}`, config)
						.catch((error) => {
							reject({
								success: false,
								error: error
							});
						})
						.then((result) => {
							buildResponse(result, resolve, reject);
						});
				});
			},
			post (resource, data) {
				let config = getBaseHeaders();
				return new Promise((resolve, reject) => {
					axios.post(`${SOCIAL_API_URL}${resource}`, data, config)
						.catch((error) => {
							reject({
								success: false,
								error: error
							});
						}).then((result) => {
						buildResponse(result, resolve, reject);
					});
				});
			},
			put (resource, data) {
				let config = getBaseHeaders();
				return new Promise((resolve, reject) => {
					axios.put(`${SOCIAL_API_URL}${resource}`, data, config)
						.catch((error) => {
							reject({
								success: false,
								error: error
							});
						}).then((result) => {
						buildResponse(result, resolve, reject);
					});
				});
			},
			delete (resource, data) {
				let config = getBaseHeaders();
				if (data) {
					config.data = data;
				}
				return new Promise((resolve, reject) => {
					axios.delete(`${SOCIAL_API_URL}${resource}`, config)
						.catch((error) => {
							reject({
								success: false,
								error: error
							});
						})
						.then((result) => {
							buildResponse(result, resolve, reject);
						});
				});
			}
		};
		Vue.prototype.$socialApi = socialApi;
	}
}
