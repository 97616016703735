<template>
	<div id="post_list" :class="'main-content'" class="page">
		<div class="page--head">
			<div class="head--title">
				<PageTitle :title_translation="false" :title="`Video Report`" :subtitle="``"></PageTitle>
			</div>
		</div>
		<div class="page--content">
			<!-- main report -->
			<div class="panel video_report" v-if="disks.loading">
				<p>
					Caricamento statistiche generali in corso...
				</p>
			</div>
			<div class="panel video_report" v-if="!disks.loading && disks.video.total && disks.image.total">
				<div class="summary">
					<div class="summary__item">
						<h2>Video</h2>
					</div>
					<div class="summary__item">
						<h2>Immagini</h2>
					</div>
				</div>
				<div class="summary">
					<div class="summary__item">
						<div class="summary__item__image">
							<img :src="getChart(disks.video)" alt="">
						</div>
						<div class="summary__item__data">
							Spazio Occupato: <strong>{{ humanFileSize(disks.video.data) }}
							({{ ((disks.video.data / disks.video.total) * 100).toFixed(0) }}%)</strong>
							<br>Spazio Disponibile: <strong>{{ humanFileSize(disks.video.free) }}
							({{ 100 - ((disks.video.data / disks.video.total) * 100).toFixed(0) }}%)</strong>
							<br>Spazio Totale: <strong>{{ humanFileSize(disks.video.total) }}</strong>
						</div>
					</div>
					<div class="summary__item">
						<div class="summary__item__image">
							<img :src="getChart(disks.image)" alt="">
						</div>
						<div class="summary__item__data">
							Spazio Occupato: <strong>{{ humanFileSize(disks.image.data) }}
							({{ ((disks.image.data / disks.image.total) * 100).toFixed(0) }}%)</strong>
							<br>Spazio Disponibile: <strong>{{ humanFileSize(disks.image.free) }}
							({{ 100 - ((disks.image.data / disks.image.total) * 100).toFixed(0) }}%)</strong>
							<br>Spazio Totale: <strong>{{ humanFileSize(disks.image.total) }}</strong>
						</div>
					</div>
				</div>
			</div>
			<!-- base objects -->
			<div class="panel video_report" v-if="mediaData.loading">
				<p>
					Caricamento media generali in corso...
				</p>
			</div>

			<div v-if="data">
				<div v-for="(d, key) in data" :key="key">
					<div class="panel video_report" v-if="!disks.loading && !mediaData.loading && mediaData.count">
						<h2>{{ d.title }}</h2>
						<p>
							Stato:
							<strong class="analisi_loading" v-if="d.loading">Analisi in corso ({{ d.status }})</strong>
							<strong class="analisi_loading" v-if="!d.loading && !d.terminated">In attesa</strong>
							<strong class="analisi_terminata" v-if="!d.loading && d.terminated">Analisi
								terminata</strong>

							<span v-if="d.orphans.count != 0">
								<br>Orfani:
								<strong>
									{{ d.orphans.count }}
									<span v-if="d.orphans.count">({{ humanFileSize(d.orphans.size) }})</span>
								</strong>
							</span>
						</p>

						<p v-if="d.terminated && d.orphans.count == 0">
							Nessun orfano trovato.
						</p>

						<div v-if="d.terminated && d.orphans.count != 0">
							<table class="report_table">
								<thead>
								<tr>
									<th>
										<button class="delete_button" @click="deleteItems(d.orphans)">
											Elimina sel.
										</button>
									</th>
									<th>File</th>
									<th>Date</th>
									<th>Size</th>
								</tr>
								</thead>
								<tbody>
								<tr v-for="(m, index) in d.orphans.items" :key="index">
									<template v-if="!m.deleted">
										<td align="center">
											<input :disabled="m.deleting" type="checkbox" v-model="m.checked">
											<i style="color: red; font-size: 11px; padding-left: 5px;"
											   v-if="m.deleting">Eliminazione in corso</i>
										</td>
										<td>
											<a :style="{color: m.deleting ? 'lightgray' : 'black'}"
											   style="font-weight: 600" target="new"
											   :href="createLinkFromFile(m.output)"
											   v-if="m.output && m.output !== 'null'">
												{{ createLinkFromFile(m.output) }}
											</a>
										</td>
										<td>{{ m.createdOn | data }}</td>
										<td>{{ humanFileSize(m.size) }}</td>
									</template>
								</tr>
								</tbody>
							</table>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageTitle from "@components/common/PageTitle";

const mediaType = {
	VIDEO_UPLOADER: 'media-video-uploader',
	VIDEO_PROCESSOR: 'media-video-processor',
	IMAGE_UPLOADER: 'media-image-uploader',
	FILE_UPLOADER: 'media-file-uploader',
}

export default {
	name: 'BaseReportPage',
	components: {
		PageTitle
	},
	data () {
		return {
			disks: {
				loading: true,
				video: {
					total: 0,
					free: 0,
					data: 0,
				},
				image: {
					total: 0,
					free: 0,
					data: 0,
				}
			},
			data: null,
			mediaData: {
				loading: true,
				count: 0,
				data: []
			}
		}
	},
	methods: {
		/**
		 *
		 * @param f
		 * @returns {string}
		 */
		createLinkFromFile (f) {
			let newFile = ``;
			if (`${f}`.indexOf('mediastream') > 0) {
				newFile = `${f}`.trim().replace(`/mnt/mediastream.telenuovo.it`, `https://mediastream.telenuovo.it`)
				newFile = `${newFile}/playlist.m3u8`
			} else {
				newFile = `${f}`.trim().replace(`/mnt/cdnimage.telenuovo.it`, `https://cdnimage.telenuovo.it`)
				if (newFile.slice(-4, -3) != "." && newFile.slice(-5, -4) != ".") {
					newFile = `${newFile}/600x375.jpg`
				}
			}
			return newFile
		},
		/**
		 *
		 * @param bytes
		 * @param si
		 * @param dp
		 * @returns {string}
		 */
		humanFileSize (bytes, si = false, dp = 1) {
			const thresh = si ? 1000 : 1024;
			if (Math.abs(bytes) < thresh) {
				return bytes + ' B';
			}
			const units = si
				? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
				: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
			let u = -1;
			const r = 10 ** dp;
			do {
				bytes /= thresh;
				++u;
			} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
			return bytes.toFixed(dp) + ' ' + units[u];
		},
		/**
		 *
		 * @param d
		 * @returns {string}
		 */
		getChart (d) {
			const data = ((d.data / d.total) * 100).toFixed(0);
			const free = 100 - data;
			let url = `https://chart.googleapis.com/chart?cht=p&chs=430x300&chd=t:${data},${free}&chl=Occupato|Libero`
			return url
		},
		/**
		 *
		 * @param type
		 * @returns {Promise<unknown>}
		 */
		getGeneralStats (type) {
			return new Promise((resolve) => {
				const url = `https://mediaserver.telenuovo.it/production/${type}/stats`;
				this.$api.getUrlComplete(url)
					.then((stats) => {
						let statsData = {
							type: type,
							total: stats.total,
							total_formatted: this.humanFileSize(stats.total),
							free: stats.free,
							free_formatted: this.humanFileSize(stats.free),
							data: stats.videos ? stats.videos : (stats.images ? stats.images : stats.files),
							data_formatted: this.humanFileSize(stats.videos ? stats.videos : (stats.images ? stats.images : stats.files)),
							items: [],
							errorsItems: [],
							orphansItems: [],
							count: 0,
							errors: 0,
							orphans: 0,
							orphanSize: 0,
							noOrphans: 0
						}
						resolve(statsData)
					})
					.catch(() => {
						resolve(null)
					})
			})
		},
		/**
		 * Init,
		 * prende le statistiche generali
		 */
		init () {
			// init objects
			const initObj = {
				title: '',
				type: '',
				terminated: false,
				loading: false,
				status: '',
				count: 0,
				page: 0,
				errors: 0,
				size: 0,
				orphans: {
					items: [],
					count: 0,
					size: 0
				}
			}

			this.data = {};
			/*initObj.title = "Video da titolatrice"
			initObj.type = "videos"
			this.data[mediaType.VIDEO_PROCESSOR] = JSON.parse(JSON.stringify(initObj));*/

			initObj.title = "Video"
			initObj.type = "videos"
			this.data[mediaType.VIDEO_UPLOADER] = JSON.parse(JSON.stringify(initObj));

			initObj.title = "Immagini"
			initObj.type = "images"
			this.data[mediaType.IMAGE_UPLOADER] = JSON.parse(JSON.stringify(initObj));

			initObj.title = "File"
			initObj.type = "files"
			this.data[mediaType.FILE_UPLOADER] = JSON.parse(JSON.stringify(initObj));

			let mediaServerData = [];
			//mediaServerData.push(this.getGeneralStats(mediaType.VIDEO_PROCESSOR));
			mediaServerData.push(this.getGeneralStats(mediaType.VIDEO_UPLOADER));
			mediaServerData.push(this.getGeneralStats(mediaType.IMAGE_UPLOADER));
			mediaServerData.push(this.getGeneralStats(mediaType.FILE_UPLOADER));
			this.disks.loading = true;

			Promise.all(mediaServerData)
				.then((res) => {
					this.disks.video.total = 0;
					this.disks.video.free = 0;
					this.disks.video.data = 0;
					this.disks.image.total = 0;
					this.disks.image.free = 0;
					this.disks.image.data = 0;

					res.forEach(r => {
						switch (r.type) {
							case 'media-video-uploader':
							case 'media-video-processor':
								this.disks.video.total = r.total
								this.disks.video.data = this.disks.video.data + r.data
								this.disks.video.free = r.free
								break;

							case 'media-image-uploader':
							case 'media-file-uploader':
								this.disks.image.total = r.total
								this.disks.image.data = this.disks.image.data + r.data
								this.disks.image.free = r.free
								break;
						}
					})
					this.disks.video.data = this.disks.video.total - this.disks.video.free;
					this.disks.image.data = this.disks.image.total - this.disks.image.free;
					this.loading = false;
				})
				.finally(() => {
					this.disks.loading = false;
				})
		},
		/**
		 *
		 */
		getAllMedia () {
			return new Promise((resolve) => {
				this.loading = true
				this.$api.getUrlComplete(`https://admin.telenuovo.it/wp-json/mwd/v2/stats/media2`)
					.then((stats) => {
						this.mediaData = {
							loading: false,
							count: stats.data.length,
							data: stats.data
						};
						resolve()
					})
					.catch((e) => {
						resolve()
					})
			})
		},
		/**
		 *
		 * @param page
		 * @param type
		 */
		getMediaServer (page, key, callback) {
			this.data[key].loading = true;

			const endPointUrl = `https://mediaserver.telenuovo.it/production/${key}/${this.data[key].type}?page=${page}`;
			this.$api.getUrlComplete(endPointUrl)
				.then((items) => {
					items.forEach((i) => {
						if (i.errors) {
							this.data[key].errors = this.data[key].errors + 1;
						} else {
							this.data[key].count = this.data[key].count + 1;
							const df = this.mediaData.data.filter(df => {
								return df.media == i.id
							})
							if (df.length <= 0) {
								if (i.size > 0) {
									i.deleting = false;
									i.deleted = false;
									switch (key) {
										case mediaType.VIDEO_PROCESSOR:
											i.deleteLink = `https://mediaserver.telenuovo.it/production/${key}/${this.data[key].type}/${i.id}`;
											break;
										case mediaType.VIDEO_UPLOADER:
											i.deleteLink = `https://mediaserver.telenuovo.it/production/${key}/${this.data[key].type}/${i.videoId}`;
											break;
										case mediaType.IMAGE_UPLOADER:
											i.deleteLink = `https://mediaserver.telenuovo.it/production/${key}/${this.data[key].type}/${i.imageId}`;
											break;
										case mediaType.FILE_UPLOADER:
											i.deleteLink = `https://mediaserver.telenuovo.it/production/${key}/${this.data[key].type}/${i.fileId}`;
											break;
									}
									i.checked = false;



									if (i && i.output && i.output.length && i.output.slice(-4, i.output.length) != "webp") {
										if(i.output.includes('/images/2022/') || i.output.includes('/images/2021/')) {
											i.checked = true;
										}
										this.data[key].orphans.count = this.data[key].orphans.count + 1;
										this.data[key].orphans.items.push(i);
										this.data[key].orphans.size = this.data[key].orphans.size + i.size;
									}
								}
							}
							this.data[key].status = `Analizzati ${page * 20} media.`
							this.$forceUpdate();
						}
					});
					if (items.length > 0 && page <= 50) {
						this.getMediaServer(page + 1, key, callback)
					} else {
						this.data[key].loading = false;
						this.data[key].terminated = true;
						if (callback) {
							callback()
							this.$forceUpdate();
						}
					}

				});
		},
		/**
		 *
		 * @param itemsToDelete
		 */
		deleteProcess (count, itemsToDelete) {
			const item = itemsToDelete[0];
			item.deleting = true;
			setTimeout(() => {
				this.$api.deleteUrlComplete(item.deleteLink)
					.then((res) => {
						this.$forceUpdate();
					})
					.catch((err) => {
						console.log(err);
						this.$forceUpdate();
					})
					.finally(() => {
						count = count - 1;
						item.deleted = true;
						this.$forceUpdate();

						itemsToDelete.shift();
						if (itemsToDelete.length) {
							this.deleteProcess(count, itemsToDelete);
						}
						this.$forceUpdate();

					})
			}, 500)
		},
		/**
		 *
		 */
		deleteItems (d) {
			const itemsToDelete = d.items.filter(i => {
				return i.checked && !i.deleted
			});
			this.deleteProcess(d.count, itemsToDelete);
		}
	},
	mounted () {
		this.getAllMedia().then(() => {
			console.log(`Media Scaricati: ${this.mediaData.count}`)
			//this.getMediaServer(0, mediaType.VIDEO_PROCESSOR, () => {
				this.getMediaServer(0, mediaType.VIDEO_UPLOADER, () => {
					this.getMediaServer(0, mediaType.IMAGE_UPLOADER, () => {
						this.getMediaServer(0, mediaType.FILE_UPLOADER, () => {
							this.$forceUpdate();
						})
					})
				})
			//})
		})
		setTimeout(() => {
			this.init();
		}, 900);
	}
}
</script>

<style lang="scss">
.summary {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;

	&__item {
		width: 50%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;


		&__image {
			width: 50%;
		}

		&__data {
			width: 50%;
		}
	}
}

.delete_button {
	background: red;
	border: none;
	margin: 5px 0;
	font-size: 13px;
	display: inline-block;
}

.video_report {
	margin-bottom: 10px;

	.analisi {
		&_loading {
			color: orange;
		}

		&_terminata {
			color: green;
		}
	}

	h2 {
		padding: 10px;
		font-weight: 400;
	}

	h3 {
		padding: 10px;
		font-weight: 400;
	}

	p {
		padding: 10px;
	}


}

.report_table {
	width: 90%;
	margin: 0 10px;

	thead {
		text-align: left;

		tr {
			th {
				background: #0b1b34;
				color: white;
				line-height: 40px;
				padding: 0 0 0 10px;
			}
		}
	}
}
</style>
