<template>
	<div id="play_detail" class="main-content page">
		<LoadingSpinner v-if="loading" :small="true"></LoadingSpinner>
		<div class="page--head" v-if="!loading">
			<div class="head--title">
				<PageTitle :back="{name: 'playlist.page'}" :title="title" :title_translation="false"></PageTitle>
				<span class="buttons">
					<Btn v-on:btn-click="showLogoutModal = true" v-if="$route.params.id != 'new'" text-size=""
						 button-type="red" text="Elimina"></Btn>
				</span>
			</div>
		</div>
		<div class="page--content" v-if="!loading && item">
			<PlayForm @change="isChange = $event" @save="onSave()" :base="item"
					  :categories="categories"></PlayForm>
		</div>
		<confirm-modal :is-delete-confirm="true" v-if="showLogoutModal" @close="deleteItem(item)"
					   :title="`Eliminazione`" :description="`Eliminare questo elemento?`"></confirm-modal>
	</div>
</template>

<script>
import PageTitle from "../../components/common/PageTitle";
import LoadingSpinner from "../../components/common/Loading";
// import Btn from "../../components/common/forms/Btn";
import ConfirmModal from "../../components/common/modals/ConfirmModal";
import PlayForm from "@components/play/PlayForm";
import Btn from "@components/common/forms/Btn";

export default {
	name: 'PlayDetail',
	components: {Btn, PlayForm, ConfirmModal, LoadingSpinner, PageTitle},
	data () {
		return {
			loading: false,
			title: '',
			item: null,
			isChange: false,
			categories: [],
			showLogoutModal: false
		}
	},
	methods: {
		init () {
			this.title = `Aggiungi un nuovo elemento`;
			this.loading = true;
			this.service.play_categorie.list(1)
				.then(categories => {
					if (categories && categories.length) {
						categories.map(c => {
							this.categories.push({
								id: c.id,
								name: this.service.helpers.decode(c.title)
							});
						})
						this.categories = this.categories.sort((a, b) => {
							if (a.name > b.name) return 1;
							if (a.name <= b.name) return -1;
							return 0;
						})
						this.service.play.single(this.$route.params.id)
							.then(item => {
								this.item = null;
								let i = {...item};
								// sistemo le date
								i.date = i.date ? this.$moment(i.date * 1000).tz("Europe/Rome").format() : this.$moment().tz("Europe/Rome").format();

								i.data_scadenza = 4133939772;
								// gli ACF
								i.others = {
									description: i.description || '',
									telenuovo_id: i.telenuovo_id || '',
									titolatrice_id: i.titolatrice_id || '',
									video_url: i.video_url || '',
									thumb_url: i.thumb_url || '',
									xml: i.xml || '',
									media_id: i.media_id || '',
									data: i.data ? this.$moment(i.data * 1000).tz("Europe/Rome").format() : this.$moment().tz("Europe/Rome").format(),
									data_scadenza: this.$moment(new Date("2100-12-31")).tz("Europe/Rome").format(),
									categoria: +i.categoria || null,
								}
								if (this.$route.params.id != 'new') {
									this.title = item.title;
								}
								this.item = i
							})
							.finally(() => {
								this.loading = false;
							})
					}
				});

		},
		onSave () {
			this.flashMessage.show({
				status: 'success',
				title: 'Salvato',
				message: "Salvataggio eseguito con successo"
			});
			this.$router.push({name: 'playlist.page'});
		},
		deleteItem (del) {
			if (del && del.id) {
				this.service.play.delete(del.id)
					.then(() => {
						this.flashMessage.show({
							status: 'success',
							title: 'Eliminazione',
							message: 'Eliminazione completata con successo'
						});
						this.$router.push({name: 'playlist.page'});
					})
					.catch((err) => {
						this.$router.push({name: 'playlist.page'});
					})
					.finally(() => {
						this.loading = false;
						this.showLogoutModal = false;
					})
			}
		}
	},
	mounted () {
		this.init()
	},
	beforeRouteLeave (to, from, next) {
		if (this.isChange == false) {
			next()
		} else {
			const answer = window.confirm('Sono stati trovate delle modifiche. Uscendo le modifiche andranno perse. Si vuole uscire?')
			if (answer) {
				next()
			} else {
				next(false)
			}
		}
	}
}
</script>123

<style lang="scss">
@import '@/assets/scss/pages/_play';
</style>
