<template>
	<div id="main" class="main-layout">
		<Head></Head>
		<Menu />
		<router-view/>
		<FlashMessage :position="'right top'"></FlashMessage>
	</div>
</template>

<script>
import Menu from './common/Menu.vue';
import Head from "./common/Head";

export default {
	name: 'MainLayout',
	components: {
		Head,
		Menu
	},
	methods: {
		init() {
			let keyLastUpdated = localStorage.getItem('key_updated');
			let version = "{{ VUE_APP_API_VERSION }}";
			if(keyLastUpdated == null || keyLastUpdated != version) {
				localStorage.setItem('key_updated', version);
				this.$forceUpdate();
			}
		}
	},
	mounted() {
		this.init();

		let navbtn = this.$el.querySelector('.nav-btn');
		let menu = this.$el.querySelector('.main--menu');

		navbtn.addEventListener('click', () => {
			if(navbtn.classList.contains('active')){
				navbtn.classList.remove('active')
				menu.classList.remove('active')
			} else {
				navbtn.classList.add('active')
				menu.classList.add('active')
			}
		})
	},
}
</script>

<style lang="scss">
// .main-layout {
// 	display: block;
// 	background: $white_smoke;
// 	width: 100%;
// 	min-height: 100vh;
//
// 	.main--menu {
// 		position: fixed;
// 		top: var(--menu-head);
// 		right: -100%;
// 		display: block;
// 		background: $white;
// 		@include size(100%, calc(100% - var(--menu-link)));
// 		box-shadow: 0 0 var(--shadow-spread) rgba($shadow, 1);
// 		z-index: 2;
// 		transition: 350ms linear;
// 		&.show {
// 			right: 0%;
// 		}
// 	}
//
// 	.main-content {
// 		display: block;
// 		width: 100vw;
// 		position: relative;
// 		z-index: 0;
// 		padding: var(--gutter) var(--minigutter);
// 		margin-top: calc(var(--menu-link) * 1.2);
// 		overflow: hidden;
// 		.head {
// 			display: flex;
// 			flex-direction: row;
// 			.buttons {
// 				display: flex;
// 				button {
// 					@extend %flexCenterCenter;
// 				}
// 				margin: 15px 0;
// 			}
// 		}
//
// 		.first-row {
// 			display: flex;
// 		}
//
// 		.grid-like {
// 			display: flex;
// 			flex-wrap: wrap;
//
// 			.panel {
// 				&:nth-of-type(2) {
// 					width: calc(50% - calc(var(--gutter) / 2));
// 					margin-top: calc(var(--gutter) / 2);
// 					margin-right: calc(var(--gutter) / 2);
// 				}
//
// 				&:nth-of-type(3) {
// 					width: calc(50% - calc(var(--gutter) / 2));
// 					margin-top: calc(var(--gutter) / 2);
// 					margin-left: calc(var(--gutter) / 2);
// 				}
//
//
// 			}
// 		}
//
// 		h1 {
// 			color: $black;
// 			font-size: 20px;
// 			margin-bottom: 0;
// 		}
//
// 		h2 {
// 			font-size: 16px;
// 			margin-bottom: 20px;
// 		}
//
// 		h4 {
// 			color: black;
// 			width: 100%;
// 			font-size: 14px;
// 		}
//
// 		p {
// 			font-size: 14px;
// 			line-height: 18px;
// 		}
//
// 		.panel_number {
// 			margin-top: 15px;
//
// 			&__number {
// 				font-size: 30px;
// 			}
//
// 			&__number_big {
// 				font-size: 45px;
// 			}
//
// 			&__number_small {
// 				font-size: 40px;
// 			}
//
// 			&__with_margin_bottom {
// 				margin-top: 0px !important;
// 				margin-bottom: 20px;
// 			}
// 		}
// 	}
// 	@media (hover: hover) {
// 		.main--menu {
// 			left: 0;
// 			right: auto;
// 			width: var(--menu);
// 		}
// 		.main-content {
// 			width: calc(100% - var(--menu));
// 			margin: calc(var(--menu-link) * 1.2) 0 0 var(--menu);
// 			padding: var(--gutter);
// 		}
// 	}
// }

</style>
