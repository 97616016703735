<template>
	<div class="page-main-title">
		<div class="page-main-title--box">
			<h1 v-if="title && title_translation && !Array.isArray(title)">
				{{ title }}
			</h1>
			<h1 v-if="title && !title_translation && !Array.isArray(title)">
				{{ title }}
			</h1>
			<!-- <h1 v-if="title  && Array.isArray(title)" class="breadcrumb">
				<span v-for="(t, i) in title" v-bind:key="i" class="breadcrumb__item">
					<span v-if="t.link" class="pointer link_sub" @click="$router.push(t.link)">
						<span v-if="t.title_translation">
							{{$t(t.title)}}
						</span>
						<span v-if="!t.title_translation">
							{{t.title}}
						</span>
					</span>
					<span v-else>
						<span v-if="t.title_translation">
							{{$t(t.title)}}
						</span>
						<span v-if="!t.title_translation">
							{{t.title}}
						</span>
					</span>
					<span class="icon">
						<Icon icon="next" v-if="i < title.length - 1"></Icon>
					</span>

				</span>
			</h1> -->
			<h2 v-if="subtitle && back == null">
				{{ subtitle }}
			</h2>
			<h2 class="back" v-if="back" @click="goBack()">
				Torna indietro
			</h2>
		</div>
	</div>
</template>
<script>
// import Icon from "./forms/Icon";

export default {
	name: 'PageTitle',
	components: {
		// Icon
	},
	props: {
		title: {
			required: false,
			default: null
		},
		title_translation: {
			type: Boolean,
			required: false,
			default: true
		},
		back: {
			type: Object,
			required: false,
			default: null
		},
		subtitle: {
			type: String,
			required: false,
			default: null
		}
	},
	methods: {
		goBack() {
			this.$router.push(this.back);
		}
	}
}
</script>
<style lang="scss">
.page-main-title {
	display: flex;
	@extend %flexEndBetween;
	width: 100%;
	&--box {
		display: block;
		width: 100%;
		h1,
		h2 {
			display: block;
			width: 100%;
			line-height: 1em;
			margin: 0;
		}

		h1 {
			font-weight: $extra;
			font-size: 24px !important;
			color: $primary !important;
		}

		h2 {
			margin-bottom: 0;
			font-size: 14px !important;
			color: $grey !important;
		}

		.back {
			color: $black !important;
			margin-top: var(--minigutter);
			cursor: pointer;
			&:before {
				content: '\2190';
				display: inline-block;
				margin-right: calc(var(--gutter) / 5);
				color: $black;
			}
		}
	}
}

.breadcrumb {
	display: flex !important;

	&__item {
		display: flex;

		.icon {
			background-color: yellow;
			height: 0;
		}

		.link_sub {
			font-weight: 100;
		}

		.input-icon {
			padding-bottom: 4px;
		}

		svg {
			fill: $primary;
		}
	}
}
</style>
