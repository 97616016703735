<template>
	<div class="fieldset" :class="{'hidden-inpt': isHidden}">
		<label v-if="label">
			{{ label }}
		</label>
		<div class="input">
			<input :disabled="disabled" :type="`${(isPassword) ? 'password' : ((isNumeric) ? 'number' : ((isHidden) ? 'hidden' : 'text'))}`" :name="name" :placeholder="placeholder"
				   autocomplete="off" :maxlength="maxlength"
				   @input="handleInput($event.target.value)" :value="value"/>
		</div>
		<span class="battute" v-if="battute">
				Caratteri: {{calcuateBattute()}}
			</span>
		<div v-if="o.$invalid" class="error error--form">
			<span v-for="k in Object.keys(o.$params)" v-bind:key="k">
				<span v-if="submitted && !o[k]">
                   {{ errors[`${name}_${k}`] }}<br>
                </span>
			</span>
		</div>
	</div>
</template>

<script>
    export default {
        name: 'Inpt',
		data() {
			return {
				errors: {
					name_required: "Nome richiesto",
					title_required: "Il titolo è obbligatorio",
					undefined_required: "Il campo è obbligatorio",
					email_required: "Indirizzo email richiesto",
					email_email: "E-mail non valida",
					data_password_required: "Password richiesta",
					password_requiredIf: "Password richiesta",
					password_required: "Inserire una password valida",
					data_password_requiredIf: "Password richiesta",
					password_minLength: "La password deve essere superiore a 8 caratteri",
					data_password_minLength: "La password deve essere superiore a 8 caratteri",
					repeat_required: "Ripetere la password",
					repeat_sameAsPassword: "Le due password non coincidono",
					uuid_required: "UUID Richiesto",
					major_required: "Major Richiesto",
					minor_required: "Minor Richiesto",
					major_numeric: "Major deve essere un numero intero",
					minor_numeric: "Minor deve essere un numero intero",
					point_isPoint: "Deve essere un punto valido. X,Y",
					file_not_valid: "Il file inserito non è valido",
					coordinates_required: "Le coordinate sono richieste",
					coordinates_isPoint: "Deve essere una coordinata valida.(X,Y)",
					image_required: "Immagine richiesta",
					image_not_valid: "Non è stata inserita un'immagine valida",
					intersect_detected: "Non è possibile inserire un punto in questa posizione, i lati del poligono noon si possono intersecare"
				},
			}
		},
        methods: {
            init() {
            },
            handleInput(content) {
                this.$emit('input', content)
            },
			calcuateBattute() {
            	// return (this.value && this.value.trim() !== "") ? (this.value.trim().split(" ").length  *2) : 0
            	return (this.value && this.value.trim() !== "") ? this.value.length : 0
			}
        },
        mounted() {
            this.init();
        },
        props: {
            value: {
                required: true
            },
			maxlength: {
            	default: '999'
			},
            o: {
                type: Object,
                default: null,
                required: true
            },
            submitted: {
                type: Boolean,
                default: false,
                required: true
            },
            isPassword: {
                type: Boolean,
                default: false,
                required: false
            },
            isNumeric: {
                type: Boolean,
                default: false,
                required: false
            },
            isHidden: {
                type: Boolean,
                default: false,
                required: false
            },
            disabled: {
                type: Boolean,
                default: false,
                required: false
            },
            icon: {
                type: String,
                default: '',
                required: false
            },
			label: {
                type: String,
                default: null,
                required: false
            },
            name: {
                type: String,
                default: '',
                required: true
            },
            placeholder: {
                type: String,
                default: '',
                required: false
            },
			battute: {
            	type: Boolean,
				default: false,
				required: false
			}
        }
    }
</script>

<style lang="scss">

	.modal-body {
		color: $primary;
		.fieldset {
			.input {
				input {
					padding:7px !important;
				}
			}
		}
	}
	.fieldset {
		display: flex;
		flex-direction: column;
		@extend %flexCenterStart;
		width: 100%;

		label {
			display: block;
			width: 100%;
			text-align: left;
			margin: 10px 0 -5px 0; // Kekko non mi uccidere per sta cosa
			font-size:  14px;
			font-weight: 400;
		}


		// icona
		/*.icon {
			display: flex;
			flex-direction: row;
			width: 100%;
			.input-icon {
				display: flex;
				@extend %flexCenterCenter;
				@include size(var(--icon), var(--icon));
				svg {
					@include size(70%, 50%);
					fill: $isabelline;
				}
			}

			.input {
				flex: 1;
				// @include size(), var(--input));
				height: var(--input);
				border: 0;
				width: 100%;
				font-size: 0.7em;
				padding: 4px;
			}

		}

		.no-icon {
			width: 100%;

			.input {
				border: 0;
				width: 100%;
				font-size: 0.7em;
				padding: 9px;
			}
		}*/

		.input {
			width: 100%;
			border: 1px solid $isabelline;
			border-radius: calc(var(--radius) / 2.5);
			margin: 8px 0;
			input {
				border: 0;
				width: 100%;
				font-size: 0.9em !important;
				padding: 10px !important;
			}
		}

		.battute {
			color: $primary;
			font-size: 11px;
			width: 100%;
			text-align: right;
			margin-top: -9px;
		}

		.error {
			display: block;
			width: 100%;
			font-size: 0.6em;
			color: $red;
			line-height: 0.8em;
			text-transform: uppercase;
			border-radius: 0;

			&--form {
				line-height: calc(var(--gutter) / 2);
				text-align: right;
			}
		}

		&.hidden-inpt {
			display: none;
		}

	}
</style>
