import axios from 'axios';
const API_URL = (process.env.VUE_APP_API_URL !== undefined && process.env.VUE_APP_API_URL !== "") ? process.env.VUE_APP_API_URL : '{{ VUE_APP_API_URL }}';

export default {
    install: function (Vue) {
        // global $http object
        Object.defineProperty(Vue.prototype, '$http', {value: axios});
        // axios settings
        axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';
        //axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        //axios.defaults.withCredentials = false;
        /**
         * Creazione della risposta
         * @param response
         * @param resolve
         * @param reject
         * @param token
         * @param redirectUnauthorized
         */
        const buildResponse = (response, resolve, reject) => {
            let data = response.data || "";
            let status = response.status || null;

            if (response && response.status && status == 200) {
                resolve(data);
            }
            if (response && data.messages && status != 200) {
                reject(data);
            }
            reject({
                messages: ['api-general-error']
            });
        };
        /**
         *
         */
        const getBaseHeaders = () => {
            let token = localStorage.getItem('token');
            return {
                validateStatus: () => {
                    return true;
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        }
        //
        // definizione delle api
        //
        const api = {
            /**
             * @param email
             * @param password
             * @returns {Promise<any>}
             */
            login(email, password) {
                return new Promise((resolve, reject) => {
                    localStorage.removeItem('token');
                    axios.post(`${API_URL}/auth/login`, {
                        username: email.trim(),
                        password: password.trim()
                    }, {
                        validateStatus: () => {
                            return true;
                        }
                    })
                        .catch(() => {
                            reject({
                                messages: ['login-error']
                            });
                        })
                        .then((result) => {
                            buildResponse(result, (r) => {
                                localStorage.setItem('token', r.token);
                                resolve(r);
                            }, reject);
                        });
                });
            },
            /**
             * @param resource
             * @param data
             * @returns {Promise<unknown>}
             */
            get(resource, data) {
                let config = getBaseHeaders();
                if (data) {
                    config.data = data;
                }
                return new Promise((resolve, reject) => {
                    axios.get(`${API_URL}${resource}`, config)
                        .catch((error) => {
                            reject({
                                success: false,
                                error: error
                            });
                        })
                        .then((result) => {
                            buildResponse(result, resolve, reject);
                        });
                });
            },
            getUrlComplete(url, data) {
                let config = getBaseHeaders();
                if (data) {
                    config.data = data;
                }
                return new Promise((resolve, reject) => {
                    axios.get(`${url}`, config)
                        .catch((error) => {
                            reject({
                                success: false,
                                error: error
                            });
                        })
                        .then((result) => {
                            buildResponse(result, resolve, reject);
                        });
                });
            },
            deleteUrlComplete(url, data) {
                let config = getBaseHeaders();
                if (data) {
                    config.data = data;
                }
                return new Promise((resolve, reject) => {
                    axios.delete(`${url}`, config)
                        .catch((error) => {
                            reject({
                                success: false,
                                error: error
                            });
                        })
                        .then((result) => {
                            buildResponse(result, resolve, reject);
                        });
                });
            },
            /**
             * Nuovo inserimento di una risorsa
             * @param resource
             * @param data
             * @returns {Promise<unknown>}
             */
            post(resource, data) {
                let config = getBaseHeaders();
                return new Promise((resolve, reject) => {
                    axios.post(`${API_URL}${resource}`, data, config)
                        .catch((error) => {
                            reject({
                                success: false,
                                error: error
                            });
                        }).then((result) => {
                        buildResponse(result, resolve, reject);
                    });
                });
            },
            /**
             * Modifica di una risorsa
             * @param resource
             * @param data
             * @returns {Promise<unknown>}
             */
            put(resource, data) {
                let config = getBaseHeaders();
                return new Promise((resolve, reject) => {
                    axios.put(`${API_URL}${resource}`, data, config)
                        .catch((error) => {
                            reject({
                                success: false,
                                error: error
                            });
                        }).then((result) => {
                        buildResponse(result, resolve, reject);
                    });
                });
            },
            /**
             * Eliminazione di una risorsa
             * @param resource
             * @param data
             * @returns {Promise<unknown>}
             */
            delete(resource, data) {
                let config = getBaseHeaders();
                if (data) {
                    config.data = data;
                }
                return new Promise((resolve, reject) => {
                    axios.delete(`${API_URL}${resource}`, config)
                        .catch((error) => {
                            reject({
                                success: false,
                                error: error
                            });
                        })
                        .then((result) => {
                            buildResponse(result, resolve, reject);
                        });
                });
            },
            upload(resource, data, mime, title, caption, category) {
                let config = getBaseHeaders();
                config.headers["Content-Type"] = "application/octet-stream";
                config.headers["Wp-Media-Content-Type"] = mime || "";
                config.headers["Wp-Media-Content-Title"] = title;
                config.headers["Wp-Media-Content-Caption"] = caption;
                config.headers["Wp-Media-Content-Category"] = category;
                config.headers["Wp-Media-Content-NoWatermark"] = 1;
                return new Promise((resolve, reject) => {
                    axios.post(`${API_URL}${resource}`, data, config)
                        .catch((error) => {
                            reject({
                                success: false,
                                error: error
                            });
                        }).then((result) => {
                        buildResponse(result, resolve, reject);
                    });
                });
            },
            changeVideoThumbnail(videoID, data, mime) {
                let config = getBaseHeaders();
                config.headers["Content-Type"] = "application/octet-stream";
                let url = `${API_URL}/media/changeThumb?videoId=${videoID}`;
                return new Promise((resolve, reject) => {
                    axios.post(url, data, config)
                        .catch((error) => {
                            reject({
                                success: false,
                                error: error
                            });
                        }).then((result) => {
                        buildResponse(result, resolve, reject);
                    });
                });
            },
            changeVideoTitolatriceThumbnail(videoID, data, mime) {
                let config = getBaseHeaders();
                config.headers["Content-Type"] = "application/octet-stream";
                let url = `${API_URL}/media/changeThumbTitolatrice?videoId=${videoID}`;
                return new Promise((resolve, reject) => {
                    axios.post(url, data, config)
                        .catch((error) => {
                            reject({
                                success: false,
                                error: error
                            });
                        }).then((result) => {
                        buildResponse(result, resolve, reject);
                    });
                });
            }
        };
        Vue.prototype.$api = api;
    }
}
