<template>
	<a id="logo" href="/">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118 23">
			<linearGradient id="gradient1" gradientUnits="userSpaceOnUse" x1="58.8281" y1="23" x2="58.8281" y2="0">
				<stop offset="0" stop-color="#fcea99"/>
				<stop offset="1" stop-color="#947d30"/>
			</linearGradient>
			<path d="M109.9 10.9l5.5 3.2-5.5 3.2v-6.4zm4.1 1.4l-2-1.2.8-.8-5-.7-2.2-4.5-2.2 4.5-5 .7 3.6 3.5-.8 5 4.4-2.3 4.4 2.3-.1-.6L112 17l1 6-7.4-3.9-7.4 3.9 1.4-8.3-6-5.9 8.3-1.2 3.7-7.6 3.7 7.6 8.3 1.2-3.6 3.5zm-28.3 9.8c-3 0-5.5-2.6-5.5-5.4 0-2.8 2.5-5.4 5.5-5.4s5.4 2.6 5.4 5.4c0 2.8-2.5 5.4-5.4 5.4zm-.1-8.1c-1.4 0-2.5 1.1-2.5 2.7s1.1 2.7 2.5 2.7 2.5-1.1 2.5-2.7-1-2.7-2.5-2.7zm-12.1 7.9l-5-10.4h3.3l2.6 6 2.6-6h3.3l-5 10.4h-1.8zm-10.5.2c-3 0-5.5-2.6-5.5-5.4 0-2.8 2.5-5.4 5.5-5.4s5.4 2.6 5.4 5.4c0 2.8-2.5 5.4-5.4 5.4zm0-8.1c-1.4 0-2.5 1.1-2.5 2.7s1.1 2.7 2.5 2.7 2.5-1.1 2.5-2.7S64.4 14 63 14zm-6.4 4.1c-.2 2.4-2.1 3.9-4.9 3.9-3 0-5-1.7-5-4.5v-6.1h2.9v6.1c.1 1.2.8 1.8 2 1.8s1.9-.6 2-1.8v-6.1h2.9V17c.1.5.1.8.1 1.1zm-14-1v-1.4c-.1-1.2-.8-1.8-2-1.8s-1.9.6-2 1.8v6.1h-2.9v-5.6-1c.2-2.4 2.1-3.9 4.9-3.9 3 0 5 1.7 5 4.5v6.1h-2.9v-4.8zm-13 2.5c.9.1 1.9-.3 2.4-1l2.4.5c-.6 1.4-2 2.9-4.8 2.9-2.7 0-5.3-2.1-5.3-5.4 0-3.2 2.5-5.4 5.2-5.4 3.4 0 5.2 2.4 5.2 6.2h-7.3c.1 1.3 1.1 2.1 2.2 2.2zm2.1-4.1c-.1-.9-1-1.9-2.2-1.9-1.2 0-2 .9-2.2 1.9h4.4zm-12.9 2.2V6.6h2.9l.1 10.1c0 .6.1 1 .2 1.4.2.9 1.3 1 1.7 1.1.3.4-.3 2.4-.6 2.8-3-.2-4.2-1.7-4.3-4.3zM13 19.6c.9.1 1.9-.3 2.4-1l2.4.5c-.6 1.4-2 2.9-4.8 2.9-2.7 0-5.3-2.1-5.3-5.4 0-3.2 2.5-5.4 5.2-5.4 3.4 0 5.2 2.4 5.2 6.2h-7.3c0 1.3 1 2.1 2.2 2.2zm2.1-4.1c-.1-.9-1-1.9-2.2-1.9-1.2 0-2 .9-2.2 1.9h4.4zM4.4 17.7c0 .8.3 1.2.7 1.4.4.2.9 0 1.1 0l.5 2.6c-.4 0-1.3.3-2.3.2-2.2-.3-2.8-1.9-2.9-3.6v-4H0v-2.5h1.4V8.5h2.9v3.2h2v2.5h-2v2.9c.1.2.1.4.1.6z" fill-rule="evenodd" clip-rule="evenodd" fill="url(#gradient1)"/>
		</svg>
	</a>
</template>

<script>
export default {
	name: 'Logo'
}
</script>

<style lang="scss">
#logo {
	@include size(190px, 40px);
	svg {
		display: block;
		@include size(100%,100%);
	}

}
</style>
