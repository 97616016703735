<template>
	<button :type="`${(isSubmitButton) ? 'submit' : 'button'}`" :disabled="loading" :class="`${textSize} ${buttonType} ${(isFull) ? 'full' : ''}`"
			class="btn" @click="$emit('btn-click')">
		<span v-if="loading">Attendere...</span>
		<span v-if="!loading">{{ text }}</span>
	</button>
</template>

<script>
    export default {
        name: 'Btn',
        props: {
            text: {
                type: String,
                required: true
            },
            isSubmitButton: {
                type: Boolean,
                required: false,
                default: false
            },
            buttonType: {
                type: String,
                default: 'default',
                required: false
            },
            textSize: {
                type: String,
                default: 'small',
                required: false
            },
            isFull: {
                type: Boolean,
                default: false,
                required: false
            },
            loading: {
                required: false,
                default: false,
                type: Boolean
            }
        }
    }
</script>

<style lang="scss">
	.btn {
		border-radius: calc(var(--radius) / 2.5);
		border: 0;
		color: $white;
		font-weight: 600;
		padding: 12px 12px;
		margin: 10px 0;
		min-width: 70px;
		opacity: 1;
		transition: 0.3s;

		// colors
		&.default {
			background: $light_grey;
			color: $primary;
		}

		&.primary {
			background: $primary;
		}

		&.red {
			background: $red;
		}

		.full {
			@include size(100%, var(--input));
			margin: calc(var(--gutter) / 2) 0;
		}

		// text size
		&.biggest {
			font-size: 1.2em;
			text-transform: uppercase;
			text-align: center;
		}

		&.big {
			font-size: 1em;
			text-transform: uppercase;
			text-align: center;
		}

		&.small {
			font-size: 0.8em;
			text-transform: uppercase;
			text-align: center;
		}

		&.smaller {
			font-size: 0.6em;
			text-transform: uppercase;
			text-align: center;
		}

		&.secondary {
			background: transparent;
			color: $dark_grey;
		}

		&_green {
			background: $green;
			color: $white;
		}

		&_red {
			background: $red;
			color: $white;
		}

		&_blue {
			background: $blue;
			color: $white;
		}
	}
	.btn:hover {
		opacity: .8;
	}
</style>
