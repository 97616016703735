<template>
    <header class="main--head">
        <router-link to="/" class="logo">
            <span class="logo--mobile">
                <LogoMobile />
            </span>
            <span class="logo--desktop">
                <Logo />
            </span>
        </router-link>
        <div class="head--utilities">
            <ProfileUtility />
            <span class="nav-btn">
                <span class="bar"></span>
                <span class="bar"></span>
                <span class="bar"></span>
            </span>
        </div>
    </header>
</template>

<script>
import ProfileUtility from '../../components/common/ProfileUtility.vue';
import Logo from '../../components/Logo.vue';
import LogoMobile from '../../components/LogoMobile.vue';

export default {
    name: 'Head',
    components: {
        ProfileUtility,
        Logo,
        LogoMobile
    },
}
</script>

<style lang="scss">
@import '@/assets/scss/layout/_head';
</style>
