<template>
	<div id="play_categorie_detail" class="main-content page">
		<LoadingSpinner v-if="loading" :small="true"></LoadingSpinner>
		<div class="page--head" v-if="!loading">
			<div class="head--title">
				<PageTitle :back="{name: 'playcategorielist.page'}" :title="title" :title_translation="false"></PageTitle>
				<span class="buttons">
					<Btn v-on:btn-click="showLogoutModal = true" v-if="$route.params.id != 'new'" text-size="" button-type="red" text="Elimina"></Btn>
				</span>
			</div>
		</div>
		<div class="page--content">
			<PlayCategorieForm @change="isChange = $event" @save="onSave()" v-if="!loading && item" :base="item"></PlayCategorieForm>
		</div>
		<confirm-modal :is-delete-confirm="true" v-if="showLogoutModal" @close="deleteItem($event)" :title="`Eliminazione`" :description="`Eliminare questo elemento?`"></confirm-modal>
	</div>
</template>

<script>
import PageTitle from "../../components/common/PageTitle";
import LoadingSpinner from "../../components/common/Loading";
import Btn from "../../components/common/forms/Btn";
import ConfirmModal from "../../components/common/modals/ConfirmModal";
import PlayCategorieForm from "@components/play/PlayCategorieForm";

export default {
	name: 'PlayCategorieDetail',
	components: {PlayCategorieForm, ConfirmModal, Btn, LoadingSpinner, PageTitle},
	data () {
		return {
			loading: false,
			title: '',
			item: null,
			isChange: false,
			showLogoutModal: false
		}
	},
	methods: {
		init () {
			this.title = `Aggiungi una nuovas categoria`;
			this.loading = true;
			this.service.play_categorie.single(this.$route.params.id)
				.then(item => {
					this.item = null;
					let i = {...item};
					// sistemo le date
					i.date = i.date ? this.$moment(i.date * 1000).tz("Europe/Rome").format() : this.$moment().tz("Europe/Rome").format();
					// gli ACF
					i.others = {
						ids: i.ids || '',
						ordine: i.ordine || 0,
						visibile: i.visibile || false,
						giorni_scadenza: i.giorni_scadenza || 0,
						visibile_cms_light: i.visibile_cms_light || false,
					}
					if (this.$route.params.id != 'new') {
						this.title = item.title;
					}
					this.item = i
				})
				.finally(() => {
					this.loading = false;
				})
		},
		onSave () {
			this.flashMessage.show({
				status: 'success',
				title: 'Salvato',
				message: "Salvataggio eseguito con successo"
			});
			this.$router.push({name: 'playcategorielist.page'});
		},
		deleteItem (del) {
			if (del) {
				this.loading = true;
				this.service.play_categorie.delete(this.$route.params.id)
					.then(() => {
						this.flashMessage.show({
							status: 'success',
							title: 'Eliminazione',
							message: 'Eliminazione completata con successo'
						});
						this.$router.push({name: 'playcategorielist.page'});
					})
					.finally(() => {
						this.loading = false;
					})
			}
			this.showLogoutModal = false;
		}
	},
	mounted () {
		this.init()
	},
	beforeRouteLeave (to, from, next) {
		if (this.isChange == false) {
			next()
		} else {
			const answer = window.confirm('Sono stati trovate delle modifiche. Uscendo le modifiche andranno perse. Si vuole uscire?')
			if (answer) {
				next()
			} else {
				next(false)
			}
		}
	}
}
</script>

<style lang="scss">
@import '@/assets/scss/pages/_playcategorie';
</style>
