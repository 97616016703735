<template>
	<div id="speciali_detail" class="main-content page">
		<LoadingSpinner v-if="loading" :small="true"></LoadingSpinner>
		<div class="page--head" v-if="!loading">
			<div class="head--title">
				<PageTitle :back="{name: 'speciali.page'}" :title="title" :title_translation="false"></PageTitle>
				<span class="buttons">
					<Btn v-on:btn-click="showLogoutModal = true" v-if="$route.params.id != 'new'" text-size=""
						 button-type="red" text="Elimina"></Btn>
				</span>
			</div>
		</div>
		<div class="page--content">
			<SpecialiForm @change="isChange = $event" @save="onSave()" :categories="categories" v-if="!loading && item"
					  :base="item" ></SpecialiForm>
		</div>
		<confirm-modal :is-delete-confirm="true" v-if="showLogoutModal" @close="deleteItem($event)"
					   :title="`Eliminazione`" :description="`Eliminare questo articolo?`"></confirm-modal>
	</div>
</template>

<script>
import PageTitle from "../../components/common/PageTitle";
import LoadingSpinner from "../../components/common/Loading";
import Btn from "../../components/common/forms/Btn";
import ConfirmModal from "../../components/common/modals/ConfirmModal";
import SpecialiForm from "../../components/speciali/SpecialiForm";

export default {
	name: 'SpecialiDetail',
	components: {SpecialiForm, ConfirmModal, Btn, LoadingSpinner, PageTitle},
	data () {
		return {
			showLogoutModal: false,
			loading: false,
			title: '',
			categories: [],
			item: null,
			isChange: false
		}
	},
	methods: {
		init () {
			this.title = `Aggiungi un nuovo contenuto speciale`;
			this.loading = true;

			this.service.speciali_categorie.all()
				.then(categories => {
					this.categories = [];
					categories.map(c => {
						if(c.attivo) {
							this.categories.push(c);
						}
					})

					this.service.speciali.single(this.$route.params.id)
						.then(item => {
							this.item = null;
							let i = {...item};

							// sistemo i contenuti
							i.content = i.content.replace(/<!-- wp:paragraph -->/g, '');
							i.content = i.content.replace(/<!-- \/wp:paragraph -->/g, '');

							let uContent = i.content.split("<!-- wp:mwd/sondaggio-block");

							i.content = uContent[0];

							if (uContent && uContent.length && uContent[1]) {
								// esiste un sondaggio
								let d = uContent[1].match(/([a-zA-Z-_])+/gi);
								if (d && d.length > 1 && d[0] == "sondaggio_slug") {
									i.sondaggio = d[1].trim()
								}
							}

							// sistemo le date
							i.date = i.date ? this.$moment(i.date * 1000).tz("Europe/Rome").format() : this.$moment().tz("Europe/Rome").format();

							// gestisco i media
							i.featured_media = i.media_id;
							i.featured_media_url = i.media;

							// gli ACF
							i.others = {
								in_evidenza: i.in_evidenza || false,
								notizia_bloccata: i.notizia_bloccata || 0,
								autore: (i.autore && i.autore !== 'La Redazione') ? i.autore : '',
								news_video: i.media_video || false,
								video_in_copertina: (i.media_video && i.media) ? i.media : '',
								didascalia: (i.didascalia) ? i.didascalia : '',
								main_category: i.main_category || null,
								speciali_categorie_id: i.speciali_categorie_id || null,
								galleria: i.galleria || [],
								social: i.social || {
									facebook: {
										publish: false,
										publish_date: null,
										title: '',
										description: '',
										media: '',
										categoria: '',
										tg: []
									}
								}
							}

							this.item = i;

							if (this.$route.params.id != 'new') {
								this.title = item.title;
							}
						})
						.finally(() => {
							this.loading = false;
						})
				})
		},
		onSave () {
			this.flashMessage.show({
				status: 'success',
				title: 'Salvato',
				message: "L'articolo è stato salvato con successo."
			});
			this.$router.push({name: 'speciali.page'});
		},
		deleteItem (del) {
			if (del) {
				this.loading = true;
				this.service.speciali.delete(this.$route.params.id)
					.then(() => {
						this.flashMessage.show({
							status: 'success',
							title: 'Eliminazione',
							message: 'Articolo eliminato con successo'
						});
						this.$router.push({name: 'speciali.page'});
					})
					.finally(() => {
						this.loading = false;
					})
			}
			this.showLogoutModal = false;
		}
	},
	mounted () {
		this.init()
	},
	beforeRouteLeave (to, from, next) {
		if (this.isChange == false) {
			next()
		} else {
			const answer = window.confirm('Sono stati trovate delle modifiche. Uscendo le modifiche andranno perse. Si vuole uscire?')
			if (answer) {
				next()
			} else {
				next(false)
			}
		}
	}
}
</script>

<style lang="scss">
@import '@/assets/scss/pages/_specialidetail';
</style>
