<template>
	<div id="post_detail" class="main-content page">
		<LoadingSpinner v-if="loading" :small="true"></LoadingSpinner>
		<div class="page--head" v-if="!loading">
			<div class="head--title">
				<PageTitle :back="{name: 'postlist.page'}" :title="title" :title_translation="false"></PageTitle>
				<span class="buttons">
					<Btn v-on:btn-click="showLogoutModal = true" v-if="$route.params.id != 'new'" text-size=""
						 button-type="red" text="Elimina"></Btn>
				</span>
			</div>
		</div>
		<div class="page--content">
			<PostForm @change="isChange = $event" @save="onSave()" :categories="categories" v-if="!loading && item"
					  :base="item" :sondaggi-list="sondaggi"></PostForm>
		</div>
		<confirm-modal :is-delete-confirm="true" v-if="showLogoutModal" @close="deleteItem($event)"
					   :title="`Eliminazione`" :description="`Eliminare questo articolo?`"></confirm-modal>
	</div>
</template>

<script>
import PageTitle from "../../components/common/PageTitle";
import LoadingSpinner from "../../components/common/Loading";
import Btn from "../../components/common/forms/Btn";
import ConfirmModal from "../../components/common/modals/ConfirmModal";
import PostForm from "../../components/post/PostForm";

export default {
	name: 'PostDetail',
	components: {PostForm, ConfirmModal, Btn, LoadingSpinner, PageTitle},
	data () {
		return {
			showLogoutModal: false,
			loading: false,
			title: '',
			categories: [],
			sondaggi: [],
			item: null,
			isChange: false
		}
	},
	methods: {
		init () {
			this.title = `Aggiungi un nuovo articolo`;
			this.loading = true;

			this.service.sondaggio.list()
				.then(sondaggi => {
					this.sondaggi = sondaggi;

					this.service.categories.list()
						.then(categories => {
							this.categories = categories;
							this.service.post.single(this.$route.params.id)
								.then(item => {
									this.item = null;
									let i = {...item};

									// sistemo i contenuti
									i.content = i.content.replace(/<!-- wp:paragraph -->/g, '');
									i.content = i.content.replace(/<!-- \/wp:paragraph -->/g, '');

									let uContent = i.content.split("<!-- wp:mwd/sondaggio-block");

									i.content = uContent[0];

									if (uContent && uContent.length && uContent[1]) {
										// esiste un sondaggio
										let d = uContent[1].match(/([a-zA-Z-_])+/gi);
										if (d && d.length > 1 && d[0] == "sondaggio_slug") {
											i.sondaggio = d[1].trim()
										}
									}

									// sistemo le date
									i.date = i.date ? this.$moment(i.date * 1000).tz("Europe/Rome").format() : this.$moment().tz("Europe/Rome").format();

									// gestisco i media
									i.featured_media = i.media_id;
									i.featured_media_url = i.media;

									// gli ACF
									i.others = {
										breaking_news: i.breaking_news || false,
										breaking_news_locale: i.breaking_news_locale || false,
										straordinaria: i.straordinaria || false,
										straordinaria_verona: i.straordinaria_verona || false,
										straordinaria_padova: i.straordinaria_padova || false,
										straordinaria_gialloblu: i.straordinaria_gialloblu || false,
										straordinaria_biancoscudato: i.straordinaria_biancoscudato || false,
										alta: i.alta || false,
										alta_verona: i.alta_verona || false,
										alta_padova: i.alta_padova || false,
										alta_gialloblu: i.alta_gialloblu || false,
										alta_biancoscudato: i.alta_biancoscudato || false,
										autore: (i.autore && i.autore !== 'La Redazione') ? i.autore : '',
										news_video: i.media_video || false,
										video_in_copertina: (i.media_video && i.media) ? i.media : '',
										breaking_news_correlate: [],
										correlati: [],
										correlati_titolo: [],
										didascalia: (i.didascalia) ? i.didascalia : '',
										galleria: [],
										allegati: [],
										social_status: i.social_status || '',
										social: i.social || {
											facebook: {
												publish: false,
												publish_date: null,
												title: '',
												description: '',
												media: '',
												categoria: '',
												tg: []
											}
										},
										main_category: i.main_category || null
									}

									if (i.breaking_news_correlate && i.breaking_news_correlate.length) {
										i.breaking_news_correlate.map(bn => {
											i.others.breaking_news_correlate.push(bn.id);
										})
									}

									if (i.articoli_correlati_titolo && i.articoli_correlati_titolo.length) {
										i.articoli_correlati_titolo.map(ac => {
											i.others.correlati_titolo.push({
												title: ac.title,
												post: ac.post.id
											});
										})
									}

									if (i.galleria && i.galleria.length) {
										i.galleria.map(ac => {
											i.others.galleria.push({
												media: ac.media,
												didascalia: ac.didascalia
											});
										})
									}

									if (i.allegati && i.allegati.length) {
										i.allegati.map(ac => {
											i.others.allegati.push({
												media: ac.media,
												didascalia: ac.didascalia
											});
										})
									}

									if (i.correlati && i.correlati.length) {
										i.correlati.map(bn => {
											i.others.correlati.push(bn.id);
										})
									}

									// categorie
									if (i.categories && i.categories !== null) {
										let cat_tmp = [];
										Object.keys(i.categories).map(k => {
											if (i.categories[k].child && i.categories[k].child.length) {
												i.categories[k].child.map(icc => {
													cat_tmp.push(icc.id);
												})
											}
										});
										i.categories = cat_tmp;
									} else {
										i.categories = [];
									}

									// tags
									if (i.tags) {
										let tag_tmp = [];
										i.tags.map(t => {
											tag_tmp.push(t.name);
										})
										i.tags = tag_tmp;
									} else {
										i.tags = [];
									}

									this.item = i;

									if (this.$route.params.id != 'new') {
										this.title = item.title;
									}
								})
								.finally(() => {
									this.loading = false;
								})
						})
				});
		},
		onSave () {
			this.flashMessage.show({
				status: 'success',
				title: 'Salvato',
				message: "L'articolo è stato salvato con successo."
			});
			this.$router.push({name: 'postlist.page'});
		},
		deleteItem (del) {
			if (del) {
				this.loading = true;
				this.service.post.delete(this.$route.params.id)
					.then(() => {
						this.flashMessage.show({
							status: 'success',
							title: 'Eliminazione',
							message: 'Articolo eliminato con successo'
						});
						this.$router.push({name: 'postlist.page'});
					})
					.finally(() => {
						this.loading = false;
					})
			}
			this.showLogoutModal = false;
		}
	},
	mounted () {
		this.init()
	},
	beforeRouteLeave (to, from, next) {
		if (this.isChange == false) {
			next()
		} else {
			const answer = window.confirm('Sono stati trovate delle modifiche. Uscendo le modifiche andranno perse. Si vuole uscire?')
			if (answer) {
				next()
			} else {
				next(false)
			}
		}
	}
}
</script>

<style lang="scss">
@import '@/assets/scss/pages/_postdetail';
</style>
