let params = window.location.href.split('?')
params = (params.length > 1) ? params[1] : null
if(params) {
	params.split("&").forEach(p => {
		const param = p.split("=");
		if(param && param.length && param[0] && param[0] == "auth") {
			localStorage.setItem("token", param[1])
		}
	})
}

import "regenerator-runtime";

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import i18n from './plugins/i18n.plugin'
import {Vuelidate} from 'vuelidate'
import ApiPlugin from './plugins/api.plugin';
import SocialapiPlugin from "./plugins/socialapi.plugin";
import ServicePlugin from './plugins/service.plugin';
import moment from './plugins/moment.plugin';
import FlashMessage from '@smartweb/vue-flash-message';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
import gsap from './plugins/gsap.plugin';
import VueHtml2Canvas from 'vue-html2canvas';

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(moment);
Vue.use(ApiPlugin);
Vue.use(SocialapiPlugin);
Vue.use(ServicePlugin, router);
Vue.use(FlashMessage, {
	time: 2500
});
Vue.use(CKEditor);
Vue.use(Datetime);
Vue.use(gsap);
Vue.use(VueHtml2Canvas);

Vue.filter('percentage', function (value, decimals) {
	if (!value) {
		value = 0;
	}

	if (!decimals) {
		decimals = 0;
	}

	value = value * 100;
	value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
	value = value + '%';
	return value;
});
Vue.filter('number', function (value) {
	if (!value) {
		value = 0;
	}
	const amt = Number(value)

	var si = [
		{value: 1, symbol: ""},
		{value: 1E3, symbol: "k"},
		{value: 1E6, symbol: "M"},
		{value: 1E9, symbol: "G"},
		{value: 1E12, symbol: "T"},
		{value: 1E15, symbol: "P"},
		{value: 1E18, symbol: "E"}
	];
	var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	var i;
	for (i = si.length - 1; i > 0; i--) {
		if (amt >= si[i].value) {
			break;
		}
	}
	return (amt / si[i].value).toLocaleString(undefined, {maximumFractionDigits: 2}).replace(rx, "$1") + si[i].symbol;
	// return amt && amt.toLocaleString(undefined, {maximumFractionDigits: 2}) || '0'
});
Vue.filter('data', function (value) {
	value = (value < 10000000000) ? value * 1000 : value;
	return moment.moment(+value).format('DD/MM/YY');
});
Vue.filter('datatime', function (value) {
	value = (value < 10000000000) ? value * 1000 : value;
	return moment.moment(+value).format('DD/MM/YY - HH:mm');
});
Vue.filter('currency', function (value) {
	return `${value.toLocaleString()} €`
});
Vue.filter('data_custom', function (value, format) {
	return moment(+value).format(format);
});

new Vue({
	router,
	i18n,
	render: h => h(App)
}).$mount('#app');

