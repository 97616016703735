import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardLayout from '../views/layouts/MainLayout';
import Login from '../views/pages/Login.vue'
import NotFound from '../views/pages/errors/NotFound';
import UsersDetail from "../views/pages/users/UsersDetail";
import PostList from "../views/pages/post/PostList";
import MediaList from "../views/pages/media/MediaList";
import PostDetail from "../views/pages/post/PostDetail";
import SondaggioList from "@/views/pages/sondaggio/SondaggioList";
import SondaggioDetail from "@/views/pages/sondaggio/SondaggioDetail";
import MediaWebStatusList from "@/views/pages/media/MediaWebStatusList";
import PlayCategorieList from "@/views/pages/play_categorie/PlayCategorieList";
import PlayCategorieDetail from "@/views/pages/play_categorie/PlayCategorieDetail";
import PlayList from "@/views/pages/play/PlayList";
import PlayDetail from "@/views/pages/play/PlayDetail";
import SpecialiCategorieList from "@/views/pages/speciali/SpecialiCategorieList";
import SpecialiCategorieDetail from "@/views/pages/speciali/SpecialiCategorieDetail";
import SpecialiList from "../views/pages/speciali/SpecialiList";
import SpecialiDetail from "../views/pages/speciali/SpecialiDetail";
import VideoReport from "@/views/pages/stats/VideoReport";
import SocialList from "../views/pages/socials/SocialList.vue";

Vue.use(VueRouter);
const guardAuthService = async (to, from, next) => {
	const navBar = document.querySelector('.head--utilities .nav-btn');
	const navMenu = document.querySelector('.main--menu');
	if(navBar && navMenu) {
		navBar.classList.remove('active');
		navMenu.classList.remove('active');
	}
	try {
		let token = localStorage.getItem('token')
		if (token && token !== '') {
			next()
		} else {
			next({
				name: 'login.page',
				query: {redirectFrom: to.fullPath}
			})
		}
	} catch (e) {
		next({
			name: 'login.page',
			query: {redirectFrom: to.fullPath}
		})
	}
};
const routes = [
	{
		path: '/notfound',
		name: 'notfound.page',
		component: NotFound
	}, {
		path: '/login',
		name: 'login.page',
		component: Login,
	}, {
		path: '/',
		component: DashboardLayout,
		beforeEnter (to, from, next) {
			guardAuthService(to, from, next);
		},
		children: [
			{
				path: '',
				name: 'postlist.page',
				component: PostList,
				beforeEnter (to, from, next) {
					guardAuthService(to, from, next);
				},
			}, {
				path: 'post/:id',
				name: 'postdetail.page',
				component: PostDetail,
				beforeEnter (to, from, next) {
					guardAuthService(to, from, next);
				},
			}, {
				path: 'mediastatus',
				name: 'mediastatus.page',
				component: MediaWebStatusList,
				beforeEnter (to, from, next) {
					guardAuthService(to, from, next);
				},
			}, {
				path: 'media',
				name: 'medialist.page',
				component: MediaList,
				beforeEnter (to, from, next) {
					guardAuthService(to, from, next);
				},
			}, {
				path: 'media/:id',
				name: 'media.page',
				component: UsersDetail,
				beforeEnter (to, from, next) {
					guardAuthService(to, from, next);
				},
			}, {
				path: 'sondaggio',
				name: 'sondaggiolist.page',
				component: SondaggioList,
				beforeEnter (to, from, next) {
					guardAuthService(to, from, next);
				},
			}, {
				path: 'sondaggio/:id',
				name: 'sondaggiodetail.page',
				component: SondaggioDetail,
				beforeEnter (to, from, next) {
					guardAuthService(to, from, next);
				},
			}, {
				path: 'playcategorie',
				name: 'playcategorielist.page',
				component: PlayCategorieList,
				beforeEnter (to, from, next) {
					guardAuthService(to, from, next);
				},
			}, {
				path: 'playcategorie/:id',
				name: 'playcategoriedetail.page',
				component: PlayCategorieDetail,
				beforeEnter (to, from, next) {
					guardAuthService(to, from, next);
				},
			}, {
				path: 'play',
				name: 'playlist.page',
				component: PlayList,
				beforeEnter (to, from, next) {
					guardAuthService(to, from, next);
				},
			}, {
				path: 'play/:id',
				name: 'playdetail.page',
				component: PlayDetail,
				beforeEnter (to, from, next) {
					guardAuthService(to, from, next);
				},
			}, {
				path: 'specialicategorie',
				name: 'specialicategorie.page',
				component: SpecialiCategorieList,
				beforeEnter (to, from, next) {
					guardAuthService(to, from, next);
				},
			}, {
				path: 'specialicategorie/:id',
				name: 'specialicategoriedetail.page',
				component: SpecialiCategorieDetail,
				beforeEnter (to, from, next) {
					guardAuthService(to, from, next);
				},
			},{
				path: 'speciali',
				name: 'speciali.page',
				component: SpecialiList,
				beforeEnter (to, from, next) {
					guardAuthService(to, from, next);
				},
			}, {
				path: 'speciali/:id',
				name: 'specialidetail.page',
				component: SpecialiDetail,
				beforeEnter (to, from, next) {
					guardAuthService(to, from, next);
				},
			}, {
				path: 'reportVideo',
				name: 'reportVideo.page',
				component: VideoReport,
				beforeEnter (to, from, next) {
					guardAuthService(to, from, next);
				},
			}, {
				path: 'socials',
				name: 'socials.page',
				component: SocialList,
				beforeEnter (to, from, next) {
					guardAuthService(to, from, next);
				},
			},
			{
				path: '*',
				redirect: '/notfound',
				beforeEnter (to, from, next) {
					guardAuthService(to, from, next);
				},
			}
		]
	},
	{
		path: '*',
		redirect: '/'
	}
];
const router = new VueRouter({
	// mode: 'history',
	mode: 'hash',
	routes,
	scrollBehavior () {
		return {x: 0, y: 0};
	}
});
export default router
