<template>
	<form class="form" autocomplete="off" @submit.prevent="submitForm" @change="hasChanged">
		<!-- Definizione delle tabs -->
		<div class="tabs--stage" v-if="tabs">
			<div class="tabs--drag">
				<div class="tabs">
					<div :class="`tab ${(t.key == tabSelected) ? 'selected' : ''}`" v-for="t in tabs" v-bind:key="t.key"
						 @click="tabSelected = t.key; actionTab();">
						{{ t.caption }}
					</div>
				</div>
			</div>
		</div>

		<!-- Form -->
		<div class="panel panel_post">
			<!-- Errors -->
			<div v-if="error" class="error error--general-big">
				{{ error }}
			</div>

			<!-- Tab -->
			<div class="tab_item" v-if="tabSelected == 'generale'">
				<Inpt label="Titolo" name="title" v-model="$v.form.title.$model" :o="$v.form.title" :maxlength="96"
					  :battute="true" :placeholder="`Titolo`" :submitted="submitted"></Inpt>

				<Inpt label="Ids" name="ids" v-model="$v.form.others.ids.$model" :o="$v.form.others.ids"
					  :battute="false" :placeholder="`Ids`" :submitted="submitted"></Inpt>

				<Inpt label="Ordine" :is-numeric="true" name="ordine" v-model="$v.form.others.ordine.$model" :o="$v.form.others.ordine"
					  :battute="false" :placeholder="`Ordine`" :submitted="submitted"></Inpt>

				<div class="fieldset attributes">
					<label>
						<input type="checkbox" v-model="form.others.visibile"/>
						<span style="margin-left: 8px;">Visibile</span>
						<small style="margin-left: 8px;">Categoria visibile in TNV Play</small>
					</label>
				</div>

				<div class="fieldset attributes">
					<label>
						<input type="checkbox" v-model="form.others.visibile_cms_light"/>
						<span style="margin-left: 8px;">Visibile in CMS Light</span>
						<small style="margin-left: 8px;">Categoria che può contennere dei video di articoli</small>
					</label>
				</div>

				<Inpt label="Giorni Scadenza" :is-numeric="true" name="giorni_scadenza" v-model="$v.form.others.giorni_scadenza.$model" :o="$v.form.others.giorni_scadenza"
					  :battute="false" :placeholder="`Giorni Scadenza`" :submitted="submitted"></Inpt>
			</div>

			<div class="form-buttons">
				<div></div>
				<!--<span class="next_button" v-if="!loading && tabSelected != 'attribute' && form.id == null"
					  @click="tabSelected = nextTab()">
					Avanti
				</span>-->
				<Btn v-if="tabSelected == 'generale' || form.id != null" :loading="loading" :text="'Salva'"
					 text-size="big" button-type="primary" :is-full="false" :is-submit-button="true"></Btn>
			</div>
		</div>
	</form>
</template>
<script>
import {required} from 'vuelidate/lib/validators';
import Btn from "../common/forms/Btn";
import Inpt from "../common/forms/Inpt";

export default {
	name: 'PlayCategorieForm',
	components: {
		Inpt,
		Btn
	},
	props: {
		// l'oggetto di base
		base: {
			default: null,
			required: false
		}
	},
	data () {
		return {
			// indica se il form è stato spedito
			submitted: false,
			// errori della form
			error: '',
			loading: false,
			// gestione dei tab
			tabSelected: 'generale',
			tabs: [
				{key: 'generale', caption: ""},
			],
			// oggetto form
			form: {
				id: null,
				title: '',
				date: null,
				others: {
					ids: '',
					ordine: 0,
					visibile: false,
					giorni_scadenza: 0,
					visibile_cms_light: false
				}
			}
		};
	},
	/**
	 * Validatori Form
	 */
	validations: {
		form: {
			id: {},
			title: {
				required
			},
			date: {},
			others: {
				ids: {},
				ordine: {},
				visibile: {},
				giorni_scadenza: {},
				visibile_cms_light: {}
			}
		}
	},
	methods: {
		/**
		 * Inizializzo la pagina
		 */
		init () {
			if (this.base !== null) {
				Object.keys(this.form).map(k => {
					if (this.base[k]) {
						this.form[k] = this.base[k];
					}
				})
			}
		},
		/**
		 * Quando la form cambia, viene chiamato questo metodo che emette al componente
		 * padre la modifica
		 */
		hasChanged () {
			this.$emit('change', !this.submitted);
		},
		/**
		 * Quando viene selezionata una tab, controllo la tab ed eseguo
		 * delle operazioni
		 */
		actionTab () {
		},
		/**
		 * Tab successiva,
		 * @returns {string} il codice della tab successiva
		 */
		nextTab () {
			let next = "";
			this.tabs.map((t, index) => {
				if (this.tabSelected == t.key) {
					next = this.tabs[index + 1].key;
				}
				this.actionTab();
			})
			return next;
		},
		/**
		 * Chisura della form
		 */
		close () {
			this.$emit('close');
			this.submitted = false;
		},
		/**
		 * Salvataggio della form
		 */
		submitForm () {
			this.$v.form.$touch();
			this.submitted = true; // setto come inviato
			this.hasChanged(); // blocco l'emissione del form cambiato (non serve)
			this.error = '';
			this.loading = true;
			if (!this.$v.form.$invalid) {
				let f = {...this.form};
				this.service.play_categorie.save(f)
					.then(() => {
						this.$emit('save');
						this.submitted = false;
					})
					.catch((err) => {
						if (err && err.messages && err.messages[0]) {
							this.errorMessage = this.errors[err.messages[0]];
							this.error = this.errorMessage;
							this.submitted = false;
						}
					})
					.finally(() => {
						this.loading = false;
					})
			} else {
				this.loading = false;
				this.flashMessage.show({
					status: 'error',
					title: 'Dati non corretti',
					message: 'Controllare i dati prima per continuare'
				});
			}
		}
	},
	mounted () {
		this.init();
	}
}
</script>
<style lang="scss">
.form-buttons {
	width: 100%;
	display: flex;
	justify-content: space-between !important;
	margin-top: 30px;
}

.fieldset {
	.error {
		margin-bottom: 15px;
	}
}

.panel_post {
	width: 100%;
}
</style>
