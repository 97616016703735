<template>
	<transition name="confirm.modal">
		<div class="modal-mask">
			<div class="modal-wrapper" v-if="form">
				<div class="modal-container modal-container-big">
					<div class="modal-header">
						<slot name="header">
							<h3>{{ form.title }}</h3>
						</slot>
					</div>
					<div class="modal-body">
						<slot name="body">
							<div class="fieldset attributes">
								<label
									:class="`${(form.breaking_news_locale ||
									form.straordinaria ||
									form.straordinaria_verona ||
									form.straordinaria_padova ||
									form.straordinaria_gialloblu ||
									form.straordinaria_biancoscudato ||
									form.alta ||
									form.alta_verona ||
									form.alta_padova ||
									form.alta_gialloblu ||
									form.alta_biancoscudato) ? 'post_attributes_disabled' : ''}`">
									<input type="checkbox" v-model="form.breaking_news"/>
									<span>Breaking news</span>
									<small>Questa news sarà nella schermata iniziale su tutte le home page</small>
								</label>

								<label
									:class="`${(form.breaking_news || form.straordinaria ||
									form.straordinaria_verona ||
									form.straordinaria_padova ||
									form.straordinaria_gialloblu ||
									form.straordinaria_biancoscudato ||
									form.alta ||
									form.alta_verona ||
									form.alta_padova ||
									form.alta_gialloblu ||
									form.alta_biancoscudato) ? 'post_attributes_disabled' : ''}`">
									<input type="checkbox" v-model="form.breaking_news_locale"/>
									<span>Breaking News Locale</span>
									<small>Questa news sarà nella schermata iniziale sulle home delle categorie di
										appartenenza</small>
								</label>

								<!--<label
									:class="`${(form.breaking_news_locale || form.breaking_news || form.alta) ? 'post_attributes_disabled' : ''}`">
									<input type="checkbox" v-model="form.straordinaria"/>
									<span>News Straordinaria</span>
									<small>News con layout staordinario</small>
								</label>-->


								<div style="width: 100%; margin-bottom: 30px"
									 :class="`${(form.breaking_news_locale || form.breaking_news || form.alta) ? 'post_attributes_disabled' : ''}`">
									<h3>Straordinaria</h3>
									<label>
										<input type="checkbox" v-model="form.straordinaria_verona"/>
										<span>Straordinaria TgVerona</span>
									</label>
									<label>
										<input type="checkbox" v-model="form.straordinaria_padova"/>
										<span>Straordinaria TgPadova</span>
									</label>
									<label>
										<input type="checkbox" v-model="form.straordinaria_gialloblu"/>
										<span>Straordinaria TgGialloblu</span>
									</label>
									<label>
										<input type="checkbox" v-model="form.straordinaria_biancoscudato"/>
										<span>Straordinaria TgBiancoscudato</span>
									</label>
								</div>

								<!--<label
									:class="`${(form.breaking_news_locale || form.straordinaria || form.breaking_news) ? 'post_attributes_disabled' : ''}`">
									<input type="checkbox" v-model="form.alta"/>
									<span>News Alta</span>
									<small>News alta, in caso di straordinario</small>
								</label>-->

								<div style="width: 100%"
									 :class="`${(form.breaking_news_locale || form.breaking_news || form.straordinaria) ? 'post_attributes_disabled' : ''}`">
									<h3>Alta</h3>
									<label>
										<input type="checkbox" v-model="form.alta_verona"/>
										<span>Alta TgVerona</span>
									</label>
									<label>
										<input type="checkbox" v-model="form.alta_padova"/>
										<span>Alta TgPadova</span>
									</label>
									<label>
										<input type="checkbox" v-model="form.alta_gialloblu"/>
										<span>Alta TgGialloblu</span>
									</label>
									<label>
										<input type="checkbox" v-model="form.alta_biancoscudato"/>
										<span>Alta TgBiancoscudato</span>
									</label>
								</div>
							</div>
						</slot>
					</div>
					<div class="modal-footer">
						<slot name="footer">
							<Btn :loading="loading" @btn-click="saveModal()" button-type="primary" text-size="big"
								 text="Salva"></Btn>
							<Btn :loading="loading" @btn-click="closeModal()" button-type="default" text-size="big"
								 text="Chiudi"></Btn>
						</slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
import Btn from "../forms/Btn";

export default {
	name: 'QuickPostUpdateModal',
	components: {Btn},
	props: {
		post_id: {
			required: false
		}
	},
	data () {
		return {
			form: null,
			loading: true
		}
	},
	watch: {
		'this.post_id': {
			immediate: true,
			deep: true,
			handler () {
				if (this.post_id) {
					this.loading = true;
					this.service.post.single(this.post_id)
						.then((post) => {
							this.form = post;
						})
						.finally(() => {
							this.loading = false;
						})
				}
			}
		}
	},
	methods: {
		closeModal () {
			this.$emit('close')
		},
		saveModal () {
			this.loading = true;
			let f = {...this.form};
			f.tags = (f.tags && f.tags.length) ? f.tags : [];
			f.date = f.date ? this.$moment(f.date * 1000).tz("Europe/Rome").format() : this.$moment().tz("Europe/Rome").format();
			f.featured_media = f.media_id;
			f.featured_media_url = f.media;
			f.others = {
				breaking_news: f.breaking_news || false,
				breaking_news_locale: f.breaking_news_locale || false,
				straordinaria: f.straordinaria || false,
				straordinaria_verona: f.straordinaria_verona || false,
				straordinaria_padova: f.straordinaria_padova || false,
				straordinaria_gialloblu: f.straordinaria_gialloblu || false,
				straordinaria_biancoscudato: f.straordinaria_biancoscudato || false,
				alta: f.alta || false,
				alta_verona: f.alta_verona || false,
				alta_padova: f.alta_padova || false,
				alta_gialloblu: f.alta_gialloblu || false,
				alta_biancoscudato: f.alta_biancoscudato || false,
				autore: (f.autore && f.autore !== 'La Redazione') ? f.autore : '',
				news_video: f.media_video || false,
				video_in_copertina: (f.media_video && f.media) ? f.media : '',
				breaking_news_correlate: [],
				correlati: [],
				correlati_titolo: [],
				didascalia: (f.didascalia) ? f.didascalia : '',
				galleria: [],
				allegati: [],
				social_status: f.social_status || '',
				social: {
					facebook: {
						publish: false,
						publish_date: null,
						title: '',
						description: '',
						media: '',
						tg: []
					}
				},
				main_category: f.main_category || null
			}
			if (f.breaking_news_correlate && f.breaking_news_correlate.length) {
				f.breaking_news_correlate.map(bn => {
					f.others.breaking_news_correlate.push(bn.id);
				})
			}
			if (f.articoli_correlati_titolo && f.articoli_correlati_titolo.length) {
				f.articoli_correlati_titolo.map(ac => {
					f.others.correlati_titolo.push({
						title: ac.title,
						post: ac.post.id
					});
				})
			}
			if (f.galleria && f.galleria.length) {
				f.galleria.map(ac => {
					f.others.galleria.push({
						media: ac.media,
						didascalia: ac.didascalia
					});
				})
			}
			if (f.allegati && f.allegati.length) {
				f.allegati.map(ac => {
					f.others.allegati.push({
						media: ac.media,
						didascalia: ac.didascalia
					});
				})
			}
			if (f.correlati && f.correlati.length) {
				f.correlati.map(bn => {
					f.others.correlati.push(bn.id);
				})
			}
			if (f.categories && f.categories !== null) {
				let cat_tmp = [];
				Object.keys(f.categories).map(k => {
					if (f.categories[k].child && f.categories[k].child.length) {
						f.categories[k].child.map(icc => {
							cat_tmp.push(icc.id);
						})
					}
				});
				f.categories = cat_tmp;
			} else {
				f.categories = [];
			}
			if (f.tags) {
				let tag_tmp = [];
				f.tags.map(t => {
					tag_tmp.push(t.name);
				})
				f.tags = tag_tmp;
			} else {
				f.tags = [];
			}
			this.service.post.tags(f.tags)
				.then((tags) => {
					if (tags && tags.success) {
						f.tags = tags.tags.ids;
					} else {
						f.tags = [];
					}
					this.service.post.save(f)
						.then(() => {
							this.flashMessage.show({
								status: 'success',
								title: 'Successo',
								message: 'Salvato con successo'
							});
							this.$emit('save', this.form)
						})
						.catch((err) => {
							this.flashMessage.show({
								status: 'error',
								title: 'Errore',
								message: 'Errore durante il salvataggio.'
							});
							this.$emit('close')
						})
						.finally(() => {
							this.loading = false
						})
				});


		}
	}
}
</script>
<style lang="scss">
.post_attributes_disabled {
	color: grey;
	pointer-events: none;
	display: none !important;
}
</style>
