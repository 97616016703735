<template>
	<div class="login-form panel form">
		<div class="logo">
			<img src="@images/logo.png">
		</div>
		<form class="form" autocomplete="off" @submit.prevent="submitForm">
			<div v-if="error" class="error error--general">
				{{ error }}
			</div>
			<Inpt name="email" :placeholder="'Nome Utente'" v-model="$v.login.email.$model" :o="$v.login.email" :submitted="submitted"></Inpt>
			<Inpt name="password" :placeholder="'Password'" :is-password="true" v-model="$v.login.password.$model" :o="$v.login.password" :submitted="submitted"></Inpt>
			<Btn :loading="loading" :text="'Accedi'" text-size="big" button-type="primary" :is-full="true" :is-submit-button="true"></Btn>
		</form>
	</div>
</template>
<script>
    import {required} from 'vuelidate/lib/validators'
    import Btn from "../common/forms/Btn";
    import Inpt from "../common/forms/Inpt";

    export default {
        name: 'LoginForm',
        components: {Inpt, Btn},
        data() {
            return {
                submitted: false,
                error: '',
                loading: false,
                login: {
                    email: '',
                    password: ''
                },
				forms: {
					name_required: "Nome richiesto",
					title_required: "Il titolo è obbligatorio",
					undefined_required: "Il campo è obbligatorio",
					email_required: "Indirizzo email richiesto",
					email_email: "E-mail non valida",
					data_password_required: "Password richiesta",
					password_requiredIf: "Password richiesta",
					password_required: "Inserire una password valida",
					data_password_requiredIf: "Password richiesta",
					password_minLength: "La password deve essere superiore a 8 caratteri",
					data_password_minLength: "La password deve essere superiore a 8 caratteri",
					repeat_required: "Ripetere la password",
					repeat_sameAsPassword: "Le due password non coincidono",
					uuid_required: "UUID Richiesto",
					major_required: "Major Richiesto",
					minor_required: "Minor Richiesto",
					major_numeric: "Major deve essere un numero intero",
					minor_numeric: "Minor deve essere un numero intero",
					point_isPoint: "Deve essere un punto valido. X,Y",
					file_not_valid: "Il file inserito non è valido",
					coordinates_required: "Le coordinate sono richieste",
					coordinates_isPoint: "Deve essere una coordinata valida.(X,Y)",
					image_required: "Immagine richiesta",
					image_not_valid: "Non è stata inserita un'immagine valida",
					intersect_detected: "Non è possibile inserire un punto in questa posizione, i lati del poligono noon si possono intersecare"
			    },
            };
        },
        validations: {
            login: {
                email: {
                    required
                },
                password: {
                    required
                }
            }
        },
        methods: {
            init() {
                localStorage.removeItem('token');
            },
            submitForm() {
                this.$v.login.$touch();
                this.submitted = true;
                this.error = '';
                this.loading = true;
                if (!this.$v.login.$invalid) {
                    this.$api.login(this.login.email, this.login.password)
						.then(() => {
						    this.$router.push("/");
                            this.submitted = false;
						})
                        .catch((err) => {
                            if(err && err.messages && err.messages[0]) {
                                let errorMessage = this.errors[err.messages[0]];
                                this.error = this.errorMessage;
                                this.submitted = false;
                                this.login.password = '';
							}
                        })
						.finally(() => {
                            this.loading = false;
						})
                } else {
                    this.loading = false;
                }
            }
        },
        mounted() {
            this.init();
        }
    }
</script>
<style lang="scss">
	.login-form {
		padding: 10px;

		.logo {
			width: 80%;
			margin: 30px auto;

			img {
				width: 100%;
			}
		}
		.form {
			&-utility {
				display: flex;
				flex-wrap: wrap;
				.checkbox,
				.change-password {
					display: flex;
					align-items: center;
					width: 50%;
				}
				.change-password {
					justify-content: flex-end;
					font-size: 0.8em;
					color: $dark_grey;
					text-align: right;
				}
			}
		}
	}

	@media (min-width: 600px) {
		.login-form {
			width: 70%;
		}
	}

	@media (min-width: 1000px) {
		.login-form {
			width: 100%;
		}
	}

	@media (min-width: 1200px) {
		.login-form {
			max-width: 400px;
			.form {
				&-utility {
					.change-password {
						font-size: 0.7em;
					}
				}
			}
		}
	}
</style>
