<template>
	<form class="form" autocomplete="off" @submit.prevent="submitForm" @change="hasChanged">
		<!-- Definizione delle tabs -->
		<div class="tabs--stage" v-if="tabs">
			<div class="tabs--drag">
				<div class="tabs">
					<div :class="`tab ${(t.key == tabSelected) ? 'selected' : ''}`" v-for="t in tabs" v-bind:key="t.key"
						 @click="tabSelected = t.key; actionTab();">
						{{ t.caption }}
					</div>
				</div>
			</div>
		</div>

		<!-- Form -->
		<div class="panel panel_post">
			<!-- Errors -->
			<div v-if="error" class="error error--general-big">
				{{ error }}
			</div>

			<!-- Tab -->
			<div class="tab_item" v-if="tabSelected == 'generale'">
				<Inpt label="Titolo" name="title" v-model="$v.form.title.$model" :o="$v.form.title" :maxlength="96"
					  :battute="true" :placeholder="`Titolo`" :submitted="submitted"></Inpt>
				<Inpt label="Nota" name="nota" v-model="$v.form.others.nota.$model" :o="$v.form.others.nota"
					  :battute="false" :placeholder="`Nota box speciale (es. a cura di rete Nord...)`" :submitted="submitted"></Inpt>


				<hr>
				<div class="color_container">
					<div class="color_item">
						<h4>Colore titolo testata</h4>
						<Chrome v-model="colore.titolo_testata" @input="onChangeColoreTitoloTestata"></Chrome>
					</div>
					<div class="color_item">
						<h4>Colore titolo</h4>
						<Chrome v-model="colore.titolo" @input="onChangeColoreTitolo"></Chrome>
					</div>
					<div class="color_item">
						<h4>Colore sfondo</h4>
						<Chrome v-model="colore.sfondo" @input="onChangeColoreSfondo"></Chrome>
					</div>
					<div class="color_item">
						<h4>Colore testo</h4>
						<Chrome v-model="colore.testo" @input="onChangeColoreTesto"></Chrome>
					</div>
				</div>
				<hr>

				<div class="image_speciale_container">
					<div class="image_speciale_item">
						<h4>Immagine Desktop</h4>
						<img :src="form.others.immagine_desktop">
					</div>
					<div class="image_speciale_item">
						<h4>Immagine Mobile</h4>
						<img :src="form.others.immagine_mobile">
					</div>
				</div>
				<Btn :loading="loading" :text="'Seleziona media'" text-size="big" button-type="default"
					 :is-full="false" v-on:btn-click="showMediaModal = true" :is-submit-button="false"></Btn>
				<hr>

				<h3 style="font-weight: 500">Posizione degli speciali</h3>
				<table class="table_speciale">
					<thead>
						<tr>
							<th></th>
							<th>Telenuovo</th>
							<th>TgVerona</th>
							<th>TgPadova</th>
							<th>TgGialloblu</th>
							<th>TgBiancoscudato</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="p in Object.keys(form.others.posizione)" v-bind:key="p">
							<th>Posizione {{p}}</th>
							<td v-for="(v,i) in form.others.posizione[p]" v-bind:key="i">
								<input type="checkbox" v-model="form.others.posizione[p][i]" />
							</td>
						</tr>
					</tbody>
				</table>

				<hr>

				<h3 style="font-weight: 500">Gestione ADV (Elenco)</h3>
				<table class="table_speciale">
					<thead>
					</thead>
					<tbody>
					<tr v-for="p in Object.keys(form.others.adv.elenco)" v-bind:key="p">
						<th>{{ p }}</th>
						<td v-for="(v,i) in form.others.adv.elenco[p]" v-bind:key="i">
							<input type="number" v-model="form.others.adv.elenco[p][i]" />
						</td>
					</tr>
					</tbody>
				</table>

				<hr>

				<h3 style="font-weight: 500">Gestione ADV (Dettaglio)</h3>
				<table class="table_speciale" style="width: 25%">
					<thead>
					</thead>
					<tbody>
					<tr v-for="p in Object.keys(form.others.adv.dettaglio)" v-bind:key="p">
						<th>{{ p }}</th>
						<td v-for="(v,i) in form.others.adv.dettaglio[p]" v-bind:key="i">
							<input type="number" v-model="form.others.adv.dettaglio[p][i]" />
						</td>
					</tr>
					</tbody>
				</table>



				<hr>
				<div class="fieldset attributes">
					<label>
						<input type="checkbox" v-model="form.others.attivo"/>
						<span style="margin-left: 8px;">Speciale attivo</span>
						<small style="margin-left: 8px;">Lo speciale può essere selezionato quando si inserisce un contenuto</small>
					</label>
					<label>
						<input type="checkbox" v-model="form.others.visibile"/>
						<span style="margin-left: 8px;">Speciale visibile</span>
						<small style="margin-left: 8px;">Lo speciale è pubblico</small>
					</label>
				</div>
			</div>

			<div class="form-buttons">
				<div></div>
				<Btn v-if="tabSelected == 'generale' || form.id != null" :loading="loading" :text="'Salva'"
					 text-size="big" button-type="primary" :is-full="false" :is-submit-button="true"></Btn>
			</div>

			<SelectMediaModal v-if="showMediaModal" @close="selectMedia($event)"></SelectMediaModal>

		</div>
	</form>
</template>
<script>
import {required} from 'vuelidate/lib/validators';
import Btn from "../common/forms/Btn";
import Inpt from "../common/forms/Inpt";
import { Chrome } from 'vue-color';
import SelectMediaModal from "../common/modals/SelectMediaModal";

export default {
	name: 'SpecialiCategorieForm',
	components: {
		SelectMediaModal,
		Inpt,
		Btn,
		Chrome
	},
	props: {
		// l'oggetto di base
		base: {
			default: null,
			required: false
		}
	},
	data () {
		return {
			// indica se il form è stato spedito
			submitted: false,
			// errori della form
			error: '',
			loading: false,
			// gestione dei tab
			tabSelected: 'generale',
			tabs: [
				{key: 'generale', caption: ""},
			],
			colore: {
				titolo_testata: '',
				titolo: '',
				sfondo: '',
				testo: ''
			},
			showMediaModal: false,
			// oggetto form
			form: {
				id: null,
				title: '',
				date: null,
				others: {
					nota: '',
					colore_titolo_testata: '',
					colore_titolo: '',
					colore_sfondo: '',
					colore_testo: '',
					immagine_desktop: '',
					immagine_mobile: '',
					posizione: '',
					adv: '',
					attivo: true,
					visibile: true
				}
			}
		};
	},
	/**
	 * Validatori Form
	 */
	validations: {
		form: {
			id: {},
			title: {
				required
			},
			date: {},
			others: {
				nota: {},
				colore_titolo_testata: {required},
				colore_titolo: {required},
				colore_sfondo: {required},
				colore_testo: {required},
				immagine_desktop: {required},
				immagine_mobile: {required},
				posizione: {required},
				adv: {required},
				attivo: {},
				visibile: {}
			}
		}
	},
	methods: {
		/**
		 * Inizializzo la pagina
		 */
		init () {
			if (this.base !== null) {
				Object.keys(this.form).map(k => {
					if (this.base[k]) {
						this.form[k] = this.base[k];
					}
				});
				this.colore.titolo_testata = this.form.others.colore_titolo_testata;
				this.colore.titolo = this.form.others.colore_titolo;
				this.colore.sfondo = this.form.others.colore_sfondo;
				this.colore.testo = this.form.others.colore_testo;
			}
		},
		/**
		 * Quando la form cambia, viene chiamato questo metodo che emette al componente
		 * padre la modifica
		 */
		hasChanged () {
			this.$emit('change', !this.submitted);
		},
		/**
		 * Quando viene selezionata una tab, controllo la tab ed eseguo
		 * delle operazioni
		 */
		actionTab () {
		},
		/**
		 * Tab successiva,
		 * @returns {string} il codice della tab successiva
		 */
		nextTab () {
			let next = "";
			this.tabs.map((t, index) => {
				if (this.tabSelected == t.key) {
					next = this.tabs[index + 1].key;
				}
				this.actionTab();
			})
			return next;
		},
		/**
		 * Chisura della form
		 */
		close () {
			this.$emit('close');
			this.submitted = false;
		},
		/**
		 * Salvataggio della form
		 */
		submitForm () {
			this.$v.form.$touch();
			this.submitted = true; // setto come inviato
			this.hasChanged(); // blocco l'emissione del form cambiato (non serve)
			this.error = '';
			this.loading = true;
			if (!this.$v.form.$invalid) {
				let f = {...this.form};
				this.service.speciali_categorie.save(f)
					.then(() => {
						this.$emit('save');
						this.submitted = false;
					})
					.catch((err) => {
						if (err && err.messages && err.messages[0]) {
							this.errorMessage = this.errors[err.messages[0]];
							this.error = this.errorMessage;
							this.submitted = false;
						}
					})
					.finally(() => {
						this.loading = false;
					})
			} else {
				this.loading = false;
				this.flashMessage.show({
					status: 'error',
					title: 'Dati non corretti',
					message: 'Controllare i dati prima per continuare'
				});
			}
		},
		onChangeColoreTitolo (val) {
			this.form.others.colore_titolo = val.hex8 || ''
		},
		onChangeColoreTitoloTestata (val) {
			console.log(val)
			this.form.others.colore_titolo_testata = val.hex8 || ''
		},
		onChangeColoreSfondo (val) {
			this.form.others.colore_sfondo = val.hex8 || ''
		},
		onChangeColoreTesto (val) {
			this.form.others.colore_testo = val.hex8 || ''
		},
		selectMedia (media) {
			this.showMediaModal = false;
			this.form.others.immagine_desktop = null;
			this.form.others.immagine_mobile = null;
			if (media && media.media_type == 'image') {
				this.form.others.immagine_desktop = media.url.replace(`1200x620`, `1190x300`);
				this.form.others.immagine_mobile = media.url.replace(`1200x620`, `960x600`);
			}
		}
	},
	mounted () {
		this.init();
	}
}
</script>
<style lang="scss">
.form-buttons {
	width: 100%;
	display: flex;
	justify-content: space-between !important;
	margin-top: 30px;
}

.fieldset {
	.error {
		margin-bottom: 15px;
	}
}

.panel_post {
	width: 100%;
}

.color_container {
	display: flex;
	justify-content: space-between;
	width: 50%;

	h4 {
		padding: 15px 0;
		font-weight: 400;
	}
	.color_item {

	}
}

.image_speciale_container {
	display: flex;
	justify-content: space-between;
	width: 100%;

	h4 {
		padding: 15px 0;
		font-weight: 400;
	}
	.image_speciale_item {
		margin: 10px
	}
}

.table_speciale {
	margin-top: 10px;
	width: 70%;
	border: 1px solid #7f7f7f;
	color: white;
	background: #7f7f7f;
	thead {
		tr {
			th {
				border: 1px solid #7f7f7f;
				padding: 4px;
			}
		}
	}
	tbody {
		tr {
			th {
				border: 1px solid #7f7f7f;
				padding: 10px;
			}
			td {
				color: #7f7f7f;
				background: white;
				text-align: center;
				border: 1px solid #7f7f7f;
				input[type=checkbox] {
					cursor: pointer;
					-webkit-transform: scale(1.3);
					transform: scale(1.3);
				}
				input[type=number] {
					width: 80%;
				}
			}
		}
	}
}
</style>
