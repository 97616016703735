<template>
	<div class="fieldset">
		<label v-if="label">
			{{ label }}
		</label>
		<div class="input" v-if="values">
			<select :name="name" @input="handleInput($event.target.value)" class="select">
				<option v-for="o in Object.keys(values)" :key="o" :value="o" :selected="o == value ? true : false">
					{{values[o]}}
				</option>
			</select>
		</div>
		<div v-if="o.$invalid" class="error error--form">
			<span v-for="k in Object.keys(o.$params)" v-bind:key="k">
				<span v-if="submitted && !o[k]">
                   {{ errors[`${name}_${k}`] }}<br>
                </span>
			</span>
		</div>
	</div>
</template>

<script>
    export default {
        name: 'Slc',
		data() {
			return {
				errors: {
					name_required: "Nome richiesto",
					title_required: "Il titolo è obbligatorio",
					undefined_required: "Il campo è obbligatorio",
					email_required: "Indirizzo email richiesto",
					email_email: "E-mail non valida",
					data_password_required: "Password richiesta",
					password_requiredIf: "Password richiesta",
					password_required: "Inserire una password valida",
					data_password_requiredIf: "Password richiesta",
					password_minLength: "La password deve essere superiore a 8 caratteri",
					data_password_minLength: "La password deve essere superiore a 8 caratteri",
					repeat_required: "Ripetere la password",
					repeat_sameAsPassword: "Le due password non coincidono",
					uuid_required: "UUID Richiesto",
					major_required: "Major Richiesto",
					minor_required: "Minor Richiesto",
					major_numeric: "Major deve essere un numero intero",
					minor_numeric: "Minor deve essere un numero intero",
					point_isPoint: "Deve essere un punto valido. X,Y",
					file_not_valid: "Il file inserito non è valido",
					coordinates_required: "Le coordinate sono richieste",
					coordinates_isPoint: "Deve essere una coordinata valida.(X,Y)",
					image_required: "Immagine richiesta",
					image_not_valid: "Non è stata inserita un'immagine valida",
					intersect_detected: "Non è possibile inserire un punto in questa posizione, i lati del poligono noon si possono intersecare"
				},
			}
		},
        methods: {
            init() {
            },
            handleInput(content) {
                content = (+content) ? +content : content;
                content = (content == "0") ? 0 : content;
                this.$emit('input', content)
            }
        },
        mounted() {
            this.init();
        },
        props: {
            value: {
                required: true
            },
            o: {
                type: Object,
                default: null,
                required: true
            },
            submitted: {
                type: Boolean,
                default: false,
                required: true
            },
            label: {
                type: String,
                default: null,
                required: false
            },
            name: {
                type: String,
                default: '',
                required: true
            },
            values: {
                default: {},
                required: true
            }
        }
    }
</script>

<style lang="scss">
	.fieldset {
		display: flex;
		flex-direction: column;
		@extend %flexCenterStart;
		width: 100%;

		label {
			display: block;
			width: 100%;
			text-align: left;
			margin: 10px 0 -5px 0; // Kekko non mi uccidere per sta cosa
			font-size: 14px;
			font-weight: 400;
		}

		.input {
			width: 100%;

			border: 1px solid $isabelline;
			border-radius: calc(var(--radius) / 2.5);
			margin: 8px 0;

			select {
				border: 0;
				width: 99%;
				font-size: 0.9em;
				padding: 10px;
			}
		}

		.error {
			display: block;
			width: 100%;
			font-size: 0.6em;
			color: $red;
			line-height: 0.8em;
			text-transform: uppercase;
			border-radius: 0;

			&--form {
				line-height: calc(var(--gutter) / 2);
				text-align: right;
			}
		}

	}
</style>
