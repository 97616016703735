<template>
	<form class="form" autocomplete="off" @submit.prevent="submitForm" @change="hasChanged">
		<!-- TABS -->

		<div class="tabs--stage" v-if="tabs">
			<div class="tabs--drag">
				<div class="tabs">
					<div :class="`tab ${(t.key == tabSelected) ? 'selected' : ''}`" v-for="t in tabs" v-bind:key="t.key"
						 @click="tabSelected = t.key; actionTab();">
						{{ t.caption }}
					</div>
				</div>
			</div>
		</div>

		<!-- FORM -->
		<div class="panel panel_post">
			<div v-if="error" class="error error--general-big">
				{{ error }}
			</div>

			<div class="tab_item" v-if="tabSelected == 'generale'">
				<Inpt label="Titolo" name="title" v-model="$v.form.title.$model" :o="$v.form.title" :maxlength="96"
					  :battute="true" :placeholder="`Titolo sondaggio`" :submitted="submitted"></Inpt>
				<Inpt label="Domanda" name="title" v-model="$v.form.others.domanda.$model" :o="$v.form.others.domanda"
					  :maxlength="96" :battute="true" :placeholder="`Domanda`" :submitted="submitted"></Inpt>


				<div class="fieldset">
					<label>Elenco delle risposte</label>
					<br><!-- kekko, so che il tag è <br /> ma voglio andare contro il sistema -->
					<div class="risposte__list" v-if="!loading">
						<div class="risposte__list__item" v-for="(i, index) in form.others.risposte_possibili"
							 :key="index">
							<div @click="removeRisposta(index)" class="risposte__list__item__trash">
								<Icon icon="trash"></Icon>
							</div>
							<div class="new-box">
								<span class="new-title">
									<strong>Codice:</strong>
									<input type="text" v-model="i.codice">
								</span>
								<span class="new-title">
									<strong>Risposta:</strong>
									<input type="text" v-model="i.risposta">
								</span>
							</div>
						</div>
						<div v-if="form.others.risposte_possibili.length < 5" @click="addRisposta()" class="risposte__list__item__add">
							<span class="btn default">Aggiungi risposta</span>
						</div>
						<br><br> <!-- vedi sopra, TVB -->
					</div>
				</div>

				<div class="fieldset" v-if="form.others.data_scadenza">
					<label>Scadenza</label>
					<div class="input">
						<Datetime :type="`datetime`" v-model="$v.form.others.data_scadenza.$model"></Datetime>
					</div>
				</div>

				<div class="fieldset homes">
					<label>Visualizza in home</label>
					<div class="homes_data" v-for="h in homes" :key="h">
						<div class="h--list">
							<label class="h__item">
								<input name="scegli_dove_pubblicare" type="checkbox" v-model="$v.form.others.scegli_dove_pubblicare.$model"
									   :value="h"/>
								{{ h }}
							</label>
						</div>
					</div>
				</div>

			</div>

			<div class="form-buttons">
				<div></div>
				<!--<span class="next_button" v-if="!loading && tabSelected != 'attribute' && form.id == null"
					  @click="tabSelected = nextTab()">
					Avanti
				</span>-->
				<!--<span class="next_button" v-if="!loading && (tabSelected == 'attribute' || form.id != null)"
					  @click="saveDraft()">
					Salva come bozza
				</span>-->
				<Btn v-if="tabSelected == 'generale' || form.id != null" :loading="loading" :text="'Salva'"
					 text-size="big" button-type="primary" :is-full="false" :is-submit-button="true"></Btn>
			</div>
		</div>
	</form>
</template>
<script>
import {required} from 'vuelidate/lib/validators';
import Btn from "../common/forms/Btn";
import Inpt from "../common/forms/Inpt";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Datetime} from 'vue-datetime'
import Icon from "@components/common/forms/Icon";

export default {
	name: 'SondaggioForm',
	components: {
		Icon,
		Inpt,
		Btn,
		Datetime
	},
	props: {
		// l'oggetto di base
		base: {
			default: null,
			required: false
		}
	},
	data () {
		return {
			// indica se il form è stato spedito
			submitted: false,
			// errori della form
			error: '',
			loading: false,
			// gestione dei tab
			tabSelected: 'generale',
			tabs: [
				{key: 'generale', caption: ""},
			],
			homes: [
				"Telenuovo",
				"TgVerona",
				"TgGialloblu",
				"TgPadova",
				"TgBiancoscudato"
			],
			// oggetto form
			form: {
				id: null,
				title: '',
				date: null,
				others: {
					domanda: '',
					risposte_possibili: [],
					data_scadenza: null,
					visualizza_in_homepage: false,
					scegli_dove_pubblicare: []
				}
			}
		};
	},
	/**
	 * Validatori Form
	 */
	validations: {
		form: {
			id: {},
			title: {
				required
			},
			date: {},
			others: {
				domanda: {required},
				risposte_possibili: {},
				data_scadenza: {},
				visualizza_in_homepage: {},
				scegli_dove_pubblicare: {}
			}
		}
	},
	methods: {
		/**
		 * Inizializzo la pagina
		 */
		init () {
			if (this.base !== null) {
				this.tags = [];
				Object.keys(this.form).map(k => {
					if (this.base[k]) {
						this.form[k] = this.base[k];
					}
					if (k == "tags") {
						this.form[k].map(t => {
							this.tags.push({text: t});
						})
					}
				})
			}
		},
		addRisposta () {
			this.form.others.risposte_possibili.push({
				codice: '',
				risposta: ''
			});
		},
		removeRisposta (index) {
			let rs = [];
			this.form.others.risposte_possibili.map(r => {
				rs.push(r);
			})
			rs.splice(index, 1);
			this.form.others.risposte_possibili = rs;
		},
		/**
		 * Quando la form cambia, viene chiamato questo metodo che emette al componente
		 * padre la modifica
		 */
		hasChanged () {
			this.$emit('change', !this.submitted);
		},
		/**
		 * Quando viene selezionata una tab, controllo la tab ed eseguo
		 * delle operazioni
		 */
		actionTab () {
		},
		/**
		 * Tab successiva,
		 * @returns {string} il codice della tab successiva
		 */
		nextTab () {
			let next = "";
			this.tabs.map((t, index) => {
				if (this.tabSelected == t.key) {
					next = this.tabs[index + 1].key;
				}
				this.actionTab();
			})
			return next;
		},
		/**
		 * Chisura della form
		 */
		close () {
			this.$emit('close');
			this.submitted = false;
		},
		/**
		 * Salvataggio della form
		 */
		submitForm () {
			this.$v.form.$touch();
			this.submitted = true; // setto come inviato
			this.hasChanged(); // blocco l'emissione del form cambiato (non serve)
			this.error = '';
			this.loading = true;
			if (!this.$v.form.$invalid) {
				let f = {...this.form};

				f.others.visualizza_in_homepage = false;
				if(f.others.scegli_dove_pubblicare.length > 0) {
					f.others.visualizza_in_homepage = true;
				}

				this.service.sondaggio.save(f)
					.then((resultSondaggio) => {
						f.id = resultSondaggio.id;
						this.service.sondaggio.save(f)
							.then(() => {
								this.$emit('save');
								this.submitted = false;
							})
							.catch((err) => {
								if (err && err.messages && err.messages[0]) {
									let errorMessage = this.errors[err.messages[0]];
									this.error = this.errorMessage;
									this.submitted = false;
								}
							})
							.finally(() => {
								this.loading = false;
							})
					})
					.catch((err) => {
						if (err && err.messages && err.messages[0]) {
							let errorMessage = this.errors[err.messages[0]];
							this.error = this.errorMessage;
							this.submitted = false;
						}
					})
					.finally(() => {
						this.loading = false;
					})
			} else {
				this.loading = false;
				this.flashMessage.show({
					status: 'error',
					title: 'Mancano dati',
					message: 'Per salvare è necessario il titolo, la domanda e almeno delle risposte'
				});
			}
		},
		dragTabs () {
			let tabBox = this.$el.querySelector('.tabs--stage');
			if ('ontouchstart' in document.documentElement === true) {
				if (this.$draggable) {
					this.$draggable.create('.tabs', {
						type: 'left',
						bounds: '.tabs--stage',
						inertia: true,
						onDragStart: function () {
							Array.from(document.querySelectorAll('.tab')).forEach(link => {
								link.classList.add('disabled');
							})
						},
						onDragEnd: function () {
							Array.from(document.querySelectorAll('.tab')).forEach(link => {
								link.classList.remove('disabled');
							})
						}
					})
				}
			}
		}
	},
	mounted () {
		// inizializzo il tutto
		this.init();
		this.dragTabs();
	}
}
</script>
<style lang="scss">
.form-buttons {
	width: 100%;
	display: flex;
	justify-content: space-between !important;
	margin-top: 30px;
}

.fieldset {
	.error {
		margin-bottom: 15px;
	}
}

.risposte {

	display: block;
	width: 19%;
	float: left;

	&__title {
		font-size: 14px;
		color: $primary;
		margin: 20px 0 20px 0;
	}

	&__item {
		cursor: pointer;
		display: block;
		width: 100%;
		margin: 6px 0;
		padding-left: 0px;
	}
}

.panel_post {
	width: 100%;
}

.risposte {
	margin-top: 10px;
	border: 1px solid rgba($primary, 0.2);
	padding: 10px;
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	h2 {
		align-content: center;
		margin-bottom: var(--gutter);
	}

	&__link {
		cursor: pointer;
	}

	&__list {
		display: flex;
		flex-direction: column;
		align-content: center;
		flex-wrap: wrap;
		width: 100%;

		&__item {
			display: flex;
			@extend %flexStartStart;
			width: 100%;
			margin-bottom: var(--gutter);

			input {
				margin: 0 10px;
				height: 30px;
			}

			.new-box {
				display: flex;
				flex-wrap: wrap;
				width: 100%;

				.new-title,
				.new-article {
					display: block;
					width: 100%;
				}

				.new-article {
					margin-top: var(--minigutter);
				}

				strong, input, div {
					display: block;
					width: 100%;
				}

				input {
					width: 100%;
					margin: 6px 0;
				}
			}

			strong {
				// margin: 0 5px;
				font-weight: normal;
				font-size: 14px;
			}

			&__title {

			}

			&__trash {
				display: flex;
				@extend %flexCenterCenter;
				@include size(var(--icon), var(--icon));

				svg {
					cursor: pointer;
					fill: $primary;
				}

				&:hover {
					svg {
						fill: $red;
					}
				}
			}
		}
	}

	@media (min-width: $to-medium) {
		&__list {
			&__item {
				.new-box {
					width: calc(100% - var(--icon));
					padding-left: var(--minigutter);

					.new-title {
						display: block;
						width: 50%;
						margin-right: var(--minigutter);
					}
				}
			}
		}
	}
}
</style>
