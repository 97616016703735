<template>
	<nav class="main--menu" role="navigation">
		<span class="bg"></span>
		<div class="drawer">
			<!--<div class="livestream" v-if="loadingShowLiveStream && role != 'author'">
				<small class="label">
					Attivare live stream
				</small>
				<label for="attiva-live" class="checkradio-box">
					<input :disabled="loadingLiveStream" @change="toggle()" v-model="statusLiveStream" type="checkbox" name="attiva-live" id="attiva-live" class="checkradio-box--input"/>
					<span class="fake-check"></span>
				</label>
			</div>-->
			<router-link :to="{ name: 'postlist.page' }" class="menu-link"
						 :class="`${(is('') || is('post')) ? 'active' : ''}`">
				<span class="menu-link--icon">
					<svg viewBox="0 0 40 40">
						<use xlink:href="#icons--files" width="100%" height="100%"></use>
					</svg>
				</span> <span class="menu-link--label">
					Articoli
				</span>
			</router-link>
			<router-link :to="{ name: 'medialist.page' }" class="menu-link" :class="{'active': is('media')}">
				<span class="menu-link--icon">
					<svg viewBox="0 0 40 40">
						<use xlink:href="#icons--documents" width="100%" height="100%"></use>
					</svg>
				</span> <span class="menu-link--label">
					Media
				</span>
			</router-link>
			<router-link v-if="role != 'author'" :to="{ name: 'socials.page' }" class="menu-link"
						 :class="{'active': is('socials')}">
				<span class="menu-link--icon">
					<svg viewBox="0 0 40 40">
						<use xlink:href="#icons--campaigns" width="100%" height="100%"></use>
					</svg>
				</span> <span class="menu-link--label">
					Socials
				</span>
			</router-link>
			<hr>
			<router-link v-if="role != 'author'" :to="{ name: 'sondaggiolist.page' }" class="menu-link"
						 :class="{'active': is('sondaggio')}">
				<span class="menu-link--icon">
					<svg viewBox="0 0 40 40">
						<use xlink:href="#icons--faq" width="100%" height="100%"></use>
					</svg>
				</span> <span class="menu-link--label">
					Sondaggi
				</span>
			</router-link>
			<hr>
			<router-link v-if="role != 'author'" :to="{ name: 'playcategorielist.page' }" class="menu-link"
						 :class="{'active': is('playcategorie')}">
				<span class="menu-link--icon">
					<svg viewBox="0 0 40 40">
						<use xlink:href="#icons--media" width="100%" height="100%"></use>
					</svg>
				</span> <span class="menu-link--label">
					Play Categorie
				</span>
			</router-link>
			<router-link v-if="role != 'author'" :to="{ name: 'playlist.page' }" class="menu-link"
						 :class="{'active': is('play')}">
				<span class="menu-link--icon">
					<svg viewBox="0 0 40 40">
						<use xlink:href="#icons--play" width="100%" height="100%"></use>
					</svg>
				</span> <span class="menu-link--label">
					Play
				</span>
			</router-link>
			<hr>
			<router-link v-if="role != 'author'" :to="{ name: 'specialicategorie.page' }" class="menu-link"
						 :class="{'active': is('specialicategorie')}">
				<span class="menu-link--icon">
					<svg viewBox="0 0 40 40">
						<use xlink:href="#icons--overview" width="100%" height="100%"></use>
					</svg>
				</span> <span class="menu-link--label">
					Gestione Speciali
				</span>
			</router-link>
			<router-link :to="{ name: 'speciali.page' }" class="menu-link"
						 :class="{'active': is('speciali')}">
				<span class="menu-link--icon">
					<svg viewBox="0 0 40 40">
						<use xlink:href="#icons--files" width="100%" height="100%"></use>
					</svg>
				</span> <span class="menu-link--label">
					Contenuti Speciali
				</span>
			</router-link>
			<hr>
			<router-link v-if="role != 'author'" :to="{ name: 'mediastatus.page' }" class="menu-link"
						 :class="{'active': is('mediastatus')}">
				<span class="menu-link--icon">
					<svg viewBox="0 0 40 40">
						<use xlink:href="#icons--information" width="100%" height="100%"></use>
					</svg>
				</span> <span class="menu-link--label">
					Stato Media
				</span>
			</router-link>
			<router-link v-if="role != 'author'" :to="{ name: 'reportVideo.page' }" class="menu-link"
						 :class="{'active': is('reportVideo')}">
				<span class="menu-link--icon">
					<svg viewBox="0 0 40 40">
						<use xlink:href="#icons--information" width="100%" height="100%"></use>
					</svg>
				</span> <span class="menu-link--label">
					Report Video
				</span>
			</router-link>
		</div>
	</nav>
</template>
<script>
export default {
	name: 'Menu',
	data () {
		return {
			role: '',
			statusLiveStream: null,
			loadingShowLiveStream: false,
			loadingLiveStream: false
		}
	},
	methods: {
		is (page) {
			let path = this.$route.path;
			path = path.replace('/', '');
			path = path.toLowerCase().trim();
			page = page.toLowerCase().trim();
			path = path.split("/");
			if (path[0] == page) {
				return true;
			}
			return false;
		},
		getProfile () {
			this.service.profile()
				.then((data) => {
					this.role = data.roles || "";

					/*this.service.settings.sticky.status()
						.then((res) => {
							this.statusLiveStream = false;
							if (res && res.status && res.status == true) {
								this.statusLiveStream = true;
							}
							this.loadingShowLiveStream = true;
						})*/
				})
		},
		toggle () {
			this.loadingLiveStream = true;
			this.service.settings.sticky.toggle()
				.then(() => {
					this.flashMessage.show({
						status: 'success',
						title: 'Live Stream',
						message: 'Lo stato del live stream è stato cambiato correttamente'
					});
				})
				.finally(() => {
					this.loadingLiveStream = false;
				})
		}
	},
	mounted () {
		this.getProfile();
	}
}
</script>
<style lang="scss">
@import '@/assets/scss/layout/_menu';
</style>
