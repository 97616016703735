<template>
    <div class="notfound">
        404 - Pagina non trovata
    </div>
</template>

<script>
    export default {
        name: 'NotFoundPage',
    }
</script>

<style lang="scss">
    .notfound {
        text-align: center;
        line-height: 90vh;
        font-size: 30px;
    }
</style>
