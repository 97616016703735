<template>
	<div class="fieldset galleria_cointainer">

		<div class="row">
			<div class="col three-quarters">
				<h2>{{ title }}</h2>
			</div>
			<div class="col one-quarters">
				<Btn v-if="this.data.length < 12" @btn-click="showMediaModal = true" :text="`Aggiungi alla galleria`" text-size="small" :isFull="true"></Btn>
			</div>
		</div>

		<LoadingSpinner v-if="loading" :small="true"></LoadingSpinner>

		<div class="galleria_cointainer__list" v-if="!loading">
			<div class="galleria_cointainer__list__item" v-for="(i, index) in items" :key="i.id">
				<div class="galleria_cointainer__list__item__img o-ratio u-16:10">
					<div @click="remove(index)" class="galleria_cointainer__list__item__trash">
						<Icon icon="trash"></Icon>
					</div>
					<img :src="i.media">
				</div>
				<div class="galleria_cointainer__list__item__dida">
					<input type="text" :value="i.didascalia" @input="handleInput(i, $event.target.value)">
				</div>
			</div>
		</div>
		<SelectMediaModal v-if="showMediaModal" :onlyimage="true" @close="select($event)"></SelectMediaModal>
	</div>
</template>

<script>
import LoadingSpinner from "@components/common/Loading";
import Icon from "@components/common/forms/Icon";
import Btn from "@components/common/forms/Btn";
import SelectMediaModal from "@components/common/modals/SelectMediaModal";

export default {
	name: 'GalleriaSelection',
	components: {SelectMediaModal, Btn, Icon, LoadingSpinner},
	data () {
		return {
			showMediaModal: false,
			selected: null,
			items: [],
			loading: false,
		}
	},
	methods: {
		init (data) {
			this.items = [];
			if (data && data.length) {
				this.loading = true;
				data.map(d => {
					this.items.push(d);
				})
				this.loading = false;
			}
		},
		select (media) {
			let data = [];
			if(media) {
				this.data.map(d => data.push(d));
				data.push({
					didascalia: `${media.title || ``}`,
					media: `${media.url || ``}`,
				});
				data = [...new Set(data)];
				this.init(data);
				this.$emit("update", data);
			}
			this.showMediaModal = false;
		},
		remove (index) {
			let data = [];
			this.data.map((d, i) => {
				if (index !== i) {
					data.push(d)
				}
			});
			data = [...new Set(data)];
			this.$emit("update", data);
			this.init(data);
		},
		handleInput (i, val) {
			i.didascalia = val;
			let data = [];
			this.data.map(d => {
				if (d.media == i.media) {
					d.didascalia = val;
				}
				data.push(d)
			});
			this.$emit("update", data);
		}
	},
	mounted () {
		this.init(this.data);
	},
	props: {
		data: {
			required: true,
			default: null,
		},
		title: {
			type: String,
			default: "Galleria immagini"
		},
		name: {
			type: String,
			default: '',
			required: true
		}
	}
}
</script>

<style lang="scss">

.galleria_cointainer {
	display: block;
	width: 100%;
	padding: var(--minigutter);
	border: 1px solid rgba($gun,0.3);
	.row {
		display: block;
		width: 100%;
		margin-bottom: var(--gutter);
	}
	.galleria_cointainer__list {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		&__item {
			display: block;
			width: 98%;
			margin: 1%;
			position: relative;
			margin-bottom: var(--gutter);
			&__img {
				position: relative;
				.galleria_cointainer__list__item__trash {
					position: absolute;
					top: 6px;
					left: 6px;
					display: flex;
					@extend %flexCenterCenter;
					background: $red;
					@include size(var(--gutter),var(--gutter));
					z-index: 3;
					cursor: pointer;
					.input-icon {
						display: block;
						@include size(90%,90%);
						svg {
							display: block;
							@include size(100%,100%);
							fill: $white;
						}
					}
					img {
						position: absolute;
						top: 0;
						left: 0;
						display: block;
						@include size(100%,100%);
						object-fit: cover;
					}
				}
			}
			&__dida {
				display: block;
				width: 100%;
				margin-top: var(--minigutter);
				input {
					width: 100%;
				}
			}
		}
	}
	@media (min-width: $to-medium) {
		padding: var(--gutter);
		.galleria_cointainer__list {
			&__item {
				width: 18%;
			}
		}
	}
}

</style>
