<template>
	<transition name="confirm.modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container modal-container-big">
					<div class="modal-header">
						<slot name="header">
							<h3>Pubblicazione sui socials</h3>
						</slot>
					</div>
					<div class="modal-body">
						<slot name="body">

							<div class="social_container">
								<div>
									<div class="fieldset">
										<label>Titolo</label>
										<div class="input">
											<input type="text" v-model="socialForm.text">
										</div>
										<!--
										<label>Tags</label>
										<VueTagsInput v-model="tag" :tags="tags" :placeholder="`Aggiungi tag articolo`"
													  @tags-changed="tags => updateTags(tags)"/>
										-->

									</div>
									<div class="fieldset">
										<label>
											<input type="checkbox" v-model="socialForm.scheduled">
											Post schedulato
										</label>
										<div class="input" v-if="socialForm.scheduled">
											<div class="field">
												<Datetime :format="`dd/MM/yyyy HH:mm:ss`" placeholder="Data schedulazione"
														  type="datetime" v-model="socialForm.scheduleddatetime"></Datetime>
											</div>
										</div>
									</div>
									<div class="fieldset social_container">
										<div class="social__block">
											<h3 class="social__block_title">Facebook</h3>
											<label>
												<input type="checkbox" v-model="socialForm.facebook.telenuovo"/>
												Telenuovo<strong
												v-html="getSocialData(post, 'facebook', 'telenuovo')"></strong>
											</label>
											<label>
												<input type="checkbox" v-model="socialForm.facebook.verona"/>
												TgVerona<strong
												v-html="getSocialData(post, 'facebook', 'verona')"></strong>
											</label>
											<label>
												<input type="checkbox" v-model="socialForm.facebook.padova"/>
												TgPadova<strong
												v-html="getSocialData(post, 'facebook', 'padova')"></strong>
											</label>
											<label>
												<input type="checkbox" v-model="socialForm.facebook.gialloblu"/>
												TgGialloblu<strong
												v-html="getSocialData(post, 'facebook', 'gialloblu')"></strong>
											</label>
											<label>
												<input type="checkbox" v-model="socialForm.facebook.biancoscudato"/>
												TgBiancoscudato<strong
												v-html="getSocialData(post, 'facebook', 'biancoscudato')"></strong>
											</label>
										</div>
										<div class="social__block">
											<h3 class="social__block_title">Instagram</h3>
											<label>
												<input type="checkbox" v-model="socialForm.instagram.telenuovo"/>
												Telenuovo<strong
												v-html="getSocialData(post, 'instagram', 'telenuovo')"></strong>
											</label>
											<label>
												<input type="checkbox" v-model="socialForm.instagram.verona"/>
												TgVerona<strong
												v-html="getSocialData(post, 'instagram', 'verona')"></strong>
											</label>
											<label>
												<input type="checkbox" v-model="socialForm.instagram.padova"/>
												TgPadova<strong
												v-html="getSocialData(post, 'instagram', 'padova')"></strong>
											</label>
											<label>
												<input type="checkbox" v-model="socialForm.instagram.gialloblu"/>
												TgGialloblu<strong
												v-html="getSocialData(post, 'instagram', 'gialloblu')"></strong>
											</label>
											<label>
												<input type="checkbox" v-model="socialForm.instagram.biancoscudato"/>
												TgBiancoscudato<strong
												v-html="getSocialData(post, 'instagram', 'biancoscudato')"></strong>
											</label>
										</div>
										<div class="social__block">
											<h3 class="social__block_title">Twitter</h3>
											<label>
												<input type="checkbox" v-model="socialForm.twitter.telenuovo"/>
												Telenuovo<strong
												v-html="getSocialData(post, 'twitter', 'telenuovo')"></strong>
											</label>
											<label>
												<input type="checkbox" v-model="socialForm.twitter.verona"/>
												TgVerona<strong
												v-html="getSocialData(post, 'twitter', 'verona')"></strong>
											</label>
											<label>
												<input type="checkbox" v-model="socialForm.twitter.padova"/>
												TgPadova<strong
												v-html="getSocialData(post, 'twitter', 'padova')"></strong>
											</label>
											<label>
												<input type="checkbox" v-model="socialForm.twitter.gialloblu"/>
												TgGialloblu<strong
												v-html="getSocialData(post, 'twitter', 'gialloblu')"></strong>
											</label>
											<label>
												<input type="checkbox" v-model="socialForm.twitter.biancoscudato"/>
												TgBiancoscudato<strong
												v-html="getSocialData(post, 'twitter', 'biancoscudato')"></strong>
											</label>
										</div>
									</div>
								</div>

								<!--<div class="social__block" style="margin-left: 20px" v-if="postForm">
									<h3 class="social__block_title">Anteprima Instagram</h3>
									<div ref="instagramPreview">
										<div class="preview--instagram">
											<div class="thumb">
												<div class="thumb__img">
													<img :src="postForm.media.replace(`playlist.m3u8`, `thumbnail.jpg`)"
														 alt=""/>
												</div>
												<span class="thumb__text">
													<img class="tg"
														 :src="`${instagramSocialApiUrl}/assets/${getTg()}.png`">
													{{ socialForm.text }}
												</span>
											</div>
										</div>
									</div>

								</div>-->
							</div>

						</slot>
					</div>
					<div class="modal-footer">
						<slot name="footer">
							<Btn :loading="loading" @btn-click="publishPost()" button-type="primary" text-size="big"
								 text="Pubblica"></Btn>
							<Btn :loading="loading" @btn-click="closeModal()" button-type="default" text-size="big"
								 text="Chiudi"></Btn>
						</slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
import Btn from "../forms/Btn";
import {Datetime} from 'vue-datetime'

export default {
	name: 'SocialsModals',
	components: {Btn, Datetime},
	props: {
		post: {
			required: false
		},
		social: {
			required: false
		}
	},
	data () {
		return {
			postForm: null,
			tag: ``,
			tags: [],
			socialForm: {
				text: ``,
				tags: [],
				scheduled: false,
				scheduleddatetime: null,
				facebook: {
					telenuovo: false,
					verona: false,
					padova: false,
					biancoscudato: false,
					gialloblu: false
				},
				instagram: {
					telenuovo: false,
					verona: false,
					padova: false,
					biancoscudato: false,
					gialloblu: false
				},
				twitter: {
					telenuovo: false,
					verona: false,
					padova: false,
					biancoscudato: false,
					gialloblu: false
				}
			},
			loading: false
		}
	},
	watch: {
		'this.post': {
			immediate: true,
			deep: true,
			handler () {
				if (this.post) {
					this.postForm = this.post;
					if (this.socialForm.text == "") {
						this.socialForm.text = this.postForm.title
					}
				}
			}
		}
	},
	methods: {
		closeModal () {
			this.$emit('close')
		},
		publishPost () {
			this.loading = true;
			let p = {...this.socialForm};

			let promises = [];
			["facebook", "instagram", "twitter"].forEach(platform => {
				["telenuovo", "verona", "padova", "gialloblu", "biancoscudato"].forEach(page => {
					const text = p.text;
					const tags = p.tags;
					const url = this.service.utils.getArticleUrl(this.post)
					if (p[platform][page]) {
						promises.push(this.service.socials.add(text, tags, url, platform, page, p.scheduled, p.scheduleddatetime))
					}
				})
			})

			Promise.all(promises)
				.then(() => {
					this.flashMessage.show({
						status: 'success',
						title: 'Successo',
						message: 'Salvato con successo'
					});
				})
				.catch(() => {
					this.flashMessage.show({
						status: 'error',
						title: 'Errore',
						message: 'Errore durante la pubblicazione di un elemento nei social.'
					});
				})
				.finally(() => {
					this.loading = false;
					this.$emit('close')
				})
		},
		updateTags (tags) {
			this.socialForm.tags = [];
			tags.map(t => {
				this.socialForm.tags.push(t.text);
			})
		},
		getSocialData (item, platform, page) {
			if (this.social && this.social.length) {
				const s = this.social.filter((soc) => {
					return soc.page == page && soc.platform == platform;
				});
				if (s && s.length) {
					if (s[0].status == "success") {
						return "<span style='color: green'> (Sì)</span>";
					} else {
						if (s[0].status == "none") {
							return "<span style='color: gray'> (...)</span>";
						} else {
							return `<span style='color: red' data-tooltip='${s[0].message}'> (Error)</span>`
						}
					}
				} else {
					return `<span style='color: gray'></span>`;
				}
			} else {
				return `<span style='color: gray'></span>`;
			}
		}
	}
}
</script>
<style lang="scss">
.social_container {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: flex-start;
}

.social__block {
	padding-top: var(--gutter);
	padding-bottom: var(--gutter);
	padding-right: calc(var(--gutter) * 2);
	padding-left: 0;
}

[data-tooltip] {
	position: relative;
	z-index: 2;
	cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
	position: absolute;
	bottom: 150%;
	left: 50%;
	margin-bottom: 5px;
	margin-left: -80px;
	padding: 7px;
	width: 350px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background-color: #000;
	background-color: hsla(0, 0%, 20%, 0.9);
	color: #fff;
	content: attr(data-tooltip);
	text-align: center;
	font-size: 14px;
	line-height: 1.1;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
	position: absolute;
	bottom: 150%;
	left: 50%;
	margin-left: -5px;
	width: 0;
	border-top: 5px solid #000;
	border-top: 5px solid hsla(0, 0%, 20%, 0.9);
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
	content: " ";
	font-size: 0;
	line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
	visibility: visible;
	opacity: 1;
}
</style>
