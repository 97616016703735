<template>
	<transition name="confirm.modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container modal-container-big">
					<div class="modal-header">
						<slot name="header">
							<h3>Seleziona un articolo</h3>
						</slot>
					</div>
					<div class="modal-body">
						<slot name="body">
							<PostList :ispopup="true" @close="closeModal($event)"></PostList>
						</slot>
					</div>
					<div class="modal-footer">
						<slot name="footer">
							<Btn @btn-click="closeModal(false)" :text="`Annulla`" text-size="big"></Btn>
						</slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
import PostList from "@/views/pages/post/PostList";
import Btn from "@components/common/forms/Btn";

export default {
	name: 'SelectPostModal',
	components: {Btn, PostList},
	methods: {
		closeModal (item) {
			this.$emit('close', item)
		}
	}
}
</script>

<style lang="scss">
.modal-footer {
	display: flex;
	justify-content: space-between !important;
}
</style>
