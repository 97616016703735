<template>
	<div id="play_list" class="page main-content">
		<div class="page--head">
			<div class="head--title">
				<PageTitle :title_translation="false" :title="`Play`"
						   :subtitle="`Gestione Trasmissioni`"></PageTitle>
				<!--<span class="floating">
					<Btn @btn-click="go('new')" text-size="small" button-type="primary" text="Aggiungi"></Btn>
				</span>-->
			</div>

			<div class="ricerca">
				<div :class="`post_list_actions`">
					<div class="post_list_actions__search_container">
						<div class="field">
							<input :disabled="loading" class="post_list_search" id="post_list_search" v-model="search"
								   @keydown.13="searchHandler($event)" :placeholder="'Cerca'">
						</div>
						<div class="ricerca--controls">
							<div class="field">
								<select @change="searchData()" :disabled="loading"
										v-model="search_category"
										name="post_list_search_category" id="post_list_search_category">
									<option :value="null">Tutte le categorie</option>
									<option :value="cat.id" v-for="cat in categories" :key="cat.id">
										{{ cat.name }}
									</option>
								</select>
							</div>

							<div class="send_search_container control">
								<div class="send_search" @click="searchData()">
									<Icon icon="search"></Icon>
								</div>
							</div>

							<div v-if="isSearch" class="trash_all_container control">
								<div class="trash_all" @click="initSearch();">
									<Icon icon="cross"></Icon>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>

		<div class="page--content">
			<!-- lista -->
			<div class="panel">
				<LoadingSpinner v-if="loading" :small="true"></LoadingSpinner>
				<div class="table" v-if="!loading && items.length">
					<div class="table--head">
						<span class="table--head__col long">Titolo</span>
						<span class="table--head__col">Categoria</span>
						<span class="table--head__col">Data</span>
						<span class="table--head__col center">ID Titolatrice</span>
					</div>
					<div class="table--body">
						<div v-for="i in items" v-bind:key="i.id" class="table--row">
			                <span class="table--body__col long article">
			                    <span @click="go(i.id)" class="post__title" v-html="i.title || `(Senza titolo)`"></span>
			                </span>
							<span class="table--body__col date">
			                    {{ getCategoriaById(i.categoria) }}
			                </span>
							<span class="table--body__col date">
			                    {{ i.data | datatime }}
			                </span>
							<span class="table--body__col table--body__col__center">
								<i>{{ i.telenuovo_id }}</i>
			                </span>
						</div>
					</div>
				</div>
				<div v-if="!loading && items.length == 0" class="no-posts">
					Nessun elemento da visualizzare
				</div>
				<!-- paginazione -->
				<PaginationSimple v-if="!loading" :page="page" :page_end="pageEnd"
								  v-on:page="changePage($event)"></PaginationSimple>
			</div>
		</div>
	</div>
</template>

<script>
import PageTitle from "../../components/common/PageTitle";
import LoadingSpinner from "../../components/common/Loading";
// import Btn from "../../components/common/forms/Btn";
import PaginationSimple from "../../components/common/forms/PaginationSimple";
import Icon from "@components/common/forms/Icon";

export default {
	name: 'PlayList',
	components: {
		Icon,
		PaginationSimple,
		// Btn,
		LoadingSpinner,
		PageTitle
	},
	data () {
		return {
			loading: false,
			items: [],
			page: 1,
			pageEnd: false,
			categories: [],
			search: '',
			search_category: null,
			isSearch: false
		}
	},
	methods: {
		initSearch () {
			this.isSearch = false;
			this.page = 1;
			this.search = '';
			this.search_category = null;
			localStorage.setItem('play_search', '');
			localStorage.setItem('play_search_category', '');
			this.refresh();
		},
		searchHandler (event) {
			if (event) event.preventDefault();
			if (event) event.stopPropagation();
			this.isSearch = true;
			this.page = 1;
			localStorage.setItem('play_search', this.search);
			localStorage.setItem('play_search_category', this.search_category);
			this.refresh();
		},
		getCategoriaById (cId) {
			cId = cId || 0;
			if (this.categories && this.categories.length) {
				let c = this.categories.filter(ff => {
					return +ff.id == +cId
				});
				if (c && c.length && c[0]) {
					return c[0].name
				} else {
					return ``;
				}
			} else {
				return ``;
			}
		},
		refresh () {
			this.loading = true;
			this.items = [];
			this.service.play_categorie.list(1)
				.then(categories => {
					if (categories && categories.length) {
						if (this.categories.length == 0) {
							categories.map(c => {
								this.categories.push({
									id: c.id,
									name: this.service.helpers.decode(c.title)
								});
							})
						}
						this.categories = this.categories.sort((a, b) => {
							if (a.name > b.name) return 1;
							if (a.name <= b.name) return -1;
							return 0;
						})
					}
					this.service.play.list(this.page, this.search, this.search_category)
						.then(items => {
							this.items = items;
						})
						.finally(() => {
							this.loading = false;
						})
				})

		},
		changePage (page) {
			this.page = page;
			this.refresh();
		},
		go (id) {
			this.$router.push({name: 'playdetail.page', params: {id: id}})
		},
		searchData() {
			localStorage.setItem('play_search', this.search);
			localStorage.setItem('play_search_category', this.search_category);
			this.isSearch = true;
			this.page = 1;
			this.refresh()
		}
	},
	mounted () {
		this.search = localStorage.getItem('play_search') || '';
		this.search_category = localStorage.getItem('play_search_category') || '';
		this.searchData();
	}
}
</script>

<style lang="scss">
@import '@/assets/scss/pages/_play';
@import '@/assets/scss/components/_ricercaposts';
</style>
