<template>
	<span :class="`input-icon ${icon}`" v-if="icon != ''">
		<svg :viewBox="`0 0 ${x} ${y}`">
			<use :xlink:href="`#icons--${icon}`" width="100%" height="100%"></use>
		</svg>
	</span>
</template>

<script>
    export default {
        name: 'Icon',
        props: {
        	x: {
        		type: Number,
				default: 93
			},
			y: {
        		type: Number,
				default: 70
			},
            icon: {
                type: String,
                default: '',
                required: true
            }
        }
    }
</script>

<style lang="scss">

	.input-icon {
		display: flex;
		@extend %flexCenterCenter;
		@include size(var(--icon), var(--icon));

		svg {
			@include size(70%, 50%);
			fill: $isabelline;
		}
	}


</style>
