<template>
    <div class="profile-utility">
        <span class="username" v-if="u">
            <span class="username--name">
                {{u.username}}
            </span>
            <small class="username--role">
                {{ roles[u.roles] }}
            </small>
        </span>
        <!--<router-link :to="{ name: 'profile.page' }" class="goto-account">
            <svg viewBox="0 0 40 40">
                <use xlink:href="#icons--account" width="100%" height="100%"></use>
            </svg>
        </router-link>-->
        <div @click="showLogoutModal=true" class="logout">
            <svg viewBox="0 0 40 40">
                <use xlink:href="#icons--logout" width="100%" height="100%"></use>
            </svg>
        </div>
        <confirm-modal v-if="showLogoutModal" @close="logout($event)" title="Logout" description="Eseguire il logout?" ></confirm-modal>
    </div>
</template>

<script>
import ConfirmModal from "./modals/ConfirmModal";
export default {
    name: 'ProfileUtility',
    components: {ConfirmModal},
    data() {
        return {
            showLogoutModal: false,
            u: null,
            roles: {
                admin: "Amministratore",
                desk: "Desk",
                editor: "Desk",
                user: "Giornalista",
                subscriber: "Giornalista",
                author: "Giornalista"
            },
        }
    },
    mounted() {
        this.refreshToken();
    },
    methods: {
        logout(result) {
            this.showLogoutModal = false;
            if(result) {
                this.$router.push('/login').catch(() => {});
            }
        },
        refreshToken() {
            this.service.profile()
                .then((data) => {
                    // this.$i18n.locale = data.user.locale;
                    // localStorage.setItem('locale', data.user.locale);
                    localStorage.setItem('user_name', data.username);
                    this.u = data;
                })
        }
    }
}
</script>

<style lang="scss">
.profile-utility {
    display: flex;
    @extend %flexCenterEnd;
	color: $white;
    .username {
        display: flex;
        flex-direction: column;
        @extend %flexEndCenter;
        text-align: right;
        &--name {
            font-size: 1.2em;
            line-height: 1.2em;
        }
        &--role {
            font-size: 0.8em;
            line-height: 1.2em;
        }
        &--role {
            font-size: 0.6em;
        }
    }
    .goto-account,
    .logout {
        display: flex;
        @extend %flexCenterCenter;
        @include size(var(--icon),var(--icon));
        margin: 0 18px;
        cursor: pointer;
        svg {
            @include size(70%,70%);
            fill: $grey;
            transition: 300ms linear;
        }
        &:hover {
            svg { fill: $primary; }
        }
    }
}
</style>
