<template>
	<div class="fieldset titoli_correlati_cointainer">

		<div class="row">
			<div class="col three-quarters">
				<h2>{{ title }}</h2>
			</div>
			<div class="col one-quarters">
				<Btn v-if="this.data.length < 3" @btn-click="showPostModal = true" :text="`Aggiungi`" text-size="small" :isFull="true"></Btn>
			</div>
		</div>

		<LoadingSpinner v-if="loading" :small="true"></LoadingSpinner>

		<div class="titoli_correlati_cointainer__list" v-if="!loading">
			<div class="titoli_correlati_cointainer__list__item" v-for="(i, index) in items" :key="i.id">
				<div @click="remove(index)" class="titoli_correlati_cointainer__list__item__trash">
					<Icon icon="trash"></Icon>
				</div>
				<div class="new-box">
					<span class="new-title">
						<strong>Titolo:</strong>
						<input type="text" :value="i.title" @input="handleInput(i, $event.target.value)">
						<div class="battute_titoli_correlati">(Caratteri: {{calcuateBattute(i)}})</div>
					</span>
					<span class="new-article">
						<strong>Articolo: </strong>
						<div class="titoli_correlati_cointainer__list__item__title" v-html="i.post.title"></div>
					</span>
				</div>
			</div>
		</div>

		<SelectPostModal title="Seleziona articolo correlato" v-if="showPostModal" @close="select($event)"></SelectPostModal>
	</div>
</template>

<script>
import SelectPostModal from "@components/common/modals/SelectPostModal";
// import Btn from "@components/common/forms/Btn";
import LoadingSpinner from "@components/common/Loading";
import Icon from "@components/common/forms/Icon";
import Btn from "@components/common/forms/Btn";

export default {
	name: 'TitoliCorrelati',
	components: {Btn, Icon, LoadingSpinner, SelectPostModal},
	data () {
		return {
			showPostModal: false,
			selected: null,
			items: [],
			loading: false,
		}
	},
	methods: {
		init (data) {
			this.items = [];
			if (data && data.length) {
				this.loading = true;

				let ids = [];
				data.map(d => {
					ids.push(d.post);
				})

				let posts = [];
				this.service.post.list_read_only(1, null, "publish", null, null, null, null, ids)
				.then((res) => {
					if (res && res.length) {
						res.map(r => {
							posts.push(r);
						})

						data.map(d => {
							let post = posts.filter(p => {
								return +p.id == d.post
							});
							if (post && post.length) {
								this.items.push({
									title: d.title,
									post: post[0]
								})
							}
						})
					}
				})
				.finally(() => {
					this.loading = false;
				})
			}
		},
		select (id) {
			let data = [];
			this.data.map(d => data.push(d));
			data.push({
				title: '',
				post: id
			});
			data = [...new Set(data)];
			this.init(data);
			this.$emit("update", data);
			this.showPostModal = false;
		},
		remove (index) {
			let data = [];
			this.data.map((d, i) => {
				if(index !== i) {
					data.push(d)
				}
			});
			data = [...new Set(data)];
			this.$emit("update", data);
			this.init(data);
		},
		handleInput (i, val) {
			i.title = val;
			let data = [];
			this.data.map(d => {
				if (d.post == i.post.id) {
					d.title = val;
				}
				data.push(d)
			});
			this.$emit("update", data);
		},
		calcuateBattute(i) {
			// return (i && i.title.trim() !== "") ? (i.title.trim().split(" ").length  * 2) : 0
			return (i.title && i.title.trim() !== "") ? i.title.length : 0
		}
	},
	mounted () {
		this.init(this.data);
	},
	props: {
		data: {
			required: true,
			default: null,
		},
		title: {
			type: String,
			default: "Titoli correlati"
		},
		name: {
			type: String,
			default: '',
			required: true
		}
	}
}
</script>

<style lang="scss">

.titoli_correlati_cointainer {
	margin-top: 10px;
	border: 1px solid rgba($primary, 0.2);
	padding: 10px;
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	h2 {
		align-content: center;
		margin-bottom: var(--gutter);
	}

	&__link {
		cursor: pointer;
	}

	&__list {
		display: flex;
		flex-direction: column;
		align-content: center;
		flex-wrap: wrap;
		width: 100%;

		&__item {
			display: flex;
			@extend %flexStartStart;
			width: 100%;
			margin-bottom: var(--gutter);
			input {
				margin: 0 10px;
				height: 30px;
			}
			.new-box {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				.new-title,
				.new-article {
					display: block;
					width: 100%;
				}
				.new-article {
					margin-top: var(--minigutter);
				}
				strong, input, div {
					display: block;
					width: 100%;
				}
				input {
					width: 100%;
					margin: 6px 0;
				}
			}
			strong {
				margin: 0 5px;
			}

			div.battute_titoli_correlati {
				font-size: 12px;
				font-style: italic;
				margin-right: 20px;
			}

			&__title {

			}

			&__trash {
				display: flex;
				@extend %flexCenterCenter;
				@include size(var(--icon),var(--icon));
				svg {
					cursor: pointer;
					fill: $primary;
				}

				&:hover {
					svg {
						fill: $red;
					}
				}
			}
		}
	}
	@media (min-width: $to-medium) {
		&__list {
			&__item {
				.new-box {
					width: calc(100% - var(--icon));
					padding-left: var(--minigutter);
					.new-title {
						display: block;
						width: 50%;
						margin-right: var(--minigutter);
					}
				}
			}
		}
	}
}
</style>
