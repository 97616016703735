<template>
	<form class="form" autocomplete="off" @submit.prevent="submitForm" @change="hasChanged">
		<!-- TABS -->
		<div class="tabs--stage" v-if="tabs">
			<div class="tabs--drag">
				<div class="tabs">
					<div :class="`tab ${(t.key == tabSelected) ? 'selected' : ''}`" v-for="t in tabs" v-bind:key="t.key"
						 @click="tabSelected = t.key; actionTab();">
						{{ t.caption }}
					</div>
				</div>
			</div>
		</div>

		<!-- FORM -->
		<div class="panel panel_post">
			<div v-if="error" class="error error--general-big">
				{{ error }}
			</div>

			<div class="tab_item" v-if="tabSelected == 'generale'">
				<Inpt label="Titolo" name="title" v-model="$v.form.title.$model" :o="$v.form.title" :maxlength="96"
					  :battute="true" :placeholder="`Titolo articolo`" :submitted="submitted"></Inpt>
				<div class="fieldset">
					<label>Data pubblicazione</label>
					<div class="input">
						<Datetime :type="`datetime`" v-model="$v.form.date.$model"></Datetime>
					</div>
				</div>
				<div class="fieldset categorie">
					<label>Categorie</label>
					<div class="categories" v-for="category in categories" :key="category.id">
						<div v-if="category && category.child && category.child.length" class="cats--list">
							<h3 class="categories__title">{{ category.name }}</h3>
							<label v-for="c in category.child" :key="c.id" class="categories__item">
								<input type="checkbox" v-model="$v.form.categories.$model" :value="c.id"/>
								{{ c.name }}
							</label>
						</div>
					</div>
					<div>
						<div class="cats--list">
							<h3 class="categories__title" v-if="form.categories && form.categories.length > 1">
								<strong>Categoria Principale</strong>
							</h3>
							<select v-model="form.others.main_category"
									v-if="form.categories && form.categories.length > 1">
								<option :value="c" v-for="c in form.categories" :key="c">
									{{ getCategoryNameById(c) }}
								</option>
							</select>
						</div>
					</div>
				</div>
				<Inpt label="Autore" name="autore" v-model="$v.form.others.autore.$model" :o="$v.form.others.autore"
					  :placeholder="`Autore`" :submitted="submitted"></Inpt>
			</div>
			<div class="tab_item" v-if="tabSelected == 'testo'">
				<div class="fieldset">
					<ckeditor :editor="editor.editor" v-model="$v.form.content.$model"
							  :config="editor.editorConfig"></ckeditor>
					<div v-if="$v.form.content.$invalid" class="error error--form">
					<span v-for="k in Object.keys($v.form.content.$params)" v-bind:key="k">
						<span v-if="submitted && !$v.form.content[k]">
						   {{ errors[`${name}_${k}`] }}<br>
						</span>
					</span>
					</div>
				</div>
				<div class="fieldset">
					<NewsCorrelate title="Correlati" name="correlati" :data="form.others.correlati"
								   @update="form.others.correlati =$event"></NewsCorrelate>
				</div>
			</div>
			<div class="tab_item" v-if="tabSelected == 'media'">
				<div class="fieldset" style="align-items: flex-start">
					<template v-if="form.others.news_video== false && form.featured_media_url">
						<img style="max-height: 300px" :src="form.featured_media_url" alt="">
					</template>
					<template v-if="form.others.news_video && form.others.video_in_copertina">
						<span class="video-container-nella-galleria-media o-ratio u-16:10">
							<video-player ref="videoPlayer" :options="playerOptions"></video-player>
						</span>
					</template>

					<Inpt label="Didascalia immagine" name="didascalia" v-model="$v.form.others.didascalia.$model"
						  :o="$v.form.others.didascalia" :maxlength="96" :placeholder="`Didascalia immagine`"
						  :submitted="submitted"></Inpt>

					<Btn :loading="loading" :text="'Seleziona media'" text-size="big" button-type="default"
						 :is-full="false" v-on:btn-click="showMediaModal = true" :is-submit-button="false"></Btn>

					<span
						v-if="form.others.news_video && form.others.video_in_copertina && form.others.video_in_copertina.split('/').length > 1"
						style="width: 100%; border: 1px solid #efefef;padding: 10px;">
						<img :src="`${form.others.video_in_copertina}`.replace('playlist.m3u8', 'thumbnail.jpg')"
							 style="width:200px"><br>
						<Btn :loading="loading" :text="'Cambia Thumbnail'" text-size="big" button-type="default"
							 :is-full="false" v-on:btn-click="showChangeThumbnailVideo = true"
							 :is-submit-button="false"></Btn>
						<ChangeVideoThumbnailModal @close="closeChangeThumbVideo($event)"
												   v-if="showChangeThumbnailVideo"
												   :video-id="form.others.video_in_copertina.split('/').reverse()[1]"></ChangeVideoThumbnailModal>
					</span>

					<hr>
					<GalleriaSelection name="galleria" :data="form.others.galleria"
									   @update="form.others.galleria =$event"></GalleriaSelection>
					<hr>
					<AllegatiSelection name="allegati" :data="form.others.allegati"
									   @update="form.others.allegati =$event"></AllegatiSelection>
				</div>

			</div>
			<div class="tab_item" v-if="tabSelected == 'correlati_titolo'">
				<TitoliCorrelati title="Inserisci articoli correlati nel titolo" name="correlati"
								 :data="form.others.correlati_titolo"
								 @update="form.others.correlati_titolo =$event"></TitoliCorrelati>
			</div>
			<div class="tab_item tab_sondaggi" v-if="tabSelected == 'sondaggio'">
				<h2>Seleziona un sondaggio da utilizzare</h2>
				<select v-model="form.sondaggio">
					<option :value="null">Nessun sondaggio</option>
					<option :value="s.slug" v-for="s in sondaggiList" :key="s.slug">
						- {{ service.helpers.decode(s.title).replace(/&#8217;/g, "'") }}
					</option>
				</select>
			</div>
			<div class="tab_item" v-if="tabSelected == 'social'">
				<div class="fieldset">
					<label>Tag articolo</label>
					<VueTagsInput v-model="tag" :tags="tags" :placeholder="`Aggiungi tag articolo`"
								  @tags-changed="tags => updateTags(tags)"/>
					<hr>
					<label style="cursor: pointer">
						<span v-if="form.others.social_status == 'publish'">
							<i class="facebook_color">Questo articolo è già stato pubblicato su Facebook</i><br>
						</span>
						<span v-if="form.others.social_status == 'wait'">
							<i class="facebook_color">Articolo in attesa di pubblicazione su facebook</i><br>
						</span>
						<span v-if="form.others.social_status == 'error'">
							<i class="facebook_color">Errore durante la pubblicazione su facebook</i><br>
						</span>

						<input type="checkbox" v-model="form.others.social.facebook.publish"/>
						Condivisione dell'articolo su <strong>Facebook</strong>
					</label>

					<div class="fieldset" v-if="form.others.social.facebook.publish">

					</div>

					<label class="facebook_panel" v-if="form.others.social.facebook.publish">
						<!--<strong>Titolo: </strong>{{form.others.social.facebook.title}}<br>-->
						<label><strong>Contenuto Facebook</strong></label>
						<div class="input">
							<textarea style="width: 100%; height: 100px; border: 0"
									  v-model="form.others.social.facebook.description"></textarea>
						</div>
						<strong>Pagina: </strong>{{ form.others.social.facebook.tg.join(", ") }}<br>
						<strong>Categoria: </strong>{{ form.others.social.facebook.categoria }}<br>

						<!--<Btn :loading="loading" :text="'Seleziona immagine facebook'" text-size="default" button-type="default"
							 :is-full="false" v-on:btn-click="showMediaModalForFacebook = true" :is-submit-button="false"></Btn>
						<br><strong>Immagine selezionata: </strong><br>
						<img style="max-width: 200px;" v-if="form.others.social.facebook.media" :src="form.others.social.facebook.media">-->


						<!--<br>
						<br>
						<span class="update_facebook_post_button" @click="updatePostForFacebook()">
							Aggiorna dati
						</span>-->
					</label>


					<!--<label style="cursor: pointer">
						<input type="checkbox"/>
						Condivisione dell'articolo su <strong>Twitter</strong>
					</label>-->
				</div>
			</div>
			<div class="tab_item" v-if="tabSelected == 'attribute'">
				<div class="fieldset attributes">
					<label
						:class="`${(form.others.breaking_news_locale ||
						form.others.straordinaria ||
						form.others.straordinaria_verona ||
						form.others.straordinaria_padova ||
						form.others.straordinaria_gialloblu ||
						form.others.straordinaria_biancoscudato ||
						form.others.alta ||
						form.others.alta_verona ||
						form.others.alta_padova ||
						form.others.alta_gialloblu ||
						form.others.alta_biancoscudato) ? 'post_attributes_disabled' : ''}`">
						<input type="checkbox" v-model="form.others.breaking_news"/>
						<span>Breaking news</span>
						<small>Questa news sarà nella schermata iniziale su tutte le home page</small>
					</label>

					<label
						:class="`${(form.others.breaking_news || form.others.straordinaria ||
						form.others.straordinaria_verona ||
						form.others.straordinaria_padova ||
						form.others.straordinaria_gialloblu ||
						form.others.straordinaria_biancoscudato ||
						form.others.alta ||
						form.others.alta_verona ||
						form.others.alta_padova ||
						form.others.alta_gialloblu ||
						form.others.alta_biancoscudato) ? 'post_attributes_disabled' : ''}`">
						<input type="checkbox" v-model="form.others.breaking_news_locale"/>
						<span>Breaking News Locale</span>
						<small>Questa news sarà nella schermata iniziale sulle home delle categorie di
							appartenenza</small>
					</label>

					<!--<label
						:class="`${(form.others.breaking_news_locale || form.others.breaking_news || form.others.alta) ? 'post_attributes_disabled' : ''}`">
						<input type="checkbox" v-model="form.others.straordinaria"/>
						<span>News Straordinaria</span>
						<small>News con layout staordinario</small>
					</label>-->


					<div style="width: 100%; margin-bottom: 30px" :class="`${(form.others.breaking_news_locale || form.others.breaking_news || form.others.alta) ? 'post_attributes_disabled' : ''}`">
						<h3>Straordinaria</h3>
						<label>
							<input type="checkbox" v-model="form.others.straordinaria_verona"/>
							<span>Straordinaria TgVerona</span>
						</label>
						<label>
							<input type="checkbox" v-model="form.others.straordinaria_padova"/>
							<span>Straordinaria TgPadova</span>
						</label>
						<label>
							<input type="checkbox" v-model="form.others.straordinaria_gialloblu"/>
							<span>Straordinaria TgGialloblu</span>
						</label>
						<label>
							<input type="checkbox" v-model="form.others.straordinaria_biancoscudato"/>
							<span>Straordinaria TgBiancoscudato</span>
						</label>
					</div>


					<!--<label
						:class="`${(form.others.breaking_news_locale || form.others.straordinaria || form.others.breaking_news) ? 'post_attributes_disabled' : ''}`">
						<input type="checkbox" v-model="form.others.alta"/>
						<span>News Alta</span>
						<small>News alta, in caso di straordinario</small>
					</label>-->

					<div style="width: 100%" :class="`${(form.others.breaking_news_locale || form.others.breaking_news || form.others.straordinaria) ? 'post_attributes_disabled' : ''}`">
						<h3>Alta</h3>
						<label>
							<input type="checkbox" v-model="form.others.alta_verona"/>
							<span>Alta TgVerona</span>
						</label>
						<label>
							<input type="checkbox" v-model="form.others.alta_padova"/>
							<span>Alta TgPadova</span>
						</label>
						<label>
							<input type="checkbox" v-model="form.others.alta_gialloblu"/>
							<span>Alta TgGialloblu</span>
						</label>
						<label>
							<input type="checkbox" v-model="form.others.alta_biancoscudato"/>
							<span>Alta TgBiancoscudato</span>
						</label>
					</div>


				</div>

				<div v-if="form.others.breaking_news || form.others.breaking_news_locale">
					<NewsCorrelate name="breaking_news_correlate" :data="form.others.breaking_news_correlate"
								   @update="form.others.breaking_news_correlate =$event"></NewsCorrelate>
				</div>
			</div>

			<!-- bottoni -->
			<div class="form-buttons">
				<div></div>
				<span class="next_button" v-if="!loading && tabSelected != 'attribute' && form.id == null"
					  @click="tabSelected = nextTab()">
					Avanti
				</span>
				<span class="next_button" v-if="!loading && (tabSelected == 'attribute' || form.id != null)"
					  @click="saveDraft()">
					Salva come bozza
				</span>
				<Btn v-if="tabSelected == 'attribute' || form.id != null" :loading="loading" :text="'Pubblica'"
					 text-size="big" button-type="primary" :is-full="false" :is-submit-button="true"></Btn>
			</div>
		</div>

		<!-- MODALS -->
		<SelectMediaModal v-if="showMediaModal" @close="selectMedia($event)"></SelectMediaModal>
		<SelectMediaModal :onlyimage="true" v-if="showMediaModalForFacebook"
						  @close="selectMediaForFacebook($event)"></SelectMediaModal>
	</form>
</template>
<script>
import {required} from 'vuelidate/lib/validators';
import Btn from "../common/forms/Btn";
import Inpt from "../common/forms/Inpt";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Datetime} from 'vue-datetime'
import SelectMediaModal from "../common/modals/SelectMediaModal";
import VueTagsInput from '@johmun/vue-tags-input';
import {videoPlayer} from 'vue-videojs7';
import NewsCorrelate from "@components/common/forms/NewsCorrelate";
import TitoliCorrelati from "@components/common/forms/TitoliCorrelati";
import GalleriaSelection from "@components/common/forms/GalleriaSelection";
import ChangeVideoThumbnailModal from "@components/common/modals/ChangeVideoThumbnailModal";
import AllegatiSelection from "../common/forms/AllegatiSelection";

export default {
	name: 'PostForm',
	components: {
		AllegatiSelection,
		ChangeVideoThumbnailModal,
		GalleriaSelection,
		TitoliCorrelati,
		NewsCorrelate,
		SelectMediaModal,
		Inpt,
		Btn,
		Datetime,
		VueTagsInput,
		videoPlayer
	},
	props: {
		// l'oggetto di base
		base: {
			default: null,
			required: false
		},
		// l'elenco delle categorie
		categories: {
			default: [],
			required: false
		},
		// l'elenco dei sondaggi
		sondaggiList: {
			default: [],
			required: false
		}
	},
	computed: {
		/**
		 * il player video
		 */
		player () {
			return this.$refs.videoPlayer.player;
		}
	},
	watch: {
		'form.others.social.facebook.publish': {
			immediate: false,
			deep: false,
			handler () {
				this.updatePostForFacebook();
			}
		},
		'form.categories': {
			immediate: false,
			deep: false,
			handler () {
				if (!(this.form.categories.indexOf(this.form.others.main_category) > 0)) {
					this.form.others.main_category = this.form.categories[0];
				}
				if (this.form.categories.length == 0) {
					this.form.others.main_category = null
				}
			}
		}
	},
	data () {
		return {
			// opzioni del player
			playerOptions: {
				autoplay: false,
				controls: true,
				controlBar: {
					timeDivider: false,
					durationDisplay: false
				}
			},
			// indica se il form è stato spedito
			submitted: false,
			// errori della form
			error: '',
			loading: false,
			// indica se è aperta l'interfaccia di selezione del media
			showMediaModal: false,
			showMediaModalForFacebook: false,
			showChangeThumbnailVideo: false,
			// optioni ckeditor
			editor: {
				editor: ClassicEditor,
				editorConfig: {
					width: "100%",
					toolbar: ['heading', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'link', '|', 'undo', 'redo'],
					link: {
						decorators: [
							{
								mode: 'manual',
								label: 'Apri in una nuova finestra',
								attributes: {
									target: '_blank'
								}
							}
						]
					}
				}
			},
			// gestione dei tab
			tabSelected: 'generale',
			tabs: [
				{key: 'generale', caption: "Generale"},
				{key: 'testo', caption: "Contenuto"},
				{key: 'media', caption: "Media"},
				{key: 'correlati_titolo', caption: "Correlati nel titolo"},
				{key: 'sondaggio', caption: "Sondaggio"},
				{key: 'social', caption: "Tags e Social"},
				{key: 'attribute', caption: "Attributi"},
			],
			// gestione dei tags
			tags: [],
			tag: "",
			tags2: [],
			tag2: "",
			// oggetto form
			form: {
				id: null,
				title: '',
				date: null,
				content: '',
				categories: [],
				tags: [],
				featured_media: null,
				featured_media_url: null,
				sondaggio: null,
				others: {
					breaking_news: false,
					breaking_news_locale: false,
					straordinaria: false,
					straordinaria_verona: false,
					straordinaria_padova: false,
					straordinaria_gialloblu: false,
					straordinaria_biancoscudato: false,
					alta: false,
					alta_verona: false,
					alta_padova: false,
					alta_gialloblu: false,
					alta_biancoscudato: false,
					autore: '',
					news_video: false,
					video_in_copertina: '',
					didascalia: '',
					articoli_correlati_titolo: [],
					galleria: [],
					allegati: [],
					social: null,
					social_status: null,
					main_category: null
				},
				eliminare: {
					title: '',
					description: ''
				}
			},
			show_preview: false
		}
	},
	/**
	 * Validatori Form
	 */
	validations: {
		form: {
			id: {},
			title: {
				required
			},
			date: {},
			content: {
				required
			},
			featured_media: {},
			featured_media_url: {},
			categories: {
				required
			},
			tags: {},
			sondaggio: {},
			others: {
				breaking_news: {},
				breaking_news_locale: {},
				straordinaria: {},
				straordinaria_verona: {},
				straordinaria_padova: {},
				straordinaria_gialloblu: {},
				straordinaria_biancoscudato: {},
				alta: {},
				alta_verona: {},
				alta_padova: {},
				alta_gialloblu: {},
				alta_biancoscudato: {},
				autore: {},
				news_video: {},
				video_in_copertina: {},
				didascalia: {},
				articoli_correlati_titolo: {},
				galleria: {},
				allegati: {},
				social: {},
				social_status: '',
			},
			eliminare: {
				title: {},
				description: {}
			}
		}
	},
	mounted () {
		this.init();
	},
	methods: {
		getSocial(socials) {
			let s = ``;
			if(socials && socials.length) {
				socials.map((item, index) => {
					if(item !== "telenuovo") {
						socials[index] = `Tg${socials[index].charAt(0).toUpperCase() + socials[index].slice(1)}`;
					} else {
						socials[index] = `${socials[index].charAt(0).toUpperCase() + socials[index].slice(1)}`;
					}
				})
				s = `${socials.join(", ")}`
			}
			return s;
		},
		updatePostForFacebook () {
			// console.log(`[PostForm][updatePostForFacebook] Controllo`, this.form)
			if (this && this.form && this.form.others && this.form.others.social && this.form.others.social.facebook) {
				if (this.form.others.social.facebook.publish) {
					this.form.others.social.facebook.publish_date = this.$moment().unix()
					this.form.others.social.facebook.title = this.form.title
					let description = `${this.form.content || ""}`.replace(/<[^>]*>/g, '');
					description = description.split(".");

					if (this.form.tags && this.form.tags.length) {
						let tags = [];
						this.form.tags.map(t => {
							tags.push(`#${t}`);
						});
						//description[0] = `${description[0]}\n${tags.join(" ")}`;
					}
					// this.form.others.social.facebook.description = description[0];

					if (this.categories && this.categories.length) {
						let pages = [];
						this.categories.map(cat => {
							cat.child.map(catChild => {
								if (this.form.categories.includes(catChild.id)) {
									pages.push(cat.slug.replace("tg", ""));
								}
							})
						});
						pages = [...new Set(pages)];
						this.form.others.social.facebook.tg = pages;
						// main category
						let mc = this.form.others.main_category;
						if (mc) {
							this.form.others.social.facebook.categoria = '';
							this.categories.map(category => {
								if (category && category.child) {
									category.child.map(c => {
										let categories = [];
										`${c.slug}`.split(`-`).map(n => {
											if (!(+n)) {
												categories.push(n.trim());
											}
										})
										let slug = categories.join('-');
										console.log(`ID: [${mc}] ${c.id}, Name: ${c.name}, Slug: ${slug} (${c.slug})`);
										if (+mc == +c.id) {
											this.form.others.social.facebook.categoria = slug;
										}
									})
								}
							})
						}

					}
					// console.log(`[PostForm][updatePostForFacebook] Sistemo gli elementi`, this.form.others.social.facebook)
				} else {
					// console.log(`[PostForm][updatePostForFacebook] Svuoto`)
					this.form.others.social.facebook.publish_date = null;
					this.form.others.social.facebook.title = '';
					this.form.others.social.facebook.description = '';
					this.form.others.social.facebook.media = '';
					this.form.others.social.facebook.categoria = '';
					this.form.others.social.facebook.tg = null;
				}
			}
		},
		selectMediaForFacebook (media) {
			this.form.others.social.facebook.media = media.url;
			this.showMediaModalForFacebook = false;
		},
		/**
		 * Gestione del player video
		 */
		playVideo: function (src) {
			// in caso di localhost allora prendo un video HLS di prova
			// if (window.location.href.indexOf("http://localhost") >= 0) {
			// 	src = 'https://mediastream1.telenuovo.it/video//2020/12/28/8b0e9ea5-583d-4fb3-ac57-1c2e31872088/playlist.m3u8'
			// }
			const video = {
				withCredentials: false,
				type: 'application/x-mpegurl',
				src: src
			}
			this.player.reset();
			this.player.src(video)
		},
		getCategoryNameById (id) {
			let tmpData = ``;
			this.categories.map(fc => {
				if (fc && fc.child && fc.child.length) {
					let r = fc.child.filter(c => {
						return +c.id == +id
					});
					if (r && r.length > 0) {
						tmpData = `${fc.name} - ${r[0].name}`;
					}
				}
			});
			return tmpData;
		},
		/**
		 * Inizializzo la pagina
		 */
		init () {
			if (this.base !== null) {
				this.tags = [];

				Object.keys(this.form).map(k => {
					if (this.base[k]) {
						this.form[k] = this.base[k];
					}
					if (k == "tags") {
						this.form[k].map(t => {
							this.tags.push({text: t});
						})
					}
				});
				setTimeout(() => {
					this.form.others.social.facebook.publish = false;
				}, 500)

				// se non esiste la main category, associo la prima
				if (this.form.others.main_category == null) {
					this.form.others.main_category = this.form.categories[0];
				}
			}
		},
		/**
		 * Quando la form cambia, viene chiamato questo metodo che emette al componente
		 * padre la modifica
		 */
		hasChanged () {
			this.$emit('change', !this.submitted);
		},
		/**
		 * Quando viene selezionata una tab, controllo la tab ed eseguo
		 * delle operazioni
		 */
		actionTab () {
			if (this.tabSelected == "media") {
				// in caso di media e video inizializzo il player video
				if (this.form && this.form.others.news_video && this.form.others.video_in_copertina) {
					setTimeout(() => {
						this.playVideo(this.form.others.video_in_copertina);
					}, 300)
				}
			}

			//
			// eliminare
			if (this.tabSelected == "preview") {
				this.show_preview = false;
				setTimeout(() => {
					let data = this.$refs["preview_iframe"];
					console.log(data);
					//contentWindow.document.getElementsByClassName("article-gallery").style.display = 'none';
					this.show_preview = true;
				}, 2000)
			}
		},
		/**
		 * Tab successiva,
		 * @returns {string} il codice della tab successiva
		 */
		nextTab () {
			let next = "";
			this.tabs.map((t, index) => {
				if (this.tabSelected == t.key) {
					next = this.tabs[index + 1].key;
				}
				this.actionTab();
			})
			return next;
		},
		/**
		 * Aggiorno i tags del componente nella form
		 * @param tags
		 */
		updateTags (tags) {
			this.form.tags = [];
			tags.map(t => {
				this.form.tags.push(t.text);
			})
		},
		updateTags2 (tags) {
			this.form.tags2 = [];
			tags.map(t => {
				this.form.tags2.push(t.text);
			})
		},
		/**
		 * Imposto il media selezionato nella form
		 * @param media
		 */
		selectMedia (media) {
			// inizializzo le variabili
			this.showMediaModal = false;
			this.form.featured_media = null;
			this.form.featured_media_url = null;
			this.form.others.news_video = false;
			this.form.others.video_in_copertina = '';

			this.form.others.didascalia = `${this.service.helpers.decode(media.title) || ``}`;

			if (media && media.media_type == 'image') {
				// se si tratta di un'immagine
				this.form.featured_media = media.id;
				this.form.featured_media_url = media.url;
			} else {
				// se si tratta di un video
				this.form.others.news_video = true;
				this.form.others.video_in_copertina = media.url;
				setTimeout(() => {
					this.playVideo(this.form.others.video_in_copertina);
				}, 300)
			}
		},
		/**
		 * Chisura della form
		 */
		close () {
			this.$emit('close');
			this.submitted = false;
		},
		/**
		 * Salvataggio del post come bozza
		 */
		saveDraft () {
			this.form.status = 'draft';
			this.submitForm();
		},
		closeChangeThumbVideo (success) {
			let url = this.form.others.video_in_copertina.split("?")[0];
			this.form.others.video_in_copertina = `${url}?cache=${this.$moment().unix()}`;
			this.showChangeThumbnailVideo = false;
		},
		onInitialized (editor) {
		},
		/**
		 * Salvataggio della form
		 */
		submitForm () {
			this.$v.form.$touch();
			this.submitted = true; // setto come inviato
			this.hasChanged(); // blocco l'emissione del form cambiato (non serve)
			this.error = '';
			this.loading = true;
			if (!this.$v.form.$invalid) {
				let f = {...this.form};
				f.tags = (f.tags && f.tags.length) ? f.tags : [];

				// console.log(`[PostForm][submitForm] Inizio il salvataggio`, f)

				if (f.sondaggio && f.sondaggio !== "") {
					let sondaggio_content = `<!-- wp:mwd/sondaggio-block {"sondaggio_slug":"${f.sondaggio}"} --><sondaggio class="wp-block-mwd-sondaggio-block sondaggio" slug="${f.sondaggio}"></sondaggio><!-- /wp:mwd/sondaggio-block -->`;
					f.content = `${f.content} ${sondaggio_content}`
				}

				let ct = [];
				if (f.others.correlati_titolo && f.others.correlati_titolo && f.others.correlati_titolo.length) {
					f.others.correlati_titolo.map(c => {
						if (c && c.title && c.title.trim() !== '') {
							ct.push(c);
						}
					})
				}
				f.others.correlati_titolo = ct;

				//
				// Prendo i tags e salvo quelli nuovi
				// WP vuole gli id dei tags
				//
				this.service.post.tags(f.tags)
					.then((tags) => {
						if (tags && tags.success) {
							f.tags = tags.tags.ids;
						} else {
							f.tags = [];
						}
						// console.log(`[PostForm][submitForm] Definizione dei tags`, f)
						// Salvo il post
						this.service.post.save(f)
							.then(() => {
								// console.log(`[PostForm][submitForm] Contenuto salvato`, f)
								this.$emit('save');
								this.submitted = false;
							})
							.catch((err) => {
								if (err && err.messages && err.messages[0]) {
									let errorMessage = this.errors[err.messages[0]];
									this.error = this.errorMessage;
									this.submitted = false;
								}
							})
							.finally(() => {
								this.loading = false;
							})
					})
			} else {
				this.loading = false;
				this.flashMessage.show({
					status: 'error',
					title: 'Mancano dati',
					message: 'Per salvare è necessario il titolo, il contenuto e almeno una categoria'
				});
			}
		}

	}
}
</script>
<style lang="scss">
.video-container-nella-galleria-media {
	display: block;
	width: 100%;
	max-width: 400px;
	position: relative;

	& > div {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		@include size(100%, 100%);
		object-fit: cover;

		.video-js {
			display: block;
			@include size(100% !important, 100% !important);
			object-fit: cover;
		}
	}
}

.form-buttons {
	width: 100%;
	display: flex;
	justify-content: space-between !important;
	margin-top: 30px;
}

.ck-editor {
	width: 100% !important;

	&__editable {
		min-height: 400px;
	}
}

.fieldset {
	.error {
		margin-bottom: 15px;
	}
}

.panel_post {
	width: 100%;
}

.tab_sondaggi {
	select {
		padding: 10px;
	}
}

.next_button {
	margin-top: 20px;
	cursor: pointer;

	font-size: 1em;
	text-transform: uppercase;
	text-align: center;

	background: $medium_grey;

	border-radius: calc(var(--radius) / 2.5);
	border: 0;
	color: #fff;
	font-weight: 600;
	padding: 12px 12px;
	margin: 10px 0;
	min-width: 70px;
	opacity: 1;
	transition: 0.3s;
}

.update_facebook_post_button {
	margin-top: 20px;
	cursor: pointer;
	font-size: 0.7em;
	text-transform: uppercase;
	text-align: center;

	background: $medium_grey;

	border-radius: calc(var(--radius) / 2.5);
	border: 0;
	color: #fff;
	font-weight: 600;
	padding: 2px 7px;
	margin: 10px 0;
	min-width: 70px;
	opacity: 1;
	transition: 0.3s;
}

.categories {

	display: block;
	width: 19%;
	float: left;

	&__title {
		font-size: 14px;
		color: $primary;
		margin: 20px 0 20px 0;
	}

	&__item {
		cursor: pointer;
		display: block;
		width: 100%;
		margin: 6px 0;
		padding-left: 0px;
	}
}

.vue-tags-input {
	width: 100% !important;
	max-width: none !important;
	margin-top: 15px;

	.ti-input {
		width: 100% !important;
	}
}

.attributes {
	label {
		cursor: pointer;

		span {
			padding-left: 2px;
		}

		small {
			display: block;
			padding-left: 14px;
			padding-bottom: 20px;
			width: 90%;
		}
	}
}

.post_attributes_disabled {
	color: grey;
	pointer-events: none;
	display: none !important;
}

.facebook_panel {
	border: 1px solid #efefef;
	background: #efefef;
	padding: 10px;
}

.ck-content {
	p {
		font-size: 19px !important; // kekko non mi odiare!
		margin: 12px auto;
		line-height: 22px !important;
	}

	h2 {
		font-size: 28px !important; // kekko non mi odiare!
		margin: 24px auto;
		line-height: 28px !important;
	}

	h3 {
		font-size: 24px !important; // kekko non mi odiare!
		margin: 24px auto;
		line-height: 24px !important;
	}

	h4 {
		font-size: 22px !important; // kekko non mi odiare!
		margin: 24px auto;
		line-height: 22px !important;
	}
}

#vue-editor-js {
	width: 100% !important;

	.ce-block__content {
		border: 1px solid #efefef;
		padding: 5px;
	}
}

//
</style>
