<template>
	<div id="speciali_list" class="page main-content">
		<div class="page--head">
			<div class="head--title">
				<PageTitle :title_translation="false" :title="`Speciali`" :subtitle="`Gestione degli articoli speciali`"></PageTitle>
				<span class="floating">
					<Btn @btn-click="go('new')" text-size="small" button-type="primary" text="Aggiungi"></Btn>
				</span>
			</div>
			<!-- ricerca -->
			<div class="ricerca">
				<div :class="`post_list_actions`">
					<div class="post_list_actions__search_container">
						<div class="field">
							<select @change="isSearch = true; refresh();" :disabled="loading" v-model="search_category"
									name="post_list_search_category" id="post_list_search_category">
								<option :value="null">Tutte le categorie</option>
								<option :value="cat.id" v-for="cat in categories" :key="cat.id">
									{{ cat.title }}
								</option>
							</select>
						</div>
						<div class="field">
							<select @change="isSearch = true; page=1; refresh();" :disabled="loading"
									v-model="search_attributes" name="post_list_search_acf" id="post_list_search_acf">
								<option :value="null">Tutte le priorità</option>
								<option :value="`bloccata`">Notizia Bloccata</option>
								<option :value="`inevidenza`">Notizia in evidenza</option>
							</select>
						</div>
						<div class="field">
							<input :disabled="loading" class="post_list_search" id="post_list_search" v-model="search"
								   @keydown.13="searchHandler($event)" :placeholder="'Cerca'">
						</div>
						<div class="field">
							<Datetime :disabled="loading" :format="`dd/MM/yyyy`" :placeholder="`Data articolo`"
									  :type="`date`" v-model="search_date"></Datetime>
							<div v-if="search_date" class="remove_date_time" @click="search_date = null">
								<Icon icon="trash"></Icon>
							</div>
						</div>
						<div class="ricerca--controls">
							<div class="send_search_container control">
								<div class="send_search" @click="isSearch = true; page=1; refresh();">
									<Icon icon="search"></Icon>
								</div>
							</div>

							<div v-if="isSearch" class="trash_all_container control">
								<div class="trash_all" @click="isSearch = false; initSearch(); refresh();">
									<Icon icon="cross"></Icon>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="page--content">
			<!-- tabs -->
			<div class="tabs">
				<div :class="`tab ${('publish' == postTypeSelected) ? 'selected' : ''}`"
					 @click="postTypeSelected = 'publish'; refresh()">
					Pubblicati
				</div>
				<div :class="`tab ${('draft' == postTypeSelected) ? 'selected' : ''}`"
					 @click="postTypeSelected = 'draft'; refresh()">
					Bozze
				</div>
				<div :class="`tab ${('future' == postTypeSelected) ? 'selected' : ''}`"
					 @click="postTypeSelected = 'future'; refresh()">
					Futuri
				</div>
			</div>

			<!-- lista -->
			<div class="panel">
				<LoadingSpinner v-if="loading" :small="true"></LoadingSpinner>
				<div class="table" v-if="!loading && items.length">
					<div class="table--head">
						<span class="table--head__col long">Articolo</span>
						<span class="table--head__col">Data</span>
						<span class="table--head__col">Categoria</span>
						<span class="table--head__col">In Evidenza</span>
						<span class="table--head__col">Bloccata</span>
						<span class="table--head__col"></span>
					</div>
					<div class="table--body">
						<div v-for="i in items" v-bind:key="i.id" class="table--row">
			                <span class="table--body__col long article">
			                    <span @click="go(i.id)" class="post__title">
									<span v-html="i.title"></span>
								</span>
								<small class="post__autore">
									di {{ i.autore }}
									<span v-if="i.social_status == 'publish'">
										<br><i class="facebook_color">Pubblicato su facebook
										 su <span v-if="i && i.social && i.social.facebook && i.social.facebook.tg && i.social.facebook.tg.length">
										  {{ i.social.facebook.tg.join(', ') }}
										</span></i>
									</span>
									<span v-if="i.social_status == 'wait'">
										<br><i class="facebook_color">In attesa di pubblicazione su facebook
										 su <span v-if="i && i.social && i.social.facebook && i.social.facebook.tg && i.social.facebook.tg.length">
										  {{ i.social.facebook.tg.join(', ') }}</span></i>
									</span>
									<span v-if="i.social_status == 'error'">
										<br><i class="facebook_color">Errore durante la pubblicazione su facebook</i>
									</span>
								</small>
								<small style="cursor: pointer" v-if="service.utils.getSpecialeUrl(i, getCategorySlug(i.speciali_categorie_id))">
									<a :href="service.utils.getSpecialeUrl(i, getCategorySlug(i.speciali_categorie_id))" target="_blank" style="color: grey">
										{{ service.utils.getSpecialeUrl(i, getCategorySlug(i.speciali_categorie_id)) }}
									</a>
									<div @click="copyLink(service.utils.getSpecialeUrl(i, getCategorySlug(i.speciali_categorie_id)))">[<strong>Copia url negli appunti</strong>]</div>
								</small>
			                </span>
							<span class="table--body__col date">
			                    {{ i.date | datatime }}
			                </span>
							<span class="table--body__col cats">
								<span class="post__categories" v-html="getCategories(i.speciali_categorie_id)"></span>
							</span>

							<!-- edit = false -->
							<span v-if="!i.__extra.isShow" class="table--body__col table--body__col__center icon">
								<Icon icon="checked" v-if="i.__extra.in_evidenza"></Icon>
							</span>
							<span v-if="i.__extra.isShow && !i.__extra.isLoading" class="table--body__col">
								<label style="cursor: pointer">
									<input type="checkbox" v-model="i.__extra.in_evidenza" style="margin-right: 10px">
									In evidenza
								</label>
							</span>
							<span v-if="!i.__extra.isShow" class="table--body__col">
								<span v-if="i.__extra.notizia_bloccata > 0">
									{{i.__extra.notizia_bloccata}}
								</span>
								<span v-if="i.__extra.notizia_bloccata <= 0">
									-
								</span>
							</span>
							<!-- edit = true -->
							<span v-if="!i.__extra.isShow" class="table--body__col">
								<div v-if="!i.__extra.isShow && !i.__extra.isLoading" @click="i.__extra.isShow = true" style="cursor: pointer; font-weight: 700; width: 100%; color: #183351; font-size: 12px">
									Cambia priorità
								</div>
							</span>
							<span v-if="i.__extra.isShow && !i.__extra.isLoading" class="table--body__col">
								<input type="number" style="width: 50px" size="4" v-model="i.__extra.notizia_bloccata">
							</span>
							<span v-if="i.__extra.isShow && !i.__extra.isLoading" class="table--body__col">
								<button type="button" @click="changeStatus(i)">Aggiorna</button>
							</span>

							<div class="table--body__col" v-if="i.__extra.isLoading">
							</div>
							<div class="table--body__col" v-if="i.__extra.isLoading">
							</div>
							<div class="table--body__col" v-if="i.__extra.isLoading">
								Attendere...
							</div>

						</div>
					</div>
				</div>

				<div v-if="!loading && items.length == 0" class="no-posts">
					Nessun elemento da visualizzare
				</div>

				<!-- paginazione -->
				<PaginationSimple v-if="!loading" :page="page" :page_end="pageEnd"
								  v-on:page="changePage($event)"></PaginationSimple>
			</div>
		</div>
	</div>
</template>

<script>
import PageTitle from "../../components/common/PageTitle";
import LoadingSpinner from "../../components/common/Loading";
import Btn from "../../components/common/forms/Btn";
import PaginationSimple from "../../components/common/forms/PaginationSimple";
import Icon from "../../components/common/forms/Icon";
import {Datetime} from 'vue-datetime'

export default {
	name: 'SpecialiList',
	components: {
		Icon,
		PaginationSimple,
		Btn,
		LoadingSpinner,
		PageTitle,
		Datetime
	},
	data () {
		return {
			loading: false,
			items: [],
			page: 1,
			pageEnd: false,
			search: '',
			search_category: null,
			search_attributes: null,
			search_date: null,
			categories: [],
			sort: null,
			postTypeSelected: 'publish',
			isSearch: false
		}
	},
	watch: {
		search_date: {
			immediate: true,
			handler () {
				this.isSearch = true;
				this.refresh();
				this.page = 1;
			}
		}
	},
	methods: {
		initSearch () {
			this.search = '';
			this.search_category = null;
			this.search_attributes = null;
			this.search_date = null;
			localStorage.removeItem("speciali_search")
		},
		copyLink (url) {
			let tobecopied = document.createElement('input');
			tobecopied.classList.add('tobecopied')
			tobecopied.value = url;
			document.body.appendChild(tobecopied);
			tobecopied.focus();
			tobecopied.select();
			document.execCommand('copy');
			//alert("Link copiato correttamente");
			this.flashMessage.show({
				status: 'success',
				title: 'Link',
				message: "Link copiato correttamente"
			});
		},
		refresh () {
			this.loading = true;
			this.items = [];
			if (this && this.service && this.service.speciali_categorie) {
				this.service.speciali_categorie.all()
					.then(categories => {
						this.categories = [];
						categories.map(c => {
							if(c.attivo) {
								this.categories.push(c);
							}
						})

						let search_date = null;
						if (this.search_date && this.search_date !== "" && this.search_date.indexOf('T') >= 0) {
							search_date = this.search_date.split("T")[0];
						}

						localStorage.setItem("speciali_search", JSON.stringify({
							search: this.search,
							search_category: this.search_category,
							search_attributes: this.search_attributes,
							search_date: this.search_date
						}));

						let service = this.service.speciali.list;
						service(this.page, this.sort, this.postTypeSelected, this.search, this.search_category, this.search_attributes, search_date)
							.then(items => {
								items.map(i => {
									i.__extra = {
										in_evidenza: i.in_evidenza,
										notizia_bloccata: i.notizia_bloccata,
										isShow: false,
										isLoading: false
									}
								})
								this.items = items;
							})
							.finally(() => {
								this.loading = false;
							})
					})
					.catch(() => {
						this.loading = false;
					})
			}
		},
		changePage (page) {
			this.page = page;
			this.refresh();
		},
		changeStatus(i) {
			i.__extra.isLoading = true;

			let f = {...i};
			delete f.__extra;

			f.content = f.content.replace(/<!-- wp:paragraph -->/g, '');
			f.content = f.content.replace(/<!-- \/wp:paragraph -->/g, '');
			let uContent = f.content.split("<!-- wp:mwd/sondaggio-block");
			f.content = uContent[0];
			if (uContent && uContent.length && uContent[1]) {
				// esiste un sondaggio
				let d = uContent[1].match(/([a-zA-Z-_])+/gi);
				if (d && d.length > 1 && d[0] == "sondaggio_slug") {
					f.sondaggio = d[1].trim()
				}
			}
			f.date = f.date ? this.$moment(f.date * 1000).tz("Europe/Rome").format() : this.$moment().tz("Europe/Rome").format();
			f.featured_media = f.media_id;
			f.featured_media_url = f.media;
			f.others = {
				in_evidenza: i.__extra.in_evidenza || false,
				notizia_bloccata: i.__extra.notizia_bloccata || 0,
				autore: (f.autore && f.autore !== 'La Redazione') ? f.autore : '',
				news_video: f.media_video || false,
				video_in_copertina: (f.media_video && f.media) ? f.media : '',
				didascalia: (f.didascalia) ? f.didascalia : '',
				main_category: f.main_category || null,
				speciali_categorie_id: f.speciali_categorie_id || null,
				galleria: f.galleria || [],
				social: i.social || {
					facebook: {
						publish: false,
						publish_date: null,
						title: '',
						description: '',
						media: '',
						categoria: '',
						tg: []
					}
				}
			}
			f.others.notizia_bloccata = (f.others.notizia_bloccata !== "" && +f.others.notizia_bloccata) ? +f.others.notizia_bloccata : 0
			this.service.speciali.save(f)
				.then(() => {
					this.$emit('save');
				})
				.catch((err) => {
					if (err && err.messages && err.messages[0]) {
						let errorMessage = this.errors[err.messages[0]];
						this.error = this.errorMessage;
					}
				})
				.finally(() => {
					i.__extra.isShow = false;
					i.__extra.isLoading = false;
				})
		},
		searchHandler (event) {
			if (event) event.preventDefault();
			if (event) event.stopPropagation();
			this.isSearch = true;
			this.refresh();
			this.page = 1;
		},
		getCategories (id) {
			let result = [];
			result = this.categories.filter((f) => {
				return f.id == id
			})
			return (result && result.length) ? `${result[0].title}` : ``;
		},
		getCategorySlug (id) {
			let result = [];
			result = this.categories.filter((f) => {
				return f.id == id
			})
			return (result && result.length) ? `${result[0].slug}` : ``;
		},
		go (id) {
			this.$router.push({name: 'specialidetail.page', params: {id: id}})
		},
	},
	mounted () {
		// se sono stati settati dei filtri li aggiorno
		let sFilters = localStorage.getItem("speciali_search") || null;
		if (sFilters) {
			sFilters = JSON.parse(sFilters);
			this.search = sFilters.search || "",
			this.search_category = sFilters.search_category || null,
			this.search_attributes = sFilters.search_attributes || null,
			this.search_date = sFilters.search_date || null
		}
		this.refresh()
	}
}
</script>

<style lang="scss">
@import '@/assets/scss/pages/_specialilist';
@import '@/assets/scss/components/_ricercaposts';
</style>
