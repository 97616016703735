<template>
	<div id="post_list" :class="'main-content'" class="page">
		<div class="page--head">
			<div class="head--title">
				<PageTitle :title_translation="false" :title="`Status Media`" :subtitle="``"></PageTitle>
			</div>
		</div>
		<div class="page--content">
			<!-- lista -->
			<div class="panel">
				<LoadingSpinner v-if="loading" :small="true"></LoadingSpinner>
				<div class="table" v-if="!loading && items.length">
					<div class="table--head">
						<span class="table--head__col long">Video</span>
						<span class="table--head__col">Data</span>
					</div>
					<div class="table--body">
						<div v-for="i in items" v-bind:key="i.id" class="table--row">
			                <span class="table--body__col long article">
			                    <span class="post__title">
									{{ i.title }}
								</span>
			                    <small class="post__autore">
									Codice: {{ i.id }}<br>
									Stato: <a style="color: black; font-weight: bold" :href="`https://mediaserver.telenuovo.it/production/media-video-uploader/videos/${i.id}`" target="_blank">Vedi lo stato</a>
									<!--Url: {{ i.url }}-->
									<br><br>
									<span v-if="!i.loading_status" :style="`color: ${(i.status_error) ? 'red' : 'green'}`">
										<span v-html="i.status_message"></span>
									</span>
									<span v-if="i.loading_status">
										Caricamento delle informazioni...
									</span>
								</small>
			                </span>
							<span class="table--body__col date">
			                    {{ i.date | datatime }}
			                </span>
							<span class="table--body__col date">
			                    <button class="btn btn_red btn-mini" @click="deleteMedia(i.id)">Elimina</button>
			                </span>
						</div>
					</div>
				</div>

				<div v-if="!loading && items.length == 0" class="no-posts">
					Nessun elemento da visualizzare
				</div>

				<!-- paginazione
				<PaginationSimple v-if="!loading" :page="page" :page_end="pageEnd"
								  v-on:page="changePage($event)"></PaginationSimple>-->
			</div>
		</div>
	</div>
</template>

<script>
import PageTitle from "@components/common/PageTitle";
import LoadingSpinner from "@components/common/Loading";
import moment from "../../../plugins/moment.plugin";
// import PaginationSimple from "@components/common/forms/PaginationSimple";

export default {
	name: 'MediaWebStatusList',
	components: {
		// PaginationSimple,
		LoadingSpinner,
		PageTitle
	},
	data () {
		return {
			loading: false,
			items: [],
			page: 1,
			pageEnd: false
		}
	},
	methods: {
		refresh () {
			this.loading = true;
			this.items = [];
			let promises = [];
			this.service.media.list_working(this.page, null, null, null, 'video', "working")
				.then(items => {
					items.map(i => {
						i.loading_status = true;
						i.status_error = true;
						this.items.push(i);
						setTimeout(() => { this.getStatus(i); }, 10);
					})
				})
				.finally(() => {
					this.loading = false;
				})
		},
		getStatus(item) {
			this.$api.getUrlComplete(`https://mediaserver.telenuovo.it/production/media-video-uploader/videos/${item.id}`)
				.then(status => {
					if(status && status.errors == null) {
						console.log(status);
						item.status_error = false;
						item.status_message = `
							<strong>Codice video:</strong><i>${status.id}</i>
							<br><strong>Scaricato: </strong> ${moment.moment(+status.downloadedOn).format('DD/MM/YY - HH:mm')}
							<br><strong>Fine conversione: </strong> ${(status.conversionEnd !== null) ? moment.moment(+status.conversionEnd).format('DD/MM/YY - HH:mm') : '<strong style="color: orange">Conversione in corso...</strong>'}
							<br><strong>Pubblicato: </strong> ${(status.publishedOn !== null) ? moment.moment(+status.publishedOn).format('DD/MM/YY - HH:mm') : '<strong style="color: orange">In attesa di pubblicazione</strong>'}
						`;
					} else {
						item.status_message = status.errors;
					}
				})
				.catch(() => {
					item.status_message = "Errore durante il caricamento delle informazioni.";
				})
				.finally(() => {
					item.loading_status = false;
				});
		},
		deleteMedia(id){
			if(confirm("Eliminare questa risorsa?")) {
				this.service.media.delete(id)
					.then(() => {})
					.finally(() => {
						this.refresh();
					})
			}
		}
	},
	mounted () {
		this.refresh()
	}
}
</script>

<style lang="scss">
</style>
