<template>
	<div class="form">
		<form class="form" autocomplete="off" @submit.prevent="submitForm">
			<div v-if="error" class="error error--general-big">
				{{ error }}
			</div>
			<Inpt label="users.form.name" name="name" v-model="$v.form.name.$model" :o="$v.form.name"
				  :submitted="submitted"></Inpt>
			<Inpt label="users.form.email" :disabled="form.id !== '' && form.id !== null" name="data_email"
				  v-model="$v.form.email.$model" :o="$v.form.email" :submitted="submitted"></Inpt>
			<Inpt label="users.form.password" :is-password="true" name="data_password" v-model="$v.form.password.$model"
				  :o="$v.form.password" :submitted="submitted"></Inpt>
			<Inpt label="users.form.repeat" :is-password="true" name="repeat" v-model="$v.form.repeat.$model"
				  :o="$v.form.repeat" :submitted="submitted"></Inpt>
			<Slc :values="roles" label="users.form.role" name="role" v-model="$v.form.role.$model" :o="$v.form.role"
				 :submitted="submitted"></Slc>
			<Slc :values="enables" label="users.form.enabled" name="enabled" v-model="$v.form.enabled.$model"
				 :o="$v.form.enabled" :submitted="submitted"></Slc>
			<div class="form-buttons-reverse">
				<Btn :loading="loading" :text="'Salva'" text-size="big" button-type="primary" :is-full="false"
					 :is-submit-button="true"></Btn>
			</div>
		</form>
	</div>
</template>
<script>
    import {required, email, sameAs, minLength, requiredIf} from 'vuelidate/lib/validators'
    import Btn from "../common/forms/Btn";
    import Inpt from "../common/forms/Inpt";
    import Slc from "../common/forms/Slc";

    export default {
        name: 'UserForm',
        components: {Slc, Inpt, Btn},
        props: {
            base: {
                default: null,
                required: false
            }
        },
        data() {
            return {
                submitted: false,
                error: '',
                loading: false,
                publishers: [],
                roles: {},
                enables: {
                    0: 'Non abilitato',
                    1: 'Abilitato'
                },
                form: {
                    id: null,
                    name: '',
                    email: '',
                    role: 'security',
                    enabled: 0,
                    password: '',
                    repeat: ''
                }
            };
        },
        validations: {
            form: {
                id: {},
                name: {
                    required
                },
                email: {
                    required,
                    email
                },
                role: {
                    required
                },
                enabled: {
                    required
                },
                password: {
                    requiredIf: requiredIf(function () {
                        return this.form.id == '' || (this.form.id == null)
                    }),
                    minLength: minLength(8)
                },
                repeat: {
                    sameAsPassword: sameAs('password')
                }
            }
        },
        methods: {
            init() {
                this.roles = 'roles';
                if (this.base !== null) {
                    Object.keys(this.form).map(k => {
                        if (this.base[k]) {
                            this.form[k] = this.base[k];
                        }
                    })
                }
            },
            submitForm() {
                this.$v.form.$touch();
                this.submitted = true;
                this.error = '';
                this.loading = true;
                if (!this.$v.form.$invalid) {
                    this.service.users.save(this.form)
                        .then(() => {
                            this.$emit('save');
                            this.submitted = false;
                        })
                        .catch((err) => {
                            if (err && err.messages && err.messages[0]) {
                                let errorMessage = `errors.response.${err.messages[0]}`;
                                this.error = this.errorMessage;
                                this.submitted = false;
                            }
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.loading = false;
                }
            }
        },
        mounted() {
            this.init();
        }
    }
</script>
<style lang="scss">
	.form-buttons-reverse {
		width: 100%;
		display: flex;
		flex-direction: row-reverse;
	}
</style>
