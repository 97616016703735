<template>
	<div id="post_list" :class="(ispopup) ? '' : 'main-content'" class="page">
		<div class="page--head">
			<div v-if="!ispopup" class="head--title">
				<PageTitle :title_translation="false" :title="`Articoli`" :subtitle="`Gestione articoli`"></PageTitle>
				<span class="floating">
					<Btn @btn-click="go('new')" text-size="small" button-type="primary" text="Aggiungi"></Btn>
				</span>
			</div>
			<!-- ricerca -->
			<div class="ricerca">
				<div :class="`post_list_actions ${ispopup ? 'post_list_actions_popup' : ''}`">
					<div class="post_list_actions__search_container">
						<div class="field">
							<select @change="isSearch = true; refresh();" :disabled="loading" v-model="search_category"
									name="post_list_search_category" id="post_list_search_category">
								<option :value="null">Tutte le categorie</option>
								<option :value="cat.id" v-for="cat in categories" :key="cat.id">
									<span v-if="cat.parent !== null">---</span>
									{{ cat.name }}
								</option>
							</select>
						</div>
						<div class="field">
							<select @change="isSearch = true; page=1; refresh();" :disabled="loading"
									v-model="search_attributes" name="post_list_search_acf" id="post_list_search_acf">
								<option :value="null">Tutte le priorità</option>
								<option :value="`breaking_news`">Breaking News</option>
								<option :value="`breaking_news_locale`">Breaking News Locale</option>
								<!--<option :value="`straordinaria`">Straordinaria</option>-->
								<option :value="`straordinaria_biancoscudato`">Straordinaria TgBiancoscudato</option>
								<option :value="`straordinaria_gialloblu`">Straordinaria TgGialloblu</option>
								<option :value="`straordinaria_padova`">Straordinaria TgPadova</option>
								<option :value="`straordinaria_verona`">Straordinaria TgVerona</option>
								<!--<option :value="`alta`">Alta</option>-->
								<option :value="`alta_biancoscudato`">Alta TgBiancoscudato</option>
								<option :value="`alta_gialloblu`">Alta TgGialloblu</option>
								<option :value="`alta_padova`">Alta TgPadova</option>
								<option :value="`alta_verona`">Alta TgVerona</option>
							</select>
						</div>
						<div class="field">
							<input :disabled="loading" class="post_list_search" id="post_list_search" v-model="search"
								   @keydown.13="searchHandler($event)" :placeholder="'Cerca'">
						</div>
						<div class="field">
							<Datetime :disabled="loading" :format="`dd/MM/yyyy`" :placeholder="`Data articolo`"
									  :type="`date`" v-model="search_date"></Datetime>
							<div v-if="search_date" class="remove_date_time" @click="search_date = null">
								<Icon icon="trash"></Icon>
							</div>
						</div>
						<div class="ricerca--controls">
							<div class="send_search_container control">
								<div class="send_search" @click="isSearch = true; page=1; refresh();">
									<Icon icon="search"></Icon>
								</div>
							</div>

							<div v-if="isSearch" class="trash_all_container control">
								<div class="trash_all" @click="isSearch = false; initSearch(); refresh();">
									<Icon icon="cross"></Icon>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="page--content">
			<!-- tabs -->
			<div v-if="!ispopup" class="tabs">
				<div :class="`tab ${('publish' == postTypeSelected) ? 'selected' : ''}`"
					 @click="postTypeSelected = 'publish'; refresh()">
					Pubblicati
				</div>
				<div :class="`tab ${('draft' == postTypeSelected) ? 'selected' : ''}`"
					 @click="postTypeSelected = 'draft'; refresh()">
					Bozze
				</div>
				<div :class="`tab ${('future' == postTypeSelected) ? 'selected' : ''}`"
					 @click="postTypeSelected = 'future'; refresh()">
					Futuri
				</div>
			</div>

			<!-- lista -->
			<div class="panel">
				<LoadingSpinner v-if="loading" :small="true"></LoadingSpinner>
				<div class="table" v-if="!loading && items.length">
					<div class="table--head">
						<span class="table--head__col long">Articolo</span>
						<span class="table--head__col">Data</span>
						<span v-if="!ispopup" class="table--head__col">Categoria</span>
						<!--<span class="table--head__col long"></span>-->
					</div>
					<div class="table--body">
						<div v-for="i in items" v-bind:key="i.id" class="table--row">
			                <span class="table--body__col long article">
			                    <span @click="go(i.id)" class="post__title">
									{{ i.title }}
								</span>
			                    <small class="post__autore">
									di {{ i.autore }}
									<!--<span v-if="i.social_status == 'publish'">
										<br><i class="facebook_color">Pubblicato su facebook
										<span v-if="i && i.social && i.social.facebook && i.social.facebook.tg && i.social.facebook.tg.length">
										  {{ getSocial(i.social.facebook.tg) }}
										</span></i>
									</span>
									<span v-if="i.social_status == 'wait'">
										<br><i class="facebook_color">In attesa di pubblicazione su facebook
										 su <span v-if="i && i.social && i.social.facebook && i.social.facebook.tg && i.social.facebook.tg.length">
										  {{ i.social.facebook.tg.join(', ') }}
										</span></i>
									</span>
									<span v-if="i.social_status == 'error'">
										<br><i class="facebook_color">Errore durante la pubblicazione su facebook</i>
									</span>-->
								</small>
								<small style="cursor: pointer" v-if="service.utils.getArticleUrl(i)">
									<a :href="service.utils.getArticleUrl(i)" target="_blank" style="color: grey">
										{{ service.utils.getArticleUrl(i) }}
									</a>
									<div @click="copyLink(service.utils.getArticleUrl(i))">[<strong>Copia url negli appunti</strong>]</div>
								</small>
			                </span>
							<span class="table--body__col date">
			                    {{ i.date | datatime }}
			                </span>
							<span v-if="!ispopup" class="table--body__col cats">
								<span class="post__categories" v-html="getCategories(i.categories)"></span>
							</span>
							<span class="table--body__col extra">
			                    <small class="attributi">
									<span v-if="i.breaking_news">
										<strong>Breaking News</strong>
			                        </span>
			                        <span v-if="i.breaking_news_locale">
			                           <strong>Breaking News Locale</strong>
			                        </span>
			                        <span v-if="i.straordinaria">
			                            <strong>- Straordinaria<br></strong>
			                        </span>
									<span v-if="i.straordinaria_verona">
			                            <strong>- Straordinaria TgVerona<br></strong>
			                        </span>
									<span v-if="i.straordinaria_padova">
			                            <strong>- Straordinaria TgPadova<br></strong>
			                        </span>
									<span v-if="i.straordinaria_gialloblu">
			                            <strong>- Straordinaria TgGialloblu<br></strong>
			                        </span>
									<span v-if="i.straordinaria_biancoscudato">
			                            <strong>- Straordinaria TgBiancoscudato<br></strong>
			                        </span>
			                        <span v-if="i.alta">
			                            <strong>- Alta<br></strong>
			                        </span>
									<span v-if="i.alta_verona">
			                            <strong>- Alta TgVerona<br></strong>
			                        </span>
									<span v-if="i.alta_padova">
			                            <strong>- Alta TgPadova<br></strong>
			                        </span>
									<span v-if="i.alta_gialloblu">
			                            <strong>- Alta TgGialloblu<br></strong>
			                        </span>
									<span v-if="i.alta_biancoscudato">
			                            <strong>- Alta TgBiancoscudato<br></strong>
			                        </span>
			                    </small>

								<QuickPostUpdateModal
									:post_id="i.id"
									v-if="postQuickSelected == i.id"
									v-on:close="postQuickSelected = null"
									v-on:save="quickSave"></QuickPostUpdateModal>
								<button type="button" @click="postQuickSelected = i.id">Aggiorna Stato</button>

			                </span>
						</div>
					</div>
				</div>

				<div v-if="!loading && items.length == 0" class="no-posts">
					Nessun elemento da visualizzare
				</div>

				<!-- paginazione -->
				<PaginationSimple v-if="!loading" :page="page" :page_end="pageEnd"
								  v-on:page="changePage($event)"></PaginationSimple>
			</div>
		</div>
	</div>
</template>

<script>
import PageTitle from "../../components/common/PageTitle";
import LoadingSpinner from "../../components/common/Loading";
import Btn from "../../components/common/forms/Btn";
import PaginationSimple from "../../components/common/forms/PaginationSimple";
import Icon from "../../components/common/forms/Icon";
import {Datetime} from 'vue-datetime'
import QuickPostUpdateModal from "../../components/common/modals/QuickPostUpdate";

export default {
	name: 'PostList',
	components: {
		QuickPostUpdateModal,
		Icon,
		PaginationSimple,
		Btn,
		LoadingSpinner,
		PageTitle,
		Datetime
	},
	props: {
		ispopup: {
			required: false,
			default: false
		}
	},
	data () {
		return {
			loading: false,
			items: [],
			page: 1,
			pageEnd: false,
			search: '',
			search_category: null,
			search_attributes: null,
			search_date: null,
			categories: [],
			sort: null,
			postTypeSelected: 'publish',
			isSearch: false,
			postQuickSelected: null
		}
	},
	watch: {
		search_date: {
			immediate: true,
			handler () {
				this.isSearch = true;
				this.refresh();
				this.page = 1;
			}
		}
	},
	methods: {
		/**
		 * init
		 */
		initSearch () {
			this.search = '';
			this.search_category = null;
			this.search_attributes = null;
			this.search_date = null;
			localStorage.removeItem("post_search")
		},
		/**
		 * Copy Link
		 * @param url
		 */
		copyLink (url) {
			let tobecopied = document.createElement('input');
			tobecopied.classList.add('tobecopied')
			tobecopied.value = url;
			document.body.appendChild(tobecopied);
			tobecopied.focus();
			tobecopied.select();
			document.execCommand('copy');
			//alert("Link copiato correttamente");
			this.flashMessage.show({
				status: 'success',
				title: 'Link',
				message: "Link copiato correttamente"
			});

		},
		/**
		 * Refresh
		 */
		refresh () {
			this.loading = true;
			this.items = [];
			if (this && this.service && this.service.categories) {
				this.service.categories.list()
					.then(categories => {
						// sistemo le categorie
						this.categories = [];
						if (categories) {
							categories.map(c => {
								if (c.child.length) {
									this.categories.push({
										id: c.id,
										parent: null,
										name: c.name
									});
									c.child.map(ch => {
										let name = (ch.name.indexOf('(') >= 0) ? ch.name : `${ch.name} (${c.name})`
										this.categories.push({
											id: ch.id,
											parent: c.id,
											name: name
										});
									})
								}
							})
						}


						// se si tratta i una categoria padre, devo cercare per
						// tutte le categorie figlie

						let search_category_for_wp = null;
						if (this.search_category && this.search_category !== null) {
							let cFilter = categories.filter((c) => {
								return c.id == this.search_category
							});
							if (cFilter && cFilter.length) {
								search_category_for_wp = [];
								cFilter[0].child.map(ch => {
									search_category_for_wp.push(ch.id)
								})
								search_category_for_wp = search_category_for_wp.join(',');
							} else {
								search_category_for_wp = this.search_category;
							}
						}

						let search_date = null;
						if (this.search_date && this.search_date !== "" && this.search_date.indexOf('T') >= 0) {
							search_date = this.search_date.split("T")[0];
						}

						localStorage.setItem("post_search", JSON.stringify({
							search: this.search,
							search_category: this.search_category,
							search_attributes: this.search_attributes,
							search_date: this.search_date
						}));

						let service = this.service.post.list;
						if (this.ispopup) {
							service = this.service.post.list_read_only;
						}
						service(this.page, this.sort, this.postTypeSelected, this.search, search_category_for_wp, this.search_attributes, search_date)
							.then(items => {
								items.map(i => {
									let bn = (i.breaking_news) ? 'breaking_news' : '';
									let bnl = (i.breaking_news_locale) ? 'breaking_news_locale' : '';
									let s = (i.straordinaria) ? 'straordinaria' : '';
									let a = (i.alta) ? 'alta' : '';

									i.__extra = {
										current: `${bn}${bnl}${s}${a}`,
										isShow: false,
										isLoading: false
									}
								})
								this.items = items;
							})
							.finally(() => {
								this.loading = false;
							})
					})
					.catch(() => {
						this.loading = false;
					})
			}
		},
		/**
		 * Change page
		 * @param page
		 */
		changePage (page) {
			this.page = page;
			this.refresh();
		},
		/**
		 * Search
		 * @param event
		 */
		searchHandler (event) {
			if (event) event.preventDefault();
			if (event) event.stopPropagation();
			this.isSearch = true;
			this.refresh();
			this.page = 1;
		},
		/**
		 * Get all categories
		 * @param categories
		 * @returns {string}
		 */
		getCategories (categories) {
			let result = [];
			if (categories) {
				Object.keys(categories).map(k => {
					let category = categories[k];
					let childs = [];
					category.child.map(child => {
						childs.push(`<li>${child.name}</li>`)
					})
					result.push(`<li>${category.name}<ul>${childs.join("")}</ul></li>`)
				})
			}
			return `<ul>${result.join("")}</ul>`;
		},
		/**
		 * Go to page
		 * @param id
		 */
		go (id) {
			if (this.ispopup) {
				this.$emit('close', id)
			} else {
				this.$router.push({name: 'postdetail.page', params: {id: id}})
			}
		},


		quickSave (p) {
			this.items.forEach(i => {
				if (+i.id == +p.id) {
					i.breaking_news = p.breaking_news;
					i.breaking_news_locale = p.breaking_news_locale;
					i.straordinaria = p.straordinaria;
					i.straordinaria_verona = p.straordinaria_verona;
					i.straordinaria_padova = p.straordinaria_padova;
					i.straordinaria_gialloblu = p.straordinaria_gialloblu;
					i.straordinaria_biancoscudato = p.straordinaria_biancoscudato;
					i.alta = p.alta;
					i.alta_verona = p.alta_verona;
					i.alta_padova = p.alta_padova;
					i.alta_gialloblu = p.alta_gialloblu;
					i.alta_biancoscudato = p.alta_biancoscudato;
				}
			})
			this.postQuickSelected = null;
		},

		getSocial (socials) {
			let s = ``;
			if (socials && socials.length) {
				socials.map((item, index) => {
					if (item !== "telenuovo") {
						if (socials[index].slice(0, 2) != "Tg") {
							socials[index] = `Tg${socials[index].charAt(0).toUpperCase() + socials[index].slice(1)}`;
						}
					} else {
						socials[index] = `${socials[index].charAt(0).toUpperCase() + socials[index].slice(1)}`;
					}
				})
				s = ` su ${socials.join(", ")}`
			}
			return s;
		}

		/*changeStatus(i) {
			//i.__extra.isShow = false;
			i.__extra.isLoading = true;

			i.breaking_news = false;
			i.breaking_news_locale = false;
			i.straordinaria = false;
			i.straordinaria_verona = false;
			i.straordinaria_padova = false;
			i.straordinaria_gialloblu = false;
			i.straordinaria_biancoscudato = false;
			i.alta = false;
			i.alta_verona = false;
			i.alta_padova = false;
			i.alta_gialloblu = false;
			i.alta_biancoscudato = false;
			if(i.__extra.current == "breaking_news") i.breaking_news = true;
			if(i.__extra.current == "breaking_news_locale") i.breaking_news_locale = true;
			if(i.__extra.current == "straordinaria") i.straordinaria = true;
			if(i.__extra.current == "straordinaria_verona") i.straordinaria_verona = true;
			if(i.__extra.current == "straordinaria_padova") i.straordinaria_padova = true;
			if(i.__extra.current == "straordinaria_gialloblu") i.straordinaria_gialloblu = true;
			if(i.__extra.current == "straordinaria_biancoscudato") i.straordinaria_biancoscudato = true;
			if(i.__extra.current == "alta") i.alta = true;
			if(i.__extra.current == "alta_verona") i.alta_verona = true;
			if(i.__extra.current == "alta_padova") i.alta_padova = true;
			if(i.__extra.current == "alta_gialloblu") i.alta_gialloblu = true;
			if(i.__extra.current == "alta_biancoscudato") i.alta_biancoscudato = true;

			let f = {...i};
			delete f.__extra;
			f.tags = (f.tags && f.tags.length) ? f.tags : [];
			f.date = f.date ? this.$moment(f.date * 1000).tz("Europe/Rome").format() : this.$moment().tz("Europe/Rome").format();
			f.featured_media = f.media_id;
			f.featured_media_url = f.media;
			f.others = {
				breaking_news: f.breaking_news || false,
				breaking_news_locale: f.breaking_news_locale || false,
				straordinaria: f.straordinaria || false,
				straordinaria_verona: f.straordinaria_verona || false,
				straordinaria_padova: f.straordinaria_padova || false,
				straordinaria_gialloblu: f.straordinaria_gialloblu || false,
				straordinaria_biancoscudato: f.straordinaria_biancoscudato || false,
				alta: f.alta || false,
				alta_verona: f.alta_verona || false,
				alta_padova: f.alta_padova || false,
				alta_gialloblu: f.alta_gialloblu || false,
				alta_biancoscudato: f.alta_biancoscudato || false,
				autore: (f.autore && f.autore !== 'La Redazione') ? f.autore : '',
				news_video: f.media_video || false,
				video_in_copertina: (f.media_video && f.media) ? f.media : '',
				breaking_news_correlate: [],
				correlati: [],
				correlati_titolo: [],
				didascalia: (f.didascalia) ? f.didascalia : '',
				galleria: [],
				allegati: [],
				social_status: f.social_status || '',
				social: {
					facebook: {
						publish: false,
						publish_date: null,
						title: '',
						description: '',
						media: '',
						tg: []
					}
				},
				main_category: f.main_category || null
			}
			if (f.breaking_news_correlate && f.breaking_news_correlate.length) {
				f.breaking_news_correlate.map(bn => {
					f.others.breaking_news_correlate.push(bn.id);
				})
			}
			if (f.articoli_correlati_titolo && f.articoli_correlati_titolo.length) {
				f.articoli_correlati_titolo.map(ac => {
					f.others.correlati_titolo.push({
						title: ac.title,
						post: ac.post.id
					});
				})
			}
			if (f.galleria && f.galleria.length) {
				f.galleria.map(ac => {
					f.others.galleria.push({
						media: ac.media,
						didascalia: ac.didascalia
					});
				})
			}
			if (f.allegati && f.allegati.length) {
				f.allegati.map(ac => {
					f.others.allegati.push({
						media: ac.media,
						didascalia: ac.didascalia
					});
				})
			}
			if (f.correlati && f.correlati.length) {
				f.correlati.map(bn => {
					f.others.correlati.push(bn.id);
				})
			}
			if (f.categories && f.categories !== null) {
				let cat_tmp = [];
				Object.keys(f.categories).map(k => {
					if (f.categories[k].child && f.categories[k].child.length) {
						f.categories[k].child.map(icc => {
							cat_tmp.push(icc.id);
						})
					}
				});
				f.categories = cat_tmp;
			} else {
				f.categories = [];
			}
			if (f.tags) {
				let tag_tmp = [];
				f.tags.map(t => {
					tag_tmp.push(t.name);
				})
				f.tags = tag_tmp;
			} else {
				f.tags = [];
			}
			this.service.post.tags(f.tags)
				.then((tags) => {
					if (tags && tags.success) {
						f.tags = tags.tags.ids;
					} else {
						f.tags = [];
					}
					this.service.post.save(f)
						.then(() => {
							i.__extra.isShow = false;
							i.__extra.isLoading = false;
						})
						.catch((err) => {
							if (err && err.messages && err.messages[0]) {
								i.__extra.isShow = false;
								i.__extra.isLoading = false;
							}
						})
						.finally(() => {
							i.__extra.isShow = false;
							i.__extra.isLoading = false;
						})
				});
		} */
	},
	mounted () {
		// se sono stati settati dei filtri li aggiorno
		let sFilters = localStorage.getItem("post_search") || null;
		if (sFilters) {
			sFilters = JSON.parse(sFilters);
			this.search = sFilters.search || "",
				this.search_category = sFilters.search_category || null,
				this.search_attributes = sFilters.search_attributes || null,
				this.search_date = sFilters.search_date || null
		}
		this.refresh()
	}
}
</script>

<style lang="scss">
@import '@/assets/scss/pages/_postlist';
@import '@/assets/scss/components/_ricercaposts';

.facebook_color {
	color: #3b5998;
}

.remove_date_time {
	cursor: pointer;
	position: relative;
	top: -50px;
	right: -100px;
	svg {
		fill: $primary;
	}
}
</style>
