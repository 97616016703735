import moment from 'moment-timezone'

export default {
	install: function (Vue) {
		let service = {};
		Vue.mixin({
			mounted () {
				let helpers = {}

				/**
				 * Crea i parametri per la lista
				 * @param page
				 * @param sort
				 * @param search
				 * @param search_fields
				 * @returns {string}
				 */
				helpers.buildQueryParams = (page, sort, search, others) => {
					page = page || 1;
					sort = sort || null;
					let objParams = {
						page: page
					};
					if (sort !== null) {
						objParams.sort = sort
					}
					search = search || null;
					if (search !== null) {
						objParams.search = search;
					}

					if (others) {
						Object.keys(others).map(k => {
							if (others[k] && others[k] !== null)
								objParams[k] = others[k];
						})
					}

					let params = '';
					for (var key in objParams) {
						if (params != "") {
							params += "&";
						}
						params += key + "=" + encodeURIComponent(objParams[key]);
					}
					return params
				}
				/**
				 *
				 * @param str
				 * @returns {string}
				 */
				helpers.decode = (str) => {
					let t = document.createElement('textarea');
					t.innerHTML = str;
					let v = t.value;
					return v;
				}
				service.helpers = helpers;

				/**
				 * Gestione del profilo
				 * @returns {Promise<unknown>}
				 */
				service.profile = () => {
					let url = `/user/profile`;
					return new Promise((resolve) => {
						this.$api.get(url)
							.then((data) => {
								// se esiste data allora ok
								if (data && data.id && data.id !== null) {
									resolve(data);
								} else {
									// se data non esiste controllo l'header, e controllo se esiste
									// il token, se esiste lo salvo e faccio il reload della pagina
									let parseHttpHeaders = (httpHeaders) => {
										return httpHeaders.split("\n")
											.map(x => x.split(/: */, 2))
											.filter(x => x[0])
											.reduce((ac, x) => {
												ac[x[0].trim().toLowerCase()] = x[1];
												return ac;
											}, {});
									}
									let req = new XMLHttpRequest();
									req.open('GET', document.location, false);
									req.send(null);
									let headers = parseHttpHeaders(req.getAllResponseHeaders());

									if (headers && headers.authorization) {
										let token = headers.authorization.replace("Bearer ", "");
										localStorage.setItem('token', token);
										window.location.reload();
									} else {
										localStorage.clear();
										window.location.reload();
									}
								}
							})
							.catch(() => {
								console.log(`Unauthorized`);
								window.location.href = `#/login`;
							})
					})
				}

				let media = {}
				media.list = (page, sort, search, category, type) => {
					type = type || null;
					let params = service.helpers.buildQueryParams(page, sort, search, {
						category: category,
						type: type
					});
					return this.$api.get(`/media?${params}`);
				}
				media.list_working = (page, sort, search, category, type, status) => {
					type = type || null;
					let params = service.helpers.buildQueryParams(page, sort, search, {
						category: category,
						type: type,
						media_status: status,
						item_per_page: 50
					});
					return this.$api.get(`/media?${params}`);
				}
				media.single = (id) => {
					return this.$api.get(`/media/${id}`);
				}
				media.upload = (data, mime, title, caption, category) => {
					return this.$api.upload(`/media`, data, mime, title, caption, category);
				}
				media.changeVideoThumb = (videoId, data, mime) => {
					return this.$api.changeVideoThumbnail(videoId, data, mime);
				}
				media.changeVideoTitolatrieThumb = (videoId, data, mime) => {
					return this.$api.changeVideoTitolatriceThumbnail(videoId, data, mime);
				}
				media.save = (item) => {
					if (item.id && item.id !== "") {
						return this.$api.put(`/media/${item.id}`, item);
					}
					return this.$api.post(`/media`, item);
				}
				media.check = (kb) => {
					let data = '';
					for (let i = 0; i < (+kb * 1024); i++) {
						data = data + '#';
					}
					return this.$api.post(`/`, {
						data: data
					});
				}
				media.delete = (id) => {
					return this.$api.delete(`/media/${id}`);
				}
				media.titolatrice = {};
				media.titolatrice.getCategories = (all) => {
					let params = ``;
					if (all || false) {
						params = `?all=1`;
					}
					return this.$api.get(`/media/titolatrice/categories${params}`);
				}
				media.titolatrice.getPlayCategory = (id) => {
					return this.$api.get(`/media/titolatrice/categories/${id}`);
				}
				service.media = media;

				let categories = {}
				categories.list = () => {
					let params = service.helpers.buildQueryParams();
					return this.$api.get(`/categories?${params}`);
				}
				service.categories = categories;


				let post = {}
				post.list = (page, sort, status, search, category, attributes, date) => {
					let params = service.helpers.buildQueryParams(page, sort, search, {
						status: status,
						cats: category || null,
						attributes: attributes || null,
						date: date || null,
						per_page: 10
					});
					return this.$api.get(`/post?${params}`);
				}
				post.list_read_only = (page, sort, status, search, category, attributes, date, ids) => {
					let params = service.helpers.buildQueryParams(page, sort, search, {
						status: status,
						cats: category || null,
						attributes: attributes || null,
						date: date || null,
						c: "0",
						ids: ids || null,
						per_page: 6
					});
					return this.$api.get(`/post?${params}`);
				}
				post.single = (id) => {
					return this.$api.get(`/post/${id}`);
				}
				post.save = (item) => {
					if (item.id && item.id !== "" && item.id !== null) {
						return this.$api.put(`/post/${item.id}`, item);
					}
					return this.$api.post(`/post`, item);
				}
				post.tags = (tags) => {
					return this.$api.post(`/post/tags`, {tags: tags.join(",")});
				}
				post.delete = (id) => {
					return this.$api.delete(`/post/${id}`);
				}
				service.post = post;


				let sondaggio = {}
				sondaggio.list = (page) => {
					let params = service.helpers.buildQueryParams(page, null, null);
					return this.$api.get(`/sondaggio?${params}`);
				}
				sondaggio.all = (page) => {
					let params = service.helpers.buildQueryParams(page, null, null, {
						active: "0"
					});
					return this.$api.get(`/sondaggio?${params}`);
				}
				sondaggio.single = (id) => {
					return this.$api.get(`/sondaggio/${id}`);
				}
				sondaggio.save = (item) => {
					if (item.id && item.id !== "" && item.id !== null) {
						return this.$api.put(`/sondaggio/${item.id}`, item);
					}
					return this.$api.post(`/sondaggio`, item);
				}
				sondaggio.delete = (id) => {
					return this.$api.delete(`/sondaggio/${id}`);
				}
				service.sondaggio = sondaggio;


				let play_categorie = {}
				play_categorie.list = (page) => {
					let params = service.helpers.buildQueryParams(page, null, null, {
						per_page: 100
					});
					return this.$api.get(`/play_categorie?${params}`);
				}
				play_categorie.all = () => {
					let params = service.helpers.buildQueryParams(1, null, null, {
						per_page: 500
					});
					return this.$api.get(`/play_categorie?${params}`);
				}
				play_categorie.single = (id) => {
					return this.$api.get(`/play_categorie/${id}`);
				}
				play_categorie.save = (item) => {
					if (item.id && item.id !== "" && item.id !== null) {
						return this.$api.put(`/play_categorie/${item.id}`, item);
					}
					return this.$api.post(`/play_categorie`, item);
				}
				play_categorie.delete = (id) => {
					return this.$api.delete(`/play_categorie/${id}`);
				}
				service.play_categorie = play_categorie;

				let play = {}
				play.list = (page, search, searchCategory) => {
					searchCategory = searchCategory || null
					let others = {
						per_page: 20,
						category: searchCategory
					}
					if (searchCategory == null) {
						delete others[`category`];
					}
					let params = service.helpers.buildQueryParams(page, null, search, others);
					return this.$api.get(`/play?${params}`);
				}
				play.single = (id) => {
					return this.$api.get(`/play/${id}`);
				}
				play.save = (item) => {
					if (item.id && item.id !== "" && item.id !== null) {
						return this.$api.put(`/play/${item.id}`, item);
					}
					return this.$api.post(`/play`, item);
				}
				play.delete = (id) => {
					return this.$api.delete(`/play/${id}`);
				}
				service.play = play;


				let settings = {
					sticky: {}
				}
				settings.sticky.toggle = () => {
					return this.$api.post(`/settings/sticky/toggle`, {});
				}
				settings.sticky.status = () => {
					return this.$api.get(`/settings/sticky/status`, {});
				}
				service.settings = settings;



				let speciali_categorie = {}
				speciali_categorie.list = (page) => {
					let params = service.helpers.buildQueryParams(page, null, null, {
						per_page: 100
					});
					return this.$api.get(`/speciali_categorie?${params}`);
				}
				speciali_categorie.all = () => {
					let params = service.helpers.buildQueryParams(1, null, null, {
						per_page: 100
					});
					return this.$api.get(`/speciali_categorie?${params}`);
				}
				speciali_categorie.single = (id) => {
					return this.$api.get(`/speciali_categorie/${id}`);
				}
				speciali_categorie.save = (item) => {
					if (item.id && item.id !== "" && item.id !== null) {
						return this.$api.put(`/speciali_categorie/${item.id}`, item);
					}
					return this.$api.post(`/speciali_categorie`, item);
				}
				speciali_categorie.delete = (id) => {
					return this.$api.delete(`/speciali_categorie/${id}`);
				}
				service.speciali_categorie = speciali_categorie;

				let speciali = {}
				speciali.list = (page, sort, status, search, category, attributes, date) => {
					let params = service.helpers.buildQueryParams(page, sort, search, {
						status: status,
						cats: category || null,
						attributes: attributes || null,
						date: date || null
					});
					console.log(`Speciali`, params);
					return this.$api.get(`/speciali?${params}`);
				}
				speciali.single = (id) => {
					return this.$api.get(`/speciali/${id}`);
				}
				speciali.save = (item) => {
					if (item.id && item.id !== "" && item.id !== null) {
						return this.$api.put(`/speciali/${item.id}`, item);
					}
					return this.$api.post(`/speciali`, item);
				}
				speciali.delete = (id) => {
					return this.$api.delete(`/speciali/${id}`);
				}
				service.speciali = speciali;


				service.utils = {
					getArticleUrl(article) {
						if(article.main_category_obj && article.date && article.categories && article.main_category && article.slug) {
							let tg = ``;
							Object.keys(article.categories).map(k => {
								(article.categories[k].child || []).map((cc => {
									if(cc.id == article.main_category) {
										tg = k;
									}
								}))
							})
							let categories = [];
							article.main_category_obj.slug.split(`-`).map(n => {
								if (!(+n)) {
									categories.push(n.trim());
								}
							})
							let linkUrl = `https://${tg}.telenuovo.it/${categories.join('-')}/${moment(+article.date * 1000).format("YYYY/MM/DD")}/${article.slug}`
							if(tg == "tgtelenuovo") {
								linkUrl = linkUrl.replace("https://tgtelenuovo.telenuovo.it/home/", "https://telenuovo.it/")
							}
							linkUrl = linkUrl.replace("https://.telenuovo.it", "https://telenuovo.it")
							return linkUrl
						}
						return null;
					},
					getSpecialeUrl(article, category) {
						return `https://telenuovo.it/speciali/${category}/${article.slug}`
					}
				}

				let socials = {}
				socials.add = (text, tags, url, platform, page, scheduled, scheduleddatetime) => {
					let obj = {}
					if(scheduled && scheduleddatetime !== "" && scheduleddatetime !== null) {
						obj = {
							text: text,
							tags: tags,
							url: url,
							platform: platform,
							page: page,
							scheduledatetime: scheduleddatetime.replace("T", " ").replace(".000Z", "").trim()
						}
					} else {
						obj = {
							text: text,
							tags: tags,
							url: url,
							platform: platform,
							page: page
						}
					}

					//console.log(obj)
					return this.$socialApi.post(`/v1/socials`, obj);

					/*return this.$socialApi.post(`/v1/socials`, {
						text: text,
						tags: tags,
						url: url,
						platform: platform,
						page: page
					});*/
				}
				socials.get = (id) => {
					return this.$socialApi.get(`/v1/socials/${id}`);
				}
				service.socials = socials;
			}
		});
		Vue.prototype.service = service;
	}
}


