<template>
	<span id="logo">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 76">
            <linearGradient id="a" x1="0%" x2="0%" y1="100%" y2="0%">
                <stop offset="0%" stop-color="#FCEB9A"/>
                <stop offset="100%" stop-color="#957D30"/>
            </linearGradient>
            <path fill-rule="evenodd" d="M54.186 36.149l18.23 10.513-18.23 10.514V36.149zm13.748 4.629l-6.763-3.899 2.679-2.609-16.479-2.392L40 16.963l-7.37 14.915-16.482 2.392 11.926 11.613-2.813 16.394L40 54.536l14.74 7.741-.354-2.076 6.938-3.984 3.399 19.782L40 63.017 15.278 75.999 20 48.503 0 29.03l27.64-4.014L40 .001l12.361 25.015L80 29.03 67.934 40.778z"/>
            <path fill="url(#a)" d="M54.186 36.149l18.23 10.513-18.23 10.514V36.149zm13.748 4.629l-6.763-3.899 2.679-2.609-16.479-2.392L40 16.963l-7.37 14.915-16.482 2.392 11.926 11.613-2.813 16.394L40 54.536l14.74 7.741-.354-2.076 6.938-3.984 3.399 19.782L40 63.017 15.278 75.999 20 48.503 0 29.03l27.64-4.014L40 .001l12.361 25.015L80 29.03 67.934 40.778z"/>
		</svg>
	</span>
</template>

<script>
export default {
	name: 'Logo'
}
</script>

<style lang="scss">
#logo {
	@include size(63px, 60px);
	svg {
		display: block;
		@include size(100%,100%);
	}
}
</style>
