<template>
	<transition name="confirm.modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container modal-container-big">
					<div class="modal-header">
						<slot name="header">
							<h3>Inserisci gli attributi del media</h3>
						</slot>
					</div>
					<div class="modal-body">
						<slot name="body">


							<div class="fieldset">
								<label>
									Inserisci il titolo del media <small>(Obbligatorio)</small>
								</label>
								<div class="input">
									<input :placeholder="`Titolo del media`" autocomplete="off" :maxlength="50" v-model="item.title"/>
								</div>
							</div>

							<div class="fieldset">
								<label>
									Inserisci una descrizione del media
								</label>
								<div class="input">
									<input :placeholder="`Descrizione del media`" autocomplete="off" :maxlength="50" v-model="item.description"/>
								</div>
							</div>

							<br>
							Seleziona la categoria da assegnare <small>(Obbligatorio)</small>
							<span v-for="category in categories" v-bind:key="category.id">
								<div :class="`button_selection_category ${(category.id == item.category.id) ? 'selected' : ''}`" v-if="category.id !== null" @click="item.category = category">
									{{ category.caption }}
								</div>
							</span>
						</slot>
					</div>
					<div class="modal-footer">
						<slot name="footer">
							<Btn :disabled="!(item.category.id > 0 && item.title !== '')" @btn-click="closeModal(item)" :text="`Continua`" text-size="" button-type="primary"></Btn>
							<Btn @btn-click="closeModal(false)" :text="`Chiudi finestra`" text-size=""></Btn>
						</slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
import Btn from "../forms/Btn";

export default {
	name: 'CategorySelectionModal',
	components: {Btn},
	props: {
		categories: {
			required: false
		}
	},
	data() {
		return {
			item: {
				category: {
					id: 0,
					caption: ''
				},
				title: '',
				description: ''
			},
		}
	},
	methods: {
		closeModal (item) {
			this.$emit('close', item)
		}
	}
}
</script>

<style lang="scss">
.modal-footer {
	display: flex;
	justify-content: space-between !important;
	flex-direction: row-reverse;
}

.button_selection_category {
	background: $white;
	color: $primary;
	border: 1px solid $primary;
	cursor: pointer;
	padding: 10px 20px;
	margin: 5px auto;
	text-align: center;

	&:hover {
		background: $primary;
		color: $white;
		opacity: .6;
	}

	&.selected {
		background: $primary;
		color: $white;
	}
}
</style>
