<template>
	<div id="public" class="public-layout">
		<div class="content-left">
			<main id="login" class="login main-content">
				<LoginForm></LoginForm>
			</main>
		</div>
		<div class="image-right">
			<img src="@images/login_image.jpg" alt=""/>
		</div>
	</div>
</template>

<script>
    import LoginForm from "../components/login/LoginForm";

    export default {
        name: 'Login',
        components: {LoginForm},
    }
</script>

<style lang="scss">
	.public-layout {
		display: flex;
		background: $white_smoke;
		width: 100%;
		min-height: 100vh;

		.image-right {
			display: none;
		}

		.content-left {
			display: block;
			width: 100%;
			padding: var(--gutter);
		}

		@media (min-width: 1000px) {
			.image-right,
			.content-left {
				width: 50%;
				min-height: 100vh;
			}
			.image-right {
				position: fixed;
				top: 0;
				right: 0;
				display: block;
				height: 100vh;
				overflow: hidden;

				img {
					@include size(100%, 100%);
					object-fit: cover;
				}
			}
			.content-left {
				display: flex;
				padding: calc(var(--gutter) * 2);
			}
		}
		@media (min-width: 1000px) and (min-height: 600px) {
			.content-left {
				align-items: center;
				justify-content: center;
			}
		}
	}

	.login {
		display: flex;
		@extend %flexCenterCenter;
		@include size(100%, 100%);
	}

</style>
