<template>
	<transition name="confirm.modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container modal-container-big">
					<div class="modal-header">
						<slot name="header">
							<h3>Cambio Thumbnail</h3>
						</slot>
					</div>
					<div class="modal-body media-modal">
						<slot name="body">
							<span class="file_upload_update_thumb_video fake-btn" v-if="!loading && !cropEditor">
								<span>
									<span>
										<br>Trascina il file da caricare oppure<br>
									</span>
									<a href='javascript:void(0)'>Seleziona il file</a>
									<input name="mediaFile" @change="uploadFile($event.target.files)" type='file'/>
								</span>
							</span>
							<span v-if="cropEditor">
								<strong>Selezionare la porzione di immagine da utilizzare</strong>
								<br><br>
							</span>

							<div class="file_upload_cropper" v-if="selectedData && cropEditor" style="height: 300px">
								<cropper :src="selectedData" imageRestriction="fit-area"
										 :stencil-props="{ aspectRatio: 16/10 }"
										 image-restriction="fit-area" default-boundaries="fill"
										 @change="newMediaFile"></cropper>
							</div>


						</slot>
					</div>
					<div class="modal-footer">
						<slot name="footer">
							<Btn :loading="loading" @btn-click="closeModal(false)" :text="`Chiudi`" text-size=""></Btn>

							<div v-if="selectedData && cropEditor" class="crop_buttons">
								<Btn :loading="loading" @btn-click="uploadCropImage()" text-size=""
									 button-type="primary" :text="`Sostitusci thumb`"></Btn>
							</div>
						</slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
import Btn from "@components/common/forms/Btn";
import {videoPlayer} from 'vue-videojs7';


export default {
	name: 'ChangeVideoTitolatriceThumbnailModal',
	components: {Btn},
	props: {
		videoId: {
			required: false,
			default: null
		}
	},
	data () {
		return {
			loading: false,
			cropEditor: false,
			uploadError: false,
			selectedCropImage: null,
			selectedData: null,
			selectedDataMime: null
		}
	},
	mounted () {
		this.init();
	},
	methods: {
		init () {
		},
		closeModal (success) {
			this.loading = true;
			this.$emit('close', {success: success || false});
		},
		uploadFile (fileList) {
			this.loading = true;
			this.cropEditor = false;
			this.uploadError = false;
			Array
				.from(Array(fileList.length).keys())
				.map(x => {
					let reader = new FileReader();
					let that = this;
					let type = (fileList[0].type || "").split("/")[0];
					if (type == "image") {
						reader.onload = function (e) {
							try {
								let data = e.target.result;
								let mimeType = fileList[0].type;
								that.selectedCropImage = null;
								that.selectedData = data;
								that.selectedDataMime = mimeType;
								that.loading = false;
								that.cropEditor = true;
							} catch (e) {
								that.errorData = 'File non valido';
								that.loading = false;
								that.selectedCropImage = null;
								that.selectedData = null;
								that.selectedDataMime = null;
								that.cropEditor = false;
							}
						}
						reader.readAsDataURL(fileList[x]);
					}
				});
		},
		newMediaFile ({canvas}) {
			canvas.toBlob((blob) => {
				const reader = new FileReader();
				let that = this;
				reader.addEventListener('loadend', () => {
					const arrayBuffer = reader.result;
					that.selectedCropImage = arrayBuffer;
				});
				reader.readAsArrayBuffer(blob);
			}, this.selectedDataMime)
		},
		uploadCropImage () {
			this.loading = true;
			this.service.media.changeVideoTitolatrieThumb(this.videoId, this.selectedCropImage, this.selectedDataMime)
				.then(() => {
					this.$emit('close', true);
					this.loading = false;
					this.selectedCropImage = null;
					this.selectedData = null;
					this.selectedDataMime = null;
					this.cropEditor = false;
				})
				.catch(() => {
					this.$emit('close', false);
					this.loading = false;
					this.selectedCropImage = null;
					this.selectedData = null;
					this.selectedDataMime = null;
					this.cropEditor = false;
				})
		}
	},

}
</script>

<style lang="scss">
.file_upload_update_thumb_video {
	position: relative;
	display: block;

	span {
		cursor: pointer !important;
	}

	a {
		position: relative;
		overflow: hidden;
		font-weight: 600;
		color: $primary;
	}

	span:hover a {
		color: $white;
		background: $primary;
		padding: 2px;
		border-radius: 2px;
	}

	a + input {
		position: absolute;
		top: 10px;
		left: 0;
		opacity: 0;
		cursor: pointer;
		line-height: 70px;
		width: 100%;
	}

	.fake-btn {
		display: block;
		@include size(auto, calc(var(--icon) * 2));
		position: relative;
		text-align: center;
		border: 1px dashed cadetblue;

		input[type="file"] {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			@include size(100%, 100%);
		}

		&:hover {
			background: #183351;
			color: white;
		}

	}
}

</style>
