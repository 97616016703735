<template>
	<div class="pagination">
		<span class="start" v-if="page > 1" @click="$emit('page', 1)">
			Torna all'inizio
		</span>
		<span class="prev" v-if="page > 1" @click="$emit('page', page - 1)">
			<Icon icon="next"></Icon>
		</span>
		<span class="next" v-if="!page_end" @click="$emit('page', page + 1)">
			<Icon icon="next"></Icon>
		</span>
	</div>
</template>

<script>
    import Icon from "./Icon";

    export default {
        name: 'PaginationSimple',
        components: {Icon},
        props: {
            page: {
                default: 1,
                required: true
            },
			page_end: {
            	default: false,
				required: true
			}
        }
    }
</script>

<style lang="scss">
	.pagination {
		display: flex;
		@extend %flexCenterEnd;
		margin: calc(var(--gutter) / 2) 0;

		span {
			font-size: 14px;
			font-weight: 500;
			cursor: pointer;

			&.start {
				color: $primary;
				font-size: 12px;
			}

			&.prev {
				svg {
					fill: $primary;
					transform: rotate(180deg);
					width: 50%;
				}
			}

			&.next {
				svg {
					fill: $primary;
					width: 50%;
				}
			}
		}
	}
</style>
