<template>
	<div id="sondaggio_list" class="page main-content">
		<div class="page--head">
			<div class="head--title">
				<PageTitle :title_translation="false" :title="`Sondaggi`"
						   :subtitle="`Gestione dei sondaggi`"></PageTitle>
				<span class="floating">
				<Btn @btn-click="go('new')" text-size="small" button-type="primary" text="Aggiungi"></Btn>
			</span>
				<!-- ricerca
				<div class="ricerca">
					<div :class="`post_list_actions ${ispopup ? 'post_list_actions_popup' : ''}`">
						<div class="post_list_actions__search_container">
							<div class="field">
								<select @change="isSearch = true; refresh();" :disabled="loading" v-model="search_category" name="post_list_search_category" id="post_list_search_category">
									<option :value="null">Tutte le categorie</option>
									<option :value="cat.id" v-for="cat in categories" :key="cat.id">
										<span v-if="cat.parent !== null">---</span>
										{{ cat.name }}
									</option>
								</select>
							</div>
							<div class="field">
								<select @change="isSearch = true; page=1; refresh();" :disabled="loading" v-model="search_attributes" name="post_list_search_acf" id="post_list_search_acf">
									<option :value="null">Tutte le priorità</option>
									<option :value="`breaking_news`">Breaking News</option>
									<option :value="`breaking_news_locale`">Breaking News Locale</option>
									<option :value="`straordinaria`">Straordinaria</option>
									<option :value="`alta`">Alta</option>
								</select>
							</div>
							<div class="field">
								<input :disabled="loading" class="post_list_search" id="post_list_search" v-model="search" @keydown.13="searchHandler($event)" :placeholder="'Cerca'">
							</div>
							<div class="field">
								<Datetime :disabled="loading" :format="`dd/MM/yyyy`" :placeholder="`Data articolo`" :type="`date`" v-model="search_date"></Datetime>
								<div v-if="search_date" class="remove_date_time" @click="search_date = null">
									<Icon icon="trash"></Icon>
								</div>
							</div>
							<div class="ricerca--controls">
								<div class="send_search_container control">
									<div class="send_search" @click="isSearch = true; page=1; refresh();">
										<Icon icon="search"></Icon>
									</div>
								</div>

								<div v-if="isSearch" class="trash_all_container control">
									<div class="trash_all" @click="isSearch = false; initSearch(); refresh();">
										<Icon icon="cross"></Icon>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				-->
			</div>
		</div>
		<div class="page--content">
			<!-- lista -->
			<div class="panel">
				<LoadingSpinner v-if="loading" :small="true"></LoadingSpinner>
				<div class="table" v-if="!loading && items.length">
					<div class="table--head">
						<span class="table--head__col long">Titolo</span>
						<span class="table--head__col">Scadenza</span>
						<span class="table--head__col">Visualizzazione</span>
					</div>
					<div class="table--body">
						<div v-for="i in items" v-bind:key="i.id" class="table--row">
			                <span class="table--body__col long article">
			                    <span @click="go(i.id)" class="post__title" v-html="i.title">
								</span>
			                </span>
							<span class="table--body__col date">
			                    {{ i.data_scadenza | datatime }}
			                </span>
							<span class="table--body__col">
			                    {{ i.scegli_dove_pubblicare.join(', ') }}
			                </span>
						</div>
					</div>
				</div>
				<div v-if="!loading && items.length == 0" class="no-posts">
					Nessun elemento da visualizzare
				</div>
				<!-- paginazione -->
				<PaginationSimple v-if="!loading" :page="page" :page_end="pageEnd"
								  v-on:page="changePage($event)"></PaginationSimple>
			</div>
		</div>
	</div>
</template>

<script>
import PageTitle from "../../components/common/PageTitle";
import LoadingSpinner from "../../components/common/Loading";
import Btn from "../../components/common/forms/Btn";
import PaginationSimple from "../../components/common/forms/PaginationSimple";

export default {
	name: 'SondaggioList',
	components: {
		PaginationSimple,
		Btn,
		LoadingSpinner,
		PageTitle
	},
	data () {
		return {
			loading: false,
			items: [],
			page: 1,
			pageEnd: false
		}
	},
	methods: {
		refresh () {
			this.loading = true;
			this.items = [];
			this.service.sondaggio.all(this.page)
				.then(items => {
					this.items = items;
				})
				.finally(() => {
					this.loading = false;
				})
		},
		changePage (page) {
			this.page = page;
			this.refresh();
		},
		searchHandler (event) {
			if (event) event.preventDefault();
			if (event) event.stopPropagation();
			this.isSearch = true;
			this.refresh();
			this.page = 1;
		},
		getCategories (categories) {
			let result = [];
			if (categories) {
				Object.keys(categories).map(k => {
					let category = categories[k];
					let childs = [];
					category.child.map(child => {
						childs.push(`<li>${child.name}</li>`)
					})
					result.push(`<li>${category.name}<ul>${childs.join("")}</ul></li>`)
				})
			}
			return `<ul>${result.join("")}</ul>`;
		},
		go (id) {
			if (this.ispopup) {
				this.$emit('close', id)
			} else {
				this.$router.push({name: 'sondaggiodetail.page', params: {id: id}})
			}
		}
	},
	mounted () {
		this.refresh()
	}
}
</script>

<style lang="scss">
@import '@/assets/scss/pages/_sondaggiolist';
@import '@/assets/scss/components/_ricercaposts';
// .head {
// 	display: flex;
// 	flex-wrap: wrap;
// 	width: 100%;
// 	margin-bottom: var(--gutter);
// 	&--title {
// 		display: flex;
// 		@extend %flexCenterBetween;
// 		width: 100%;
// 		margin-bottom: var(--gutter);
// 		.page-main-title {
// 			width: 75%;
// 		}
// 		.primary {
// 			text-align: center;
// 			justify-content: center;
// 		}
// 	}
// }
// @media (hover: hover) {
//
// }
// .post_list_actions {
// 	display: flex;
// 	height: 100%;
//
// 	button {
// 		margin-right: 10px;
// 	}
//
// 	&__search_container {
// 		min-width: 55vw;
// 		border: 1px solid $primary;
// 		background: rgba($primary, 0.1);
// 		padding: 10px;
//
// 		display: flex;
// 		flex-direction: row;
// 		justify-content: space-evenly;
//
// 		.field {
// 			margin-right: 5px;
// 			position: relative;
//
// 			select, input, .vdatetime {
// 				height: 100%;
// 			}
//
// 			.remove_date_time {
// 				cursor: pointer;
// 				position: absolute;
// 				top: 7px;
// 				right: 6px;
//
// 				svg {
// 					fill: $primary;
// 				}
// 			}
// 		}
// 	}
// }
//
// .post_list_actions_popup {
// 	width: 100% !important;
// }
//
// .post_list_search_container {
// 	margin-left: 10px;
// 	margin-top: 11px;
//
// 	input.post_list_search {
// 		height: 46px;
// 	}
// }
//
// .attributi {
// 	span {
// 		display: flex;
//
// 		strong {
// 			display: flex;
// 			align-items: center;
// 		}
// 	}
//
// 	svg {
// 		fill: $green;
// 	}
// }
//
// .post_autore {
// 	font-size: 12px;
// }
//
// .post_categories {
// 	font-size: 15px;
//
// 	ul {
// 		li {
// 			font-weight: 700;
//
// 			ul {
// 				list-style-type: disc;
// 				list-style-position: inside;
//
// 				li {
// 					font-weight: normal;
// 					padding-left: 10px;
// 				}
// 			}
// 		}
// 	}
// }
//
// .trash_all_container {
//
// 	.trash_all {
// 		margin-top: 7px;
// 		cursor: pointer;
//
// 		svg {
// 			fill: $red;
// 			width: 100% !important;
// 			height: 100% !important;
// 		}
// 	}
// }
//
// .send_search_container {
//
// 	.send_search {
// 		margin-top: 7px;
// 		cursor: pointer;
//
// 		svg {
// 			fill: $primary;
// 			width: 100% !important;
// 			height: 100% !important;
// 		}
// 	}
// }


</style>
