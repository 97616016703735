<template>
	<transition name="confirm.modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container">
					<div class="modal-header" v-if="title">
						<slot name="header">
							<h3 :class="(isDeleteConfirm) ? 'modal_color_red' : ''">{{title}}</h3>
						</slot>
					</div>
					<div class="modal-body" v-if="description">
						<slot name="body">
							{{description}}
						</slot>
					</div>
					<div class="modal-footer">
						<slot name="footer">
							<Btn @btn-click="closeModal(false)" text="No" text-size="big"></Btn>
							<Btn @btn-click="closeModal(true)" :button-type="(isDeleteConfirm) ? 'red' : 'primary'" text-size="big"
								 text="Si"></Btn>
						</slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
    import Btn from "../forms/Btn";

    export default {
        name: 'ConfirmModal',
        components: {Btn},
        props: {
            title: {
                required: false
            },
            description: {
                required: false
            },
			isDeleteConfirm: {
                default: false,
                required: false
			}
        },
        methods: {
            closeModal(success) {
                this.$emit('close', success)
            }
        }
    }
</script>

<style lang="scss">
	.modal-footer {
		display: flex;
		justify-content: space-between !important;
	}

	.modal_color_red {
		color: $red !important;
	}
</style>
