<template>
	<transition name="confirm.modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container modal-container-big">
					<div class="modal-header">
						<slot name="header" v-if="media && media.title">
							<h3>{{ media.title }}</h3>
						</slot>
					</div>
					<div class="modal-body media-modal">
						<slot name="body">
							<div class="row">
								<div class="col half media">
									<span v-if="media.media_type == 'image'" class="media-box">
										<img :src="media.url" alt=""/>
										<br>
										{{media.url}}
										<strong style="cursor: pointer">
											<div class="button" @click="copyLink(media.url)">Copia link</div>
										</strong>
									</span>
									<span v-if="media.media_type == 'video'" class="media-box">
										<video-player ref="videoPlayer" :options="playerOptions"></video-player>
									</span>
									<span v-if="media.media_type == 'file'" class="media-box">
										<a :href="media.url" target="_blank">Scarica il file</a>
									</span>


								</div>
								<div class="col half">

									<div class="fieldset">
										<label>
											Inserisci il titolo del media <small>(Obbligatorio)</small>
										</label>
										<div class="input">
											<input :placeholder="`Titolo del media`" autocomplete="off"
												   :maxlength="50" v-model="item.title"/>
										</div>
									</div>

									<div class="fieldset" style="visibility: hidden">
										<label>
											Inserisci una descrizione del media
										</label>
										<div class="input">
											<input :placeholder="`Descrizione del media`" autocomplete="off"
												   :maxlength="50" v-model="item.description"/>
										</div>
									</div>

									<br>
									Seleziona la categoria da assegnare
									<span v-for="category in categories" v-bind:key="category.id">
										<div
											:class="`button_selection_category ${(category.id == item.category.id) ? 'selected' : ''}`"
											v-if="category.id !== null" @click="item.category = category">
											{{ category.caption }}
										</div>

									</span>


									<div class="fieldset" v-if="titCategories.length">
										<label>
											Categoria <strong>TNV Play</strong>
										</label>
										<div class="input">
											<select v-model="titCategorySelected">
												<option :value="null">Non assegnato</option>
												<option v-for="c in titCategories"
														:key="c.id"
														:value="c.id">
													{{c.title}}
												</option>
											</select>
										</div>
									</div>

								</div>
							</div>
						</slot>
					</div>
					<div class="modal-footer">
						<slot name="footer" v-if="item.title">
							<Btn button-type="primary" :loading="loading" @btn-click="closeModal(false)" :text="`Salva e Chiudi`" text-size=""></Btn>
						</slot>
						<slot name="footer">
							<Btn @btn-click="$emit('close'); this.loading = false;" :text="`Chiudi finestra`" text-size="big"></Btn>
						</slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
import Btn from "@components/common/forms/Btn";
import {videoPlayer} from 'vue-videojs7';


export default {
	name: 'MediaDetailModal',
	components: {Btn, videoPlayer},
	props: {
		categories: {
			required: false
		},
		media: {
			required: true
		}
	},
	computed: {
		player () {
			return this.$refs.videoPlayer.player
		}
	},
	mounted () {
		if (this.media && this.media.title) {
			this.media.title = this.service.helpers.decode(this.media.title);
			this.item.title = this.service.helpers.decode(this.media.title);
			this.$forceUpdate();
		}
		if (this.media.media_type.indexOf('video') >= 0) {
			this.init();
			const src = this.media.url;
			this.playVideo(src)
		}
	},
	methods: {
		copyLink (url) {
			let newUrl = url;
			let tobecopied = document.createElement('input');
			tobecopied.classList.add('tobecopied')
			tobecopied.value = newUrl;
			document.body.appendChild(tobecopied);
			tobecopied.focus();
			tobecopied.select();
			document.execCommand('copy');
			let tooltip = document.createElement('div');
			tooltip.classList.add('tooltip');
			tooltip.innerHTML = 'Link copiato!'
			document.body.appendChild(tooltip);
			this.$gsap.to(tooltip, {
				duration: 0.3,
				opacity: 1,
				onComplete: () => {
					this.flashMessage.show({
						status: 'success',
						title: 'Link',
						message: "Link copiato correttamente"
					});
				}
			})
		},
		init () {
			this.titCategories = [];
			this.service.media.titolatrice.getPlayCategory(this.media.id)
				.then(mainPlayCategory => {
					if(mainPlayCategory && mainPlayCategory.id) {
						this.titCategorySelected = mainPlayCategory.id;
					}
					this.service.media.titolatrice.getCategories()
						.then(titCategories => {
							if (titCategories && titCategories.data && titCategories.data.length) {
								this.titCategories = titCategories.data;
							}
						})
				})
		},

		closeModal () {
			this.loading = true;

			let media = {
				id: this.media.id,
				title: this.item.title,
				caption: this.item.description,
				category: this.item.category.id,
				tit: (this.titCategorySelected == null) ? null : +this.titCategorySelected
			}

			if (this.media.media_type.indexOf('video') < 0) {
				delete media.tit;
			}

			this.service.media.save(media)
				.then(() => {
					this.$emit('close');
					this.loading = false;
				})
				.catch(() => {
					this.$emit('close');
					this.loading = false;
				})

			setTimeout(() => {
				this.$emit('close');
				this.loading = false;
			}, 5000)
		},
		playVideo: function (src) {
			//if(window.location.href.indexOf("http://localhost") >= 0) {
			//	src = 'https://mediastream1.telenuovo.it/video//2020/12/28/8b0e9ea5-583d-4fb3-ac57-1c2e31872088/playlist.m3u8'
			//}
			const poster = `${src}`.replace("playlist.m3u8", "thumbnail.jpg");
			const video = {
				withCredentials: false,
				type: 'application/x-mpegurl',
				src: src,
				poster: poster
			}
			this.player.reset()
			this.player.src(video)
			// this.player.load()
			// this.player.play()
		}
	},
	data () {
		return {
			loading: false,
			playerOptions: {
				autoplay: false,
				controls: true,
				controlBar: {
					timeDivider: false,
					durationDisplay: false
				}
			},
			item: {
				category: {
					id: this.media.category,
					caption: ''
				},
				title: this.media.title,
				description: this.media.caption
			},
			titCategories: [],
			titCategorySelected: null
		}
	}
}
</script>

<style lang="scss">
.modal-footer {
	display: flex;
	justify-content: space-between !important;
}

.media {
	img {
		width: 98%;
	}
}

.video-js {
	width: 380px !important;
}
</style>
