<template>
	<div id="speciali_categorie_detail" class="main-content page">
		<LoadingSpinner v-if="loading" :small="true"></LoadingSpinner>
		<div class="page--head" v-if="!loading">
			<div class="head--title">
				<PageTitle :back="{name: 'specialicategorie.page'}" :title="title" :title_translation="false"></PageTitle>
				<span class="buttons">
					<Btn v-on:btn-click="showLogoutModal = true" v-if="$route.params.id != 'new'" text-size="" button-type="red" text="Elimina"></Btn>
				</span>
			</div>
		</div>
		<div class="page--content">
			<SpecialiCategorieForm @change="isChange = $event" @save="onSave()" v-if="!loading && item" :base="item"></SpecialiCategorieForm>
		</div>
		<confirm-modal :is-delete-confirm="true" v-if="showLogoutModal" @close="deleteItem($event)" :title="`Eliminazione`" :description="`Eliminare questo elemento?`"></confirm-modal>
	</div>
</template>

<script>
import PageTitle from "../../components/common/PageTitle";
import LoadingSpinner from "../../components/common/Loading";
import Btn from "../../components/common/forms/Btn";
import ConfirmModal from "../../components/common/modals/ConfirmModal";
import SpecialiCategorieForm from "../../components/speciali/SpecialiCategorieForm";

export default {
	name: 'SpecialiCategorieDetail',
	components: {SpecialiCategorieForm, ConfirmModal, Btn, LoadingSpinner, PageTitle},
	data () {
		return {
			loading: false,
			title: '',
			item: null,
			isChange: false,
			showLogoutModal: false
		}
	},
	methods: {
		init () {
			this.title = `Aggiungi una nuova categoria`;
			this.loading = true;
			this.service.speciali_categorie.single(this.$route.params.id)
				.then(item => {
					this.item = null;
					let i = {...item};
					// sistemo le date
					i.date = i.date ? this.$moment(i.date * 1000).tz("Europe/Rome").format() : this.$moment().tz("Europe/Rome").format();
					// gli ACF
					i.others = {
						nota: i.nota || '',
						colore_titolo_testata: i.colore_titolo_testata || '#000000',
						colore_titolo: i.colore_titolo || '#F8CE0E',
						colore_sfondo: i.colore_sfondo || '#193351',
						colore_testo: i.colore_testo || '#ffffff',
						immagine_desktop: i.immagine_desktop || 'https://via.placeholder.com/1190x300',
						immagine_mobile: i.immagine_mobile || 'https://via.placeholder.com/960x600',
						posizione: i.posizione || {
							1: [false, false, false, false, false],
							2: [false, false, false, false, false],
							3: [false, false, false, false, false]
						},
						adv: i.adv || {
							elenco: {
								desktop: [108362, 108363, 108364, 108365, null, null, null, null],
								mobile: [108362, 108363, 108364, 108365, null, null, null, null]
							},
							dettaglio: {
								desktop: [108366, 108367],
								mobile: [108366, 108367]
							}
						},
						attivo: i.attivo,
						visibile: i.visibile,
					}
					if (this.$route.params.id != 'new') {
						this.title = item.title;
					}
					this.item = i
				})
				.finally(() => {
					this.loading = false;
				})
		},
		onSave () {
			this.flashMessage.show({
				status: 'success',
				title: 'Salvato',
				message: "Salvataggio eseguito con successo"
			});
			this.$router.push({name: 'specialicategorie.page'});
		},
		deleteItem (del) {
			if (del) {
				this.loading = true;
				this.service.speciali_categorie.delete(this.$route.params.id)
					.then(() => {
						this.flashMessage.show({
							status: 'success',
							title: 'Eliminazione',
							message: 'Eliminazione completata con successo'
						});
						this.$router.push({name: 'specialicategorie.page'});
					})
					.finally(() => {
						this.loading = false;
					})
			}
			this.showLogoutModal = false;
		}
	},
	mounted () {
		this.init()
	},
	beforeRouteLeave (to, from, next) {
		if (this.isChange == false) {
			next()
		} else {
			const answer = window.confirm('Sono stati trovate delle modifiche. Uscendo le modifiche andranno perse. Si vuole uscire?')
			if (answer) {
				next()
			} else {
				next(false)
			}
		}
	}
}
</script>

<style lang="scss">
@import '@/assets/scss/pages/_specialicategorie';
</style>
