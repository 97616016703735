<template>
	<div id="users_detail" class="main-content">
		<LoadingSpinner v-if="loading" :small="true"></LoadingSpinner>
		<div class="head" v-if="!loading">
			<!--<PageTitle :back="{name: 'users.page'}" :title="title"
					   :title_translation="($route.params.id == 'new') ? true : false"></PageTitle>-->
			<PageTitle :title="navigation" subtitle="users.subtitle"
					   :title_translation="($route.params.id == 'new') ? true : false"></PageTitle>
			<span class="buttons">
				<Btn v-on:btn-click="showLogoutModal = true" v-if="$route.params.id != 'new'" text-size="" button-type="red" text="Elimina"></Btn>
			</span>
		</div>
		<div class="panel users-list" v-if="!loading">
			<UserForm :base="item" v-on:save="onSave()"></UserForm>
		</div>
		<confirm-modal :is-delete-confirm="true" v-if="showLogoutModal" @close="deleteItem($event)" :title="$t('users.modal.delete.title')" :description="$t('users.modal.delete.description')" ></confirm-modal>
	</div>
</template>

<script>
    import PageTitle from "../../components/common/PageTitle";
    import LoadingSpinner from "../../components/common/Loading";
    import Btn from "../../components/common/forms/Btn";
    import UserForm from "../../components/users/UserForm";
    import ConfirmModal from "../../components/common/modals/ConfirmModal";

    export default {
        name: 'UsersDetails',
        components: {ConfirmModal, UserForm, Btn, LoadingSpinner, PageTitle},
        data() {
            return {
                showLogoutModal: false,
                loading: false,
                title: '',
                item: null,
                navigation: [{
                    'title': this.$t('users.title'),
                    'link': { name: 'users.page' }
                }]
            }
        },
        methods: {
            init() {
                this.title = "users.new";
                if (this.$route.params.id != 'new') {
                    this.loading = true;
                    this.service.users.single(this.$route.params.id)
                        .then(item => {
                            this.item = item;
                            this.title = item.name;

                            this.navigation.push({
								'title': this.title
							});
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.navigation.push({
                        'title': 'users.new'
                    });
				}
            },
            onSave() {
                this.flashMessage.show({
                    status: 'success',
                    title: this.$t('common.save_popup.title'),
                    message: this.$t('common.save_popup.message.success')
                });
                this.$router.push({name: 'users.page'});
            },
            deleteItem(del) {
                if(del) {
                    this.loading = true;
                    this.service.users.delete(this.$route.params.id)
                        .then(() => {
                            this.flashMessage.show({
                                status: 'success',
                                title: this.$t('common.save_popup.title'),
                                message: this.$t('common.save_popup.message.delete_success')
                            });
                            this.$router.push({name: 'users.page'});
                        })
                        .finally(() => {
                            this.loading = false;
                        })
				}
                this.showLogoutModal = false;
			}
        },
        mounted() {
            this.init()
        }
    }
</script>

<style lang="scss">


	.icon_checked {
		svg {
			fill: $primary;
		}
	}
</style>
