<template>
	<div id="post_list" class="page main-content">
		<div class="page--head">
			<div class="head--title">
				<PageTitle :title_translation="false" :title="`Socials`"
						   :subtitle="`Gestione delle pubblicazioni socials`"></PageTitle>
			</div>
			<!-- ricerca -->
			<div class="ricerca">
				<div :class="`post_list_actions`">
					<div class="post_list_actions__search_container">
						<div class="field">
							<select @change="isSearch = true; refresh();" :disabled="loading" v-model="search_category"
									name="post_list_search_category" id="post_list_search_category">
								<option :value="null">Tutte le categorie</option>
								<option :value="cat.id" v-for="cat in categories" :key="cat.id">
									<span v-if="cat.parent !== null">---</span>
									{{ cat.name }}
								</option>
							</select>
						</div>
						<div class="field">
							<select @change="isSearch = true; page=1; refresh();" :disabled="loading"
									v-model="search_attributes" name="post_list_search_acf" id="post_list_search_acf">
								<option :value="null">Tutte le priorità</option>
								<option :value="`breaking_news`">Breaking News</option>
								<option :value="`breaking_news_locale`">Breaking News Locale</option>
								<!--<option :value="`straordinaria`">Straordinaria</option>-->
								<option :value="`straordinaria_biancoscudato`">Straordinaria TgBiancoscudato</option>
								<option :value="`straordinaria_gialloblu`">Straordinaria TgGialloblu</option>
								<option :value="`straordinaria_padova`">Straordinaria TgPadova</option>
								<option :value="`straordinaria_verona`">Straordinaria TgVerona</option>
								<!--<option :value="`alta`">Alta</option>-->
								<option :value="`alta_biancoscudato`">Alta TgBiancoscudato</option>
								<option :value="`alta_gialloblu`">Alta TgGialloblu</option>
								<option :value="`alta_padova`">Alta TgPadova</option>
								<option :value="`alta_verona`">Alta TgVerona</option>
							</select>
						</div>
						<div class="field">
							<input :disabled="loading" class="post_list_search" id="post_list_search" v-model="search"
								   @keydown.13="searchHandler($event)" :placeholder="'Cerca'">
						</div>
						<div class="field">
							<Datetime :disabled="loading" :format="`dd/MM/yyyy`" :placeholder="`Data articolo`"
									  :type="`date`" v-model="search_date"></Datetime>
							<div v-if="search_date" class="remove_date_time" @click="search_date = null">
								<Icon icon="trash"></Icon>
							</div>
						</div>
						<div class="ricerca--controls">
							<div class="send_search_container control">
								<div class="send_search" @click="isSearch = true; page=1; refresh();">
									<Icon icon="search"></Icon>
								</div>
							</div>

							<div v-if="isSearch" class="trash_all_container control">
								<div class="trash_all" @click="isSearch = false; initSearch(); refresh();">
									<Icon icon="cross"></Icon>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="page--content">

			<!-- tabs -->
			<div class="tabs">
				<div :class="`tab ${('publish' == postTypeSelected) ? 'selected' : ''}`"
					 @click="postTypeSelected = 'publish'; refresh()">
					Pubblicati
				</div>
				<div :class="`tab ${('future' == postTypeSelected) ? 'selected' : ''}`"
					 @click="postTypeSelected = 'future'; refresh()">
					Futuri
				</div>
			</div>

			<div class="panel">
				<LoadingSpinner v-if="loading" :small="true"></LoadingSpinner>
				<div class="table" v-if="!loading && items.length">
					<div class="table--head">
						<span class="table--head__col long">Articolo</span>
						<!--<span class="table--head__col">Data</span>-->
						<span class="table--head__col"></span>
						<span class="table--head__col"></span>
					</div>
					<div class="table--body">
						<div v-for="i in items" v-bind:key="i.id" class="table--row">
			                <span class="table--body__col long article" style="width: 34%">
			                    <span class="post__title_social">
									{{ i.title }}
								</span>
			                    <small class="post__autore">
									di {{ i.autore }} del {{ i.date | datatime }}
								</small>
								<small>
									<div v-html="getScheduledData(i)"></div>
								</small>
								<small style="cursor: pointer" v-if="service.utils.getArticleUrl(i)">
									<a :href="service.utils.getArticleUrl(i)" target="_blank" style="color: grey">
										{{ service.utils.getArticleUrl(i) }}
									</a>
									<div @click="copyLink(service.utils.getArticleUrl(i))">[<strong>Copia url negli appunti</strong>]</div>
								</small>
			                </span>
							<!--<span class="table--body__col date">
			                    {{ i.date | datatime }}
			                </span>-->
							<span class="">
								<template>
									<div class="fieldset social_container">
										<div class="social__block">
											<h3 class="social__block_title">Facebook</h3>
											<label>Telenuovo: <strong v-html="getSocialData(i, 'facebook', 'telenuovo')"></strong></label>
											<label>TgVerona: <strong v-html="getSocialData(i, 'facebook', 'verona')"></strong></label>
											<label>TgPadova: <strong v-html="getSocialData(i, 'facebook', 'padova')"></strong></label>
											<label>TgGialloblu: <strong v-html="getSocialData(i, 'facebook', 'gialloblu')"></strong></label>
											<label>TgBiancoscudato: <strong v-html="getSocialData(i, 'facebook', 'biancoscudato')"></strong></label>
										</div>
										<div class="social__block">
											<h3 class="social__block_title">Instagram</h3>
											<label>Telenuovo: <strong v-html="getSocialData(i, 'instagram', 'telenuovo')"></strong></label>
											<label>TgVerona: <strong v-html="getSocialData(i, 'instagram', 'verona')"></strong></label>
											<label>TgPadova: <strong v-html="getSocialData(i, 'instagram', 'padova')"></strong></label>
											<label>TgGialloblu: <strong v-html="getSocialData(i, 'instagram', 'gialloblu')"></strong></label>
											<label>TgBiancoscudato: <strong v-html="getSocialData(i, 'instagram', 'biancoscudato')"></strong></label>
										</div>
										<div class="social__block">
											<h3 class="social__block_title">Twitter</h3>
											<label>Telenuovo: <strong v-html="getSocialData(i, 'twitter', 'telenuovo')"></strong></label>
											<label>TgVerona: <strong v-html="getSocialData(i, 'twitter', 'verona')"></strong></label>
											<label>TgPadova: <strong v-html="getSocialData(i, 'twitter', 'padova')"></strong></label>
											<label>TgGialloblu: <strong v-html="getSocialData(i, 'twitter', 'gialloblu')"></strong></label>
											<label>TgBiancoscudato: <strong v-html="getSocialData(i, 'twitter', 'biancoscudato')"></strong></label>
										</div>

									</div>

								</template>
			                </span>
							<span class="table--body__col extra">
								<Btn v-on:btn-click="publishPost(i)" v-if="$route.params.id != 'new'" text-size=""
									 text="Pubblica"></Btn>
			                </span>
						</div>
					</div>
				</div>
				<div v-if="!loading && items.length == 0" class="no-posts">
					Nessun elemento da visualizzare
				</div>
				<!-- paginazione -->
				<PaginationSimple v-if="!loading" :page="page" :page_end="pageEnd"
								  v-on:page="changePage($event)"></PaginationSimple>
			</div>
		</div>
		<socials-modals :post="currentPost" :social="currentSocial" v-if="showSocialModal"
						@close="refresh()"></socials-modals>
	</div>
</template>

<script>
import PageTitle from "../../components/common/PageTitle";
import LoadingSpinner from "../../components/common/Loading";
import PaginationSimple from "../../components/common/forms/PaginationSimple";
import Icon from "../../components/common/forms/Icon";
import {Datetime} from 'vue-datetime'
import Btn from "../../components/common/forms/Btn.vue";
import SocialsModals from "../../components/common/modals/SocialsModal.vue";
import moment from "../../../plugins/moment.plugin";

export default {
	name: 'SocialsList',
	components: {
		SocialsModals,
		Btn,
		Icon,
		PaginationSimple,
		LoadingSpinner,
		PageTitle,
		Datetime
	},
	data () {
		return {
			loading: false,
			items: [],
			page: 1,
			pageEnd: false,
			search: '',
			search_category: null,
			search_attributes: null,
			search_date: null,
			categories: [],
			sort: null,
			isSearch: false,
			showSocialModal: false,
			currentPost: null,
			currentSocial: null,
			postTypeSelected: 'publish'
		}
	},
	watch: {
		search_date: {
			immediate: true,
			handler () {
				this.isSearch = true;
				this.refresh();
				this.page = 1;
			}
		}
	},
	methods: {
		initSearch () {
			this.search = '';
			this.search_category = null;
			this.search_attributes = null;
			this.search_date = null;
			localStorage.removeItem("socials_search")
		},
		copyLink (url) {
			let tobecopied = document.createElement('input');
			tobecopied.classList.add('tobecopied')
			tobecopied.value = url;
			document.body.appendChild(tobecopied);
			tobecopied.focus();
			tobecopied.select();
			document.execCommand('copy');
			//alert("Link copiato correttamente");
			this.flashMessage.show({
				status: 'success',
				title: 'Link',
				message: "Link copiato correttamente"
			});
		},
		refresh () {
			this.loading = true;
			this.showSocialModal = false;
			this.currentPost = null;
			this.currentSocial = null;
			this.items = [];
			if (this && this.service && this.service.categories) {
				this.service.categories.list()
					.then(categories => {
						// sistemo le categorie
						this.categories = [];
						if (categories) {
							categories.map(c => {
								if (c.child.length) {
									this.categories.push({
										id: c.id,
										parent: null,
										name: c.name
									});
									c.child.map(ch => {
										let name = (ch.name.indexOf('(') >= 0) ? ch.name : `${ch.name} (${c.name})`
										this.categories.push({
											id: ch.id,
											parent: c.id,
											name: name
										});
									})
								}
							})
						}

						// se si tratta i una categoria padre, devo cercare per
						// tutte le categorie figlie
						let search_category_for_wp = null;
						if (this.search_category && this.search_category !== null) {
							let cFilter = categories.filter((c) => {
								return c.id == this.search_category
							});
							if (cFilter && cFilter.length) {
								search_category_for_wp = [];
								cFilter[0].child.map(ch => {
									search_category_for_wp.push(ch.id)
								})
								search_category_for_wp = search_category_for_wp.join(',');
							} else {
								search_category_for_wp = this.search_category;
							}
						}

						let search_date = null;
						if (this.search_date && this.search_date !== "" && this.search_date.indexOf('T') >= 0) {
							search_date = this.search_date.split("T")[0];
						}

						localStorage.setItem("socials_search", JSON.stringify({
							search: this.search,
							search_category: this.search_category,
							search_attributes: this.search_attributes,
							search_date: this.search_date
						}));

						let service = this.service.post.list;
						service(this.page, this.sort, this.postTypeSelected, this.search, search_category_for_wp, this.search_attributes, search_date)
							.then(items => {
								let promises = [];
								items.forEach(i => {
									promises.push(this.service.socials.get(i.id));
								})
								this.items = items;

								Promise.all(promises)
									.then((data) => {
										data.forEach((d, index) => {
											let f = {...this.items[index]};
											f = {...f, ...{socials: null}}
											this.items[index] = f;
											if(d && d.length) {
												this.items[index].socials = d;
												this.$forceUpdate()
											}
										})
									});
							})
							.finally(() => {
								this.loading = false;
							})
					})
					.catch(() => {
						this.loading = false;
					})
			}
		},
		changePage (page) {
			this.page = page;
			this.refresh();
		},
		searchHandler (event) {
			if (event) event.preventDefault();
			if (event) event.stopPropagation();
			this.isSearch = true;
			this.refresh();
			this.page = 1;
		},
		publishPost (post) {
			this.currentPost = post;
			this.currentSocial = (post && post.socials) ? post.socials : []
			this.showSocialModal = true;
		},
		getSocialData(item, platform, page) {
			if(item && item.socials && item.socials.length) {
				const s = item.socials.filter((soc) => {
					return soc.page == page && soc.platform == platform;
				});
				if(s && s.length) {
					if(s[0].status == "success") {
						return "<span style='color: green'>Sì</span>";
					} else {
						if(s[0].status == "none") {
							return "<span style='color: gray'>...</span>";
						} else {
							return `<span style='color: red' data-tooltip='${s[0].message}'>Error</span>`
						}
					}
				} else {
					return `<span style='color: gray'>No</span>`;
				}
			} else {
				return `<span style='color: gray'>No</span>`;
			}
		},
		getScheduledData(i) {
			if(i && i.socials && i.socials.length) {
				const s = i.socials.filter((soc) => {
					return soc.status == "none";
				});
				if(s && s.length) {
					let result = [];
					s.forEach(a => {
						const d = moment.moment(a.scheduledatetime).format('DD/MM/YY - HH:mm');
						result.push(`<span style='color: orange'>Pianificato su <strong>${a.page} ${a.platform}</strong> il ${d}</span>`)
					})
					return result.join("<br>");
				} else {
					return ``;
				}
			} else {
				return ``;
			}
		}
	},
	mounted () {
		// se sono stati settati dei filtri li aggiorno
		let sFilters = localStorage.getItem("socials_search") || null;
		if (sFilters) {
			sFilters = JSON.parse(sFilters);
			this.search = sFilters.search || "",
				this.search_category = sFilters.search_category || null,
				this.search_attributes = sFilters.search_attributes || null,
				this.search_date = sFilters.search_date || null
		}
		this.refresh()
	}
}
</script>

<style lang="scss">
@import '@/assets/scss/pages/_postlist';
@import '@/assets/scss/components/_ricercaposts';

.facebook_color {
	color: #3b5998;
}

.post__title_social {
	font-size: 1.2em;
	font-weight: $bold;
}

.social_col {
	width: 40%;
}

.social_container {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: flex-start;
}

.social__block {
	padding-top: var(--gutter);
	padding-bottom: var(--gutter);
	padding-right: calc(var(--gutter) * 2);
	padding-left: 0;
}

[data-tooltip] {
	position: relative;
	z-index: 2;
	cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
	position: absolute;
	bottom: 150%;
	left: 50%;
	margin-bottom: 5px;
	margin-left: -80px;
	padding: 7px;
	width: 350px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background-color: #000;
	background-color: hsla(0, 0%, 20%, 0.9);
	color: #fff;
	content: attr(data-tooltip);
	text-align: center;
	font-size: 14px;
	line-height: 1.1;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
	position: absolute;
	bottom: 150%;
	left: 50%;
	margin-left: -5px;
	width: 0;
	border-top: 5px solid #000;
	border-top: 5px solid hsla(0, 0%, 20%, 0.9);
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
	content: " ";
	font-size: 0;
	line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
	visibility: visible;
	opacity: 1;
}
</style>
