<template>
	<div id="sondaggio_detail" class="main-content page">
		<LoadingSpinner v-if="loading" :small="true"></LoadingSpinner>
		<div class="page--head" v-if="!loading">
			<div class="head--title">
				<PageTitle :back="{name: 'sondaggiolist.page'}" :title="title" :title_translation="false"></PageTitle>
				<span class="buttons">
					<Btn v-on:btn-click="showLogoutModal = true" v-if="$route.params.id != 'new'" text-size="" button-type="red" text="Elimina"></Btn>
				</span>
			</div>
		</div>
		<div class="page--content">
			<SondaggioForm @change="isChange = $event" @save="onSave()" v-if="!loading && item" :base="item"></SondaggioForm>
		</div>
		<confirm-modal :is-delete-confirm="true" v-if="showLogoutModal" @close="deleteItem($event)" :title="`Eliminazione`" :description="`Eliminare questo articolo?`"></confirm-modal>
	</div>
</template>

<script>
import PageTitle from "../../components/common/PageTitle";
import LoadingSpinner from "../../components/common/Loading";
import Btn from "../../components/common/forms/Btn";
import ConfirmModal from "../../components/common/modals/ConfirmModal";
import SondaggioForm from "@components/sondaggio/SondaggioForm";

export default {
	name: 'SondaggioDetail',
	components: {SondaggioForm, ConfirmModal, Btn, LoadingSpinner, PageTitle},
	data () {
		return {
			loading: false,
			title: '',
			item: null,
			isChange: false,
			showLogoutModal: false
		}
	},
	methods: {
		init () {
			this.title = `Aggiungi un nuovo sondaggio`;
			this.loading = true;
			this.service.sondaggio.single(this.$route.params.id)
				.then(item => {
					this.item = null;
					let i = {...item};

					// sistemo le date
					i.date = i.date ? this.$moment(i.date * 1000).tz("Europe/Rome").format() : this.$moment().tz("Europe/Rome").format();
					i.data_scadenza = i.data_scadenza ? this.$moment(i.data_scadenza * 1000).tz("Europe/Rome").format() : this.$moment().tz("Europe/Rome").add(15, "days").format();

					// gli ACF
					i.others = {
						domanda: i.domanda || '',
						risposte_possibili: [],
						data_scadenza: i.data_scadenza,
						visualizza_in_homepage: i.visualizza_in_homepage || false,
						scegli_dove_pubblicare: i.scegli_dove_pubblicare || [],
					}

					if (i.risposte_possibili && i.risposte_possibili.length) {
						i.risposte_possibili.map(ac => {
							i.others.risposte_possibili.push({
								codice: ac.codice,
								risposta: ac.risposta
							});
						})
					}

					if (this.$route.params.id != 'new') {
						this.title = item.title;
					}

					this.item = i
				})
				.finally(() => {
					this.loading = false;
				})
		},
		onSave () {
			this.flashMessage.show({
				status: 'success',
				title: 'Salvato',
				message: "Il sondaggio è stato salvato con successo."
			});
			this.$router.push({name: 'sondaggiolist.page'});
		},
		deleteItem (del) {
			if (del) {
				this.loading = true;
				this.service.sondaggio.delete(this.$route.params.id)
					.then(() => {
						this.flashMessage.show({
							status: 'success',
							title: 'Eliminazione',
							message: 'Sondaggio eliminato con successo'
						});
						this.$router.push({name: 'sondaggiolist.page'});
					})
					.finally(() => {
						this.loading = false;
					})
			}
			this.showLogoutModal = false;
		}
	},
	mounted () {
		this.init()
	},
	beforeRouteLeave (to, from, next) {
		if (this.isChange == false) {
			next()
		} else {
			const answer = window.confirm('Sono stati trovate delle modifiche. Uscendo le modifiche andranno perse. Si vuole uscire?')
			if (answer) {
				next()
			} else {
				next(false)
			}
		}
	}
}
</script>

<style lang="scss">
@import '@/assets/scss/pages/_sondaggiodetail';
</style>
