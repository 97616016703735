<template>
	<div id="speciali_categorie_list" class="page main-content">
		<div class="page--head">
			<div class="head--title">
				<PageTitle :title_translation="false" :title="`Speciali Categorie`"
						   :subtitle="`Gestione degli speciali`"></PageTitle>
				<span class="floating">
					<Btn @btn-click="go('new')" text-size="small" button-type="primary" text="Aggiungi"></Btn>
				</span>
			</div>
		</div>
		<div class="page--content">
			<!-- lista -->
			<div class="panel">
				<LoadingSpinner v-if="loading" :small="true"></LoadingSpinner>
				<div class="table" v-if="!loading && items.length">
					<div class="table--head">
						<span class="table--head__col long">Titolo</span>
						<!--<span class="table--head__col">Ordinamento</span>
						<span class="table--head__col center">Video Presenti</span>
						<span class="table--head__col center">Giorni Scadenza</span>-->
						<span class="table--head__col center">Attivo</span>
						<span class="table--head__col center">Visibile</span>
					</div>
					<div class="table--body">
						<div v-for="i in items" v-bind:key="i.id" class="table--row">
			                <span class="table--body__col long article">
			                    <span @click="go(i.id)" class="post__title" v-html="i.title">
								</span>
			                </span>
							<!--<span class="table--body__col ordine_form">
								<input :disabled="loading" type="number" v-model="i.ordine"/>
								<button :disabled="loading" type="button" @click="submit(i)">
									Salva
								</button>
			                </span>
							<span class="table--body__col table--body__col__center">
			                    {{ i.videos.count }}
			                </span>
							<span class="table--body__col table--body__col__center">
			                    <span v-if="i.giorni_scadenza > 0">{{ i.giorni_scadenza }}</span>
			                    <span v-else>-</span>
			                </span>-->
							<span class="table--body__col table--body__col__center icon">
								<Icon icon="checked" v-if="i.attivo"></Icon>
			                </span>
							<span class="table--body__col table--body__col__center icon">
								<Icon icon="checked" v-if="i.visibile"></Icon>
			                </span>
						</div>
					</div>
				</div>
				<div v-if="!loading && items.length == 0" class="no-posts">
					Nessun elemento da visualizzare
				</div>
				<!-- paginazione -->
				<PaginationSimple v-if="!loading" :page="page" :page_end="pageEnd"
								  v-on:page="changePage($event)"></PaginationSimple>
			</div>
		</div>
	</div>
</template>

<script>
import PageTitle from "../../components/common/PageTitle";
import LoadingSpinner from "../../components/common/Loading";
import Btn from "../../components/common/forms/Btn";
import PaginationSimple from "../../components/common/forms/PaginationSimple";
import Icon from "@components/common/forms/Icon";
import {required} from "vuelidate/lib/validators";

export default {
	name: 'SpecialiCategorieList',
	components: {
		Icon,
		PaginationSimple,
		Btn,
		LoadingSpinner,
		PageTitle
	},
	data () {
		return {
			loading: false,
			items: [],
			page: 1,
			pageEnd: false
		}
	},
	methods: {
		refresh () {
			this.loading = true;
			this.items = [];
			this.service.speciali_categorie.list(this.page)
				.then(items => {
					this.items = items;
				})
				.finally(() => {
					this.loading = false;
				})
		},
		changePage (page) {
			this.page = page;
			this.refresh();
		},
		go (id) {
			if (this.ispopup) {
				this.$emit('close', id)
			} else {
				this.$router.push({name: 'specialicategoriedetail.page', params: {id: id}})
			}
		}
	},
	mounted () {
		this.refresh()
	}
}
</script>

<style lang="scss">
@import '@/assets/scss/pages/_specialicategorie';
@import '@/assets/scss/components/_ricercaposts';
</style>
